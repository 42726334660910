import { forEach, omit } from 'lodash-es'
import type { GenericReducer } from '../types'

export type SelectedItemsObjType = {
  [x: string]: boolean
}

export type SelectedItemsMapType = {
  [key: string]: SelectedItemsObjType
}

export type State = {
  selectedItems: SelectedItemsMapType
}

const init: () => State = () => ({
  selectedItems: {},
})

export type Reducer = GenericReducer<State>
const reducer: Reducer = (state = init(), action) => {
  const { selectedItems } = state
  switch (action.type) {
  case 'DESELECT_ITEMS': {
    const {
      payload: { itemIds, prefix },
    }: {
      payload: {
        itemIds: Array<string>,
        prefix: string
      }
    } = action
    return {
      ...state,
      selectedItems: {
        ...selectedItems,
        [prefix]: omit(selectedItems[prefix], itemIds),
      },
    }
  }
  case 'SELECT_ITEMS': {
    const {
      payload: { itemIds, prefix },
    }: {
      payload: {
        itemIds: Array<string>,
        prefix: string
      }
    } = action
    const selectedItemsObj: SelectedItemsObjType = {
      ...selectedItems[prefix],
    }
    forEach(itemIds, (itemId) => {
      selectedItemsObj[itemId] = true
    })
    return {
      ...state,
      selectedItems: {
        ...selectedItems,
        [prefix]: selectedItemsObj,
      },
    }
  }
  case 'DESELECT_ALL': {
    const {
      payload: { prefix },
    }: {
      payload: {
        prefix: string
      }
    } = action
    return {
      ...state,
      selectedItems: {
        ...selectedItems,
        [prefix]: {},
      },
    }
  }
  default: {
    return state
  }
  }
}

export default reducer
