export default function useRadioSelectedDisabled () {
  return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <defs>
      <style>
        .a {
          fill: #dfdfdf;
        }

        .b {
          fill: #fff;
        }
      </style>
    </defs>
    <circle class="a" cx="10" cy="10" r="10"/>
    <path class="b" d="M8,14.47l8.2-8.2a.34.34,0,0,0,0-.46L15.38,5a.32.32,0,0,0-.46,0L7.73,12.22,5.08,9.58a.32.32,0,0,0-.46,0l-.78.78a.34.34,0,0,0,0,.46l3.65,3.65A.33.33,0,0,0,8,14.47Z"/>
  </svg>`
}
