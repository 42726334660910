import makeStyles from 'd2/hooks/tss/makeStyles'

const BLANK_MIN_WIDTH = 98

const styles = () => ({
  blankButton: {
    minWidth: BLANK_MIN_WIDTH,
  },
  button: {
    alignSelf: 'flex-end',
  },
  checkboxList: {
    overflow: 'hidden hidden',
  },
} as const)

export default makeStyles({
  name: 'OneTimePassword',
})(styles)
