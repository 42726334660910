import {
  find,
  flatMap,
  includes,
  isEmpty,
} from 'lodash-es'
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useMutation } from '@apollo/client'
import type {
  ApolloClient,
  DocumentNode,
  MutationError,
} from 'd2/types'
import type {
  ApolloError,
  MutationFunctionOptions,
  RefetchQueriesFunction,
} from '@apollo/client'
import type { Queries } from 'd2/queries/types'

export { useMutation } from '@apollo/client'

export type MutationErrorWithTypename = MutationError & {
  __typename: string
}

// If we want to make our own hook around `useMutation` it should:
//  - Accept a reducer function to get from `data` to `data?.mutationName` for example (data.createAlbumRelease), because this is pretty noisy.
//  - Consider making one of the returns the return of reduced `data?.mutationName` so it is easier to access.
//  - Decide how to handle `error` return value. E.g. possibly universally handle network errors (or all non-user-input-validation errors) by showing a snackbar?
//  - Not abstract such that it gets in the way of accessing return values such as { loading }
//  - Consider making the first argument the mutation `inputs`, to avoid `variables: { inputs }`, but allow passing in other options as another argument?
//  - Not abstract such that it gets in the way of doing things like passing in extra mutation options.
//  - More easily make available the mutation `errors` results.
//  - Consider reporting to raygun if `data.mutationName` is missing. Could indicate a developer error perhaps?
//  - memoize properly.
//  - Not allow double submit. If mutation is already loading, don't allow firing of another one. Consider using ref (so it takes effect immediately without waiting for React lifecycle), or debouncing(first: yes, last: no) to ensure it's only fired once.
//  - Consider handling (or writing external utils to handle) different common cases, such as `skip_save`. E.g. after awaiting mutation response, if there are no validation errors, it is successful.
//  - Consider a function option which determines success criteria for the mutation. E.g. `(data) => !!data?.album?.id`

// Most/all of the type exports from apollo libdefs are inexact intersection types, so unfortunately we are required to re-type some of it here.
// TVariables isn't used yet, but I think it should be so it's here for now.
export type MutationReducerArg<TData, _TVariables = EO,
> = {
  called: boolean,
  client?: ApolloClient<any>,
  data?: TData | null,
  error?: ApolloError,
  loading: boolean
}

export type SingleMutationReducer<TData, TReduced = any> = (a: MutationReducerArg<TData>) => TReduced
export type SingleMutationReducedResult<TData, TReducer extends (a: MutationReducerArg<TData>) => any> = $Call1<TReducer, MutationReducerArg<TData>>

export type QuerySwitchHookOptions<TData, TReducer extends (a: MutationReducerArg<TData>) => any> = {
  reducer: TReducer
}

type InvocationOptions<TData, TVariables> = {
  allowConcurrentRequests?: boolean,
  mutationOptions?:
  & MutationFunctionOptions<TData, TVariables>
  & {
    refetchQueries?: Queries[] | RefetchQueriesFunction,
  }
  // TODO: Add useSingleMutation internal options here as needed!
}

export type SingleMutationFunctionPromiseData<TData, TReducer extends (a: MutationReducerArg<TData>) => any> = {
  errorMessages: string[],
  errors: MutationErrorWithTypename[],
  reduced: SingleMutationReducedResult<TData, TReducer>,
  success: boolean
}

type SingleMutationFunction<TData, TReducer extends (a: MutationReducerArg<TData>) => any, TVariables extends { [key: string]: any }> = (
  input: TVariables['input'],
  invocationOptions?: InvocationOptions<TData, TVariables> | null
) => Promise<SingleMutationFunctionPromiseData<TData, TReducer>>

type UseSingleMutationReturn<TData, TReducer extends (a: MutationReducerArg<TData>) => any, TVariables extends { [key: string]: any; }> = [
  SingleMutationFunction<TData, TReducer, TVariables>,
  {
    apolloError: ApolloError | undefined,
    called: boolean,
    errorMessages: string[],
    errors: MutationErrorWithTypename[],
    failed: boolean,
    formErrors: MutationError[],
    loading: boolean,
    longLoading: boolean,
    reduced: SingleMutationReducedResult<TData, TReducer>,
    success: boolean
  },
]

type MutationVariables = {
  input: any
}

class MutationAlreadyLoadingError extends Error {
  constructor (message: string) {
    super(message)
    this.name = 'MutationAlreadyLoadingError'
  }
}

export function formErrorsFromMutationErrors<TDataMutationErrors extends MutationErrorWithTypename[]> (errors?: TDataMutationErrors | null): MutationError[] {
  return (errors ?? []).map((error: MutationErrorWithTypename) => {
    const { __typename, ...formError } = error // eslint-disable-line unused-imports/no-unused-vars
    return formError
  })
}

export function useSingleMutation<TData, TVariables extends MutationVariables, TReducer extends SingleMutationReducer<TData>> (
  query: DocumentNode,
  {
    reducer,
  }: QuerySwitchHookOptions<TData, TReducer>,
): UseSingleMutationReturn<TData, TReducer, TVariables> {
  // TODO: Handle `error` and consider forwarding `client`.
  // const [mutate, { called, client, data, error, loading }] = useMutation<TData, TVariables>(query)

  const [mutate, { called, data, error: apolloError, loading }] = useMutation<TData, TVariables>(query)
  const singleMutate = useCallback(async (input: any, invocationOptions: any) => {
    // By rejecting the promise instead of returning void, we guarantee that a
    // SUCCESSFUL promise resolve will contain the expected data, so we don't
    // need to nil-check it as the consumer.

    // TODO: Unfortunately, this prevents the mutation from being fired again with new inputs until the previous one has resolved. Fix this. Maybe compare inputs? Or allowConcurrentRequests boolean option?
    if (loading && !invocationOptions?.allowConcurrentRequests) throw new MutationAlreadyLoadingError('Mutation is already in flight')

    const result = await mutate({
      variables: {
        input,
      },
      ...invocationOptions?.mutationOptions,
    })

    const reduced = reducer({
      // TODO: Consider passing other things into reducer?
      called: true,
      data: result.data,
      loading: false,
    })
    const errors: MutationErrorWithTypename[] = reduced?.errors || []
    const success = !!(reduced && isEmpty(errors))

    return {
      apolloError,
      errorMessages: flatMap(errors, ({ messages }) => messages),
      errors,
      reduced,
      success,
    }
  }, [
    apolloError,
    loading,
    mutate,
    reducer,
  ])

  const reduced = useMemo(() => data
    ? reducer({
      // TODO: Consider passing other things into reducer?
      called,
      data,
      loading,
    })
    : undefined, [
    called,
    data,
    loading,
    reducer,
  ])
  const reducedErrors = reduced?.errors
  const errors: MutationErrorWithTypename[] = useMemo(() => reducedErrors || [], [reducedErrors])
  const success = !!(reduced && isEmpty(errors))

  // Set longLoading to true if loading is true for more than 400ms.
  //
  // Understanding the Doherty Threshold: Computer interactions must occur within 400 milliseconds to eliminate the perception of waiting, ensuring users stay immersed and productive. This principle recognizes the human attention span and the impact of response time on the overall user experience.
  //
  // Our mutation hook returns a mutationLoading boolean which is true while the mutation is in flight. This value can be used to change the UI to reflect mutation loading state
  // However, many mutations are so quick to resolve that they do not merit a loading state, as it would be too quick of a flicker in-and-out to be meaningful, at best. At worst, disruptive.
  // I have added another return value to the mutation hook: `mutationLongLoading`, which is `false` until the mutation has been in flight for longer than a certain threshold (400ms), when it becomes `true`.
  // This would allow the UI to remain unchanging / instant for quick mutations, while for longer mutations, the user will be reassured that their input was received, and the system is working on their request.
  const [longLoading, setLongLoading] = useState(loading)
  useEffect(() => {
    if (loading) {
      const timeout = setTimeout(() => {
        setLongLoading(true)
      }, 400)
      return () => clearTimeout(timeout)
    }
    setLongLoading(false)
  }, [loading])

  return [
    singleMutate,
    {
      apolloError,
      called,
      errorMessages: useMemo(() => flatMap(errors, ({ messages }) => messages), [errors]),
      errors,
      failed: !!(called && data && !success),
      formErrors: useMemo(() => formErrorsFromMutationErrors(errors), [errors]),
      loading,
      longLoading,
      reduced,
      success,
    },
  ]
}

// Return true if the errors object contains the error.
export function includesError<TMutationErrors extends MutationErrorWithTypename[]> (errors: TMutationErrors | null | undefined, key: string, message?: string): boolean {
  return !!find(errors, (error) => error.key === key && (!message || includes(error.messages, message)))
}
