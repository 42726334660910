import { forwardRef, memo } from 'react'
import FaIcon from 'd2/components/FaIcon'
import SolidButton from '../SolidButton'
import useStyles from './styles'
import useTranslations from './translations'
import type { SubmitButtonProps } from './types'

const SubmitButton = memo<SubmitButtonProps>(forwardRef(({
  children,
  className,
  mutationLoading,
  success,
  type = 'submit',
  ...props
}, ref) => {
  const { classes, cx } = useStyles()
  const t = useTranslations()
  const resolvedIcon = mutationLoading && (<FaIcon
    icon='spinner'
    spin
    weight='solid'
  />)

  const resolveIconWithWrapper = resolvedIcon && (<div
    className={cx(classes.iconWrapper)}
  >
    { resolvedIcon }
  </div>)

  return (<SolidButton
    {...props}
    className={cx({
      [classes.success]: success,
    }, className)}
    ref={ref}
    type={type}
  >
    { resolveIconWithWrapper }
    { mutationLoading && children ? t.loading : children }
  </SolidButton>
  )
},
))

SubmitButton.displayName = 'SubmitButton'

export default SubmitButton
