import { CODE_FORBIDDEN, CODE_UNAUTHORIZED } from 'd2/constants/status_codes'
import { memo, useEffect } from 'react'
import { useApolloClient } from '@apollo/client'
import HyperLink from 'd2/components/Typography/HyperLink'
import useSnackbar from 'd2/hooks/useSnackbar'

// The tests/specs for this are in spec/requests/dashboard_2/ApolloErrorReduxConnectorProvider_spec.rb
const ApolloErrorReduxConnectorProvider = memo<EO>(() => {
  const { showSnackbar } = useSnackbar()

  const apolloClient = useApolloClient()

  // apolloClient.events is set by Vydia code here: d2/src/utils/apollo/index.tsx
  // @ts-expect-error Property 'events' does not exist on type 'ApolloClient<NormalizedCacheObject>'.ts(2339)
  const { events } = apolloClient

  useEffect(() => {
    const handleEvent = ({ networkError }: {
      operationName: string,
      variables: Record<string, unknown>,
      requestId: string,
      networkError: unknown,
    }) => {
      if (!networkError) return

      const reloadButton = (<HyperLink
        onClick={() => {
          window.location.reload()
        }}
      >
        Click here to reload the page.
      </HyperLink>)

      // @ts-expect-error Property 'statusCode' does not exist on type 'Error | ServerError | ServerParseError'. Property 'statusCode' does not exist on type 'Error'.ts(2339)
      switch (networkError.statusCode) {
      case CODE_UNAUTHORIZED:
      case CODE_FORBIDDEN: {
        showSnackbar({
          hasDismiss: true,
          message: <>
            { 'Oops! You don\'t have access. Maybe your login session has expired? ' }

            { reloadButton }
            { ' If that doesn\'t work, try logging out and then back in.' }
          </>,
          persist: true,
          type: 'error',
        })

        break
      }
      default: {
        showSnackbar({
          hasDismiss: true,
          message: <>
            { 'Oops! Something went wrong. ' }

            { reloadButton }
            { ' If that doesn\'t work, try logging out and then back in.' }
          </>,
          persist: true,
          type: 'error',
        })

        break
      }
      }
    }

    events.on('queryResult', handleEvent)
    events.on('mutationResult', handleEvent)

    return () => {
      events.off('queryResult', handleEvent)
      events.off('mutationResult', handleEvent)
    }
  }, [events, showSnackbar])

  return null
})

export default ApolloErrorReduxConnectorProvider
