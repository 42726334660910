import { SPACING_HALF, SPACING_ZERO } from 'd2/constants'
import { px } from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

const styles = ({ branding }: MergedThemes) => ({
  iconWrapper: {
    paddingRight: px(SPACING_HALF),
  },
  iconWrapperNoSpacing: {
    paddingRight: SPACING_ZERO,
  },
  success: {
    '&:active': {
      backgroundColor: branding?.successActive,
    },
    '&:focus': {
      outlineOffset: SPACING_ZERO,
    },
    '&:hover': {
      backgroundColor: branding?.successHover,
    },
    backgroundColor: branding?.success,
  },
} as const)

export default makeStyles({
  name: 'SubmitButton',
})(styles)
