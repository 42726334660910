import { Link as RouterDomLink } from 'react-router-dom'
import { forwardRef, memo } from 'react'
import type { Props } from './types'

const Link = memo<Props>(forwardRef((props, ref) => (
  <RouterDomLink
    data-turbolinks='false'
    ref={ref}
    {...props}
  />
)))

Link.displayName = 'Link'

export default Link
