import makeStyles from 'd2/hooks/tss/makeStyles'

const styles = () => ({
  inputForNonAnimatedLabel: {
    // default for the animated version is 16px, which you have to shut off when doing labelPosition='top'
    'label + &': {
      marginTop: 0,
    },
  },
} as const)

export default makeStyles({
  name: 'TextField',
})(styles)
