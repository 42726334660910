/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Flexbox } from 'd2/components/Layout'
import {
  isNil,
  omit,
  reject,
  size,
  toString,
} from 'lodash-es'
import {
  memo,
  useCallback,
  useMemo,
} from 'react'
import { useFieldValue } from '../FieldValue/hook'
import BodySmall from 'd2/components/Typography/BodySmall'
import Input from '@mui/material/Input'
import InputContainer from '../InputContainer'
import useStyles from './styles'
import useTranslations from './translations'
import type { FieldBaseProps } from '../types'

const length: (a: any) => string = (value) => toString(size(toString(value || '')))

// some properties were taken from https://github.com/flow-typed/flow-typed/blob/master/definitions/npm/%40material-ui/core_v1.x.x/flow_v0.58.x-/core_v1.x.x.js
// https://material-ui.com/api/text-field/ for property descriptions
export type OwnProps = FieldBaseProps & {
  autoComplete?: string,
  classes?: {
    [x: string]: string
  },
  endAdornment?: React$Node,
  maxLength?: number | null,
  muiMaxLength?: number | null,
  multiline?: boolean,
  onChange?: (a?: string | null) => Promise<void> | void,
  onClick?: (a?: string | null) => void,
  onFocus?: (a?: string | null) => void,
  placeholder?: string,
  rows?: number | string,
  rowsMax?: number | string,
  showLength?: boolean,
  startAdornment?: React$Node,
  testID?: string,
  type?: 'number' | 'password'
}

export type Props = OwnProps

const TextField = memo<Props>(({
  autoComplete = 'off',
  boldLabel = false,
  className,
  disabled,
  endAdornment,
  errorKey,
  errorKeys,
  errorPlacement,
  fullWidth,
  helperText: helperTextProp,
  id,
  label,
  labelPosition = 'topOrPlaceholder',
  maxLength,
  muiMaxLength,
  onChange: onChangeProp,
  path,
  renderError,
  required,
  resourceId,
  resourceType,
  showLength,
  startAdornment,
  tooltipText,
  ...otherProps
}) => {
  const { classes, cx } = useStyles()
  const t = useTranslations()
  const {
    computedId,
    computedPath,
  } = useMemo(() => (
    {
      computedId: (id || path) && reject([resourceType, resourceId, id || path], isNil).join('-'),
      computedPath: path && reject([resourceType, resourceId, path], isNil).join('-'),
    }
  ), [
    id,
    path,
    resourceId,
    resourceType,
  ])

  const {
    disableHtml5Required,
    error,
    fieldTestID,
    isDisabled,
    onChange,
    updateError,
    validationError,
    value,
  } = useFieldValue({
    disabled,
    errorKey,
    errorKeys,
    path,
    resourceId,
    resourceType,
  })

  const helperText: React$Node | null | undefined = useMemo(
    () =>
      maxLength
        ? <Flexbox
          align='spaceBetween'
          vertical={false}
        >
          <BodySmall color='gray'>
            { helperTextProp }
          </BodySmall>
          { maxLength && <BodySmall color='gray'>
            { t.characterCountMax(length(value), maxLength) }
          </BodySmall> }
        </Flexbox>
        : showLength
          ? <Flexbox
            align='spaceBetween'
            vertical={false}
          >
            <BodySmall color='gray'>
              { helperTextProp }
            </BodySmall>
            <BodySmall color='gray'>
              { t.characterCount(length(value)) }
            </BodySmall>
          </Flexbox>
          : helperTextProp,
    [
      helperTextProp,
      maxLength,
      showLength,
      t,
      value,
    ],
  )

  const handleOnChange = useCallback(({ target: { value: inputValue } }: {
    target: {
      value: string | null
    }
  }) => {
    onChange(inputValue)
    if (typeof onChangeProp === 'function') onChangeProp(inputValue)
  }, [onChange, onChangeProp])

  const handleFocus = useCallback(() => {
    if (error) {
      updateError(validationError, {
        matched: false,
        valueForError: value,
        visible: false,
      })
    }
  }, [
    validationError,
    value,
    updateError,
    error,
  ])

  const onWheel = useCallback((event: any) => event.target.blur(), [])

  return (
    <InputContainer
      boldLabel={boldLabel}
      className={className}
      disabled={isDisabled}
      error={error}
      errorPlacement={errorPlacement || 'helpText'}
      fullWidth={fullWidth}
      helperText={helperText}
      id={computedId}
      label={label}
      labelPosition={labelPosition}
      renderError={renderError}
      required={required}
      tooltipText={tooltipText}
    >
      { /* @ts-expect-error TODO: Resolve Conflict with Material type */ }
      <Input
        {...otherProps}
        autoComplete={autoComplete}
        classes={omit(classes, 'inputForNonAnimatedLabel')}
        className={cx({
          [classes.inputForNonAnimatedLabel]: labelPosition !== 'topOrPlaceholder',
        })}
        data-test-id={fieldTestID}
        disabled={disabled}
        endAdornment={endAdornment}
        error={!!error}
        fullWidth={fullWidth}
        id={computedId}
        inputProps={{ maxLength: muiMaxLength }}
        name={computedPath}
        onChange={handleOnChange}
        onFocus={handleFocus}
        onWheel={onWheel}
        required={required && !disableHtml5Required}
        startAdornment={startAdornment}
        value={isNil(value) ? '' : value}
      />
    </InputContainer>
  )
})

TextField.displayName = 'TextField'

export default TextField
