import { gql } from '@apollo/client'
import { useQuerySwitch } from 'd2/hooks/useQuerySwitch'
import type { ReducerArg } from 'd2/hooks/useQuerySwitch'
import type { UserOtpQrCode } from 'd2/queries'

const reducer = ({ data }: ReducerArg<UserOtpQrCode>) => {
  if (!data) return
  return {
    otpQrCode: data.mfa.otp_authenticator_qr_code,
  }
}
export const useQrCodeQuery = () => useQuerySwitch<UserOtpQrCode, EO, typeof reducer>(gql`
  query UserOtpQrCode {
    mfa {
      id
      otp_authenticator_qr_code
    }
  }
`, {
  reducer,
})
