import { DEFAULT_LINE_HEIGHT } from 'd2/constants/fonts'
import { SPACING_ZERO } from 'd2/constants'
import { em, px } from 'd2/utils/style'
import { screenXsMax } from 'd2/utils/breakpoints'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'
import type { OutlineButtonProps } from './types'

const BUTTON_BORDER_SMALL = 1
const styles = (
  { branding, colors, fontNormalStyles, palette }: MergedThemes,
  { color }: OutlineButtonProps,
) => {
  const colorName = color && branding?.[`${color}Color`]
  return {
    button: {
      ...fontNormalStyles,
      '&:disabled': {
        backgroundColor: branding?.disabled,
        color: branding?.disabledText,
      },
      '&:visited': {
        color: branding?.primaryButtonTextColor,
      },
      borderRadius: SPACING_ZERO,
      boxShadow: 'none',
      color: branding?.primaryButtonTextColor,
      lineHeight: em(DEFAULT_LINE_HEIGHT),
      minHeight: SPACING_ZERO,
      minWidth: SPACING_ZERO,
      textAlign: 'center',
      textTransform: 'none',
      [`@media (max-width: ${px(screenXsMax)})`]: {
        marginLeft: SPACING_ZERO,
      },
      whiteSpace: 'nowrap',
    },
    outline: {
      '&:disabled': {
        backgroundColor: colors.transparent,
        border: `solid ${px(BUTTON_BORDER_SMALL)} ${
          branding?.disabledText ?? 'transparent'
        }`,
        color: branding?.disabledText,
      },
      '&:hover': {
        backgroundColor: colorName,
        color: `${palette.common.white} !important`,
      },
      '&:visited': {
        backgroundColor: colors.transparent,
        border: `solid ${px(BUTTON_BORDER_SMALL)} ${
          colorName ?? 'transparent'
        }`,
        color: colorName,
      },
      backgroundColor: colors.transparent,
      border: `solid ${px(BUTTON_BORDER_SMALL)} ${colorName ?? 'transparent'}`,
      color: colorName,
    },
  } as const
}

export default makeStyles<OutlineButtonProps>({
  name: 'OutlineButton',
})(styles)
