import sortEnumsByName from 'd2/queries/sortEnumsByName'
import type { SearchBy } from 'd2/queries/enumSearchTypes'
import type { SortBy } from 'd2/queries/enumSortTypes'

export function advancedSearchOptionsFor<T extends SearchBy | SortBy | null> (name: T): T extends keyof typeof sortEnumsByName ? typeof sortEnumsByName[T] : undefined {
  // @ts-expect-error Type 'T' cannot be used to index type '{ readonly AdditionalServiceCategory: readonly ["DESIGN", "SECURITY", "CONTENT"]; readonly AdditionalServiceType: readonly ["SOCIAL", "PREMIUM_PARTNER", "AUDIO"]; readonly AgreementType: readonly ["UCLA", "RCA", "CLA"]; ... 88 more ...; readonly __DirectiveLocation: readonly [...]; }'.
  return sortEnumsByName[name]
}

export default advancedSearchOptionsFor
