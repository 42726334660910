import { gql } from '@apollo/client'
import { useQuerySwitch } from 'd2/hooks/useQuerySwitch'
import type { ReducerArg } from 'd2/hooks/useQuerySwitch'
import type { StorageSpaceBanner } from 'd2/queries'

const reducer = ({ data }: ReducerArg<StorageSpaceBanner>) => data
export const useQuery = () => useQuerySwitch<StorageSpaceBanner, EO, typeof reducer>(gql`
  query StorageSpaceBanner {
      me {
        id
        exceeded_storage_limit
        has_hidden_payment_plan
      }
    }
`, {
  pollInterval: 20 * 60 * 1000,
  reducer,
})
