/*
Top level component that provides basic integrations for Redux, Apollo, Router, etc.

There are multiple providers. Wrap individual components in this to create standalone components.
*/
import { Component } from 'react'
import BaseProvider from './BaseProvider'
import apollo from 'd2/utils/apollo'
import createStoreResources from 'd2/store/createStoreResources'
import type { History } from 'history'
import type { Store } from 'd2/types'
import type { StoreResources } from 'd2/store/createStoreResources'

type Props = {
  children?: React$Node,
  includeErrorBoundary?: boolean | null
}

export default class extends Component<Props> {
  history: History
  store: Store

  constructor (props: Props) {
    super(props)

    const {
      history,
      store,
    }: StoreResources = createStoreResources()
    this.history = history
    this.store = store
  }

  render = () => {
    const { history, props: { children, includeErrorBoundary = true }, store } = this

    return (
      <BaseProvider
        apolloClient={apollo()}
        history={history}
        includeErrorBoundary={includeErrorBoundary}
        store={store}
      >
        { children }
      </BaseProvider>
    )
  }
}
