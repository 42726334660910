import {
  Children,
  SyntheticEvent,
  forwardRef,
  memo,
  useCallback,
} from 'react'
import { forwardProps } from 'd2/utils/props'
import { isNil } from 'lodash-es'
import { track2 } from 'd2/analytics'
import FaIcon from 'd2/components/FaIcon'
import Link from 'd2/components/Link'
// eslint-disable-next-line consistent-default-export-name/default-import-match-filename
import MaterialButton from '@mui/material/Button'
import useStyles from './styles'
import useTranslations from './translations'
import type { ElementType } from 'react'
import type { FontAwesomeIcon } from 'd2/components/FaIcon/types'
import type { ReactDomProps } from 'd2/types'

export type Color = 'default' | 'inherit' | 'primary' | 'secondary'
export type Size = 'large' | 'medium' | 'small'
export type Variant = 'contained' | 'danger' | 'outlined' | 'primary-inverted' | 'primary' | 'text'
export type Weight = 'light' | 'regular' | 'solid'

type SelfProps = {
  align?: string,
  blank?: boolean,
  block?: boolean,
  children?: React$Node,
  className?: string,
  color?: Color,
  component?: ElementType,
  danger?: boolean,
  disabled?: boolean,
  disableFocusRipple?: boolean,
  disableRipple?: boolean,
  endIcon?: React$Node,
  fullWidth?: boolean,
  ghost?: boolean,
  highlight?: boolean,
  href?: string,
  icon?: FontAwesomeIcon | React$Node | (() => React$Node) | null | undefined,
  iconWeight?: Weight | null,
  inline?: boolean,
  link?: boolean,
  linkBlack?: boolean,
  mutationLoading?: boolean,
  noBorder?: boolean,
  noHover?: boolean,
  noPadding?: boolean,
  onCard?: boolean,

  onClick?: (event: Event | React.SyntheticEvent<any>) => void,
  primary?: boolean,
  rel?: 'nofollow',
  secondary?: boolean,
  size?: Size | null | undefined,
  spin?: boolean,
  success?: boolean,
  target?: '_blank',
  tertiary?: boolean,
  text?: React$Node | null,
  to?: string,
  type?: 'button' | 'reset' | 'submit' | undefined,
  variant?: Variant | null,
  weight?: Weight | null,
  withHover?: boolean
}

export type OwnProps = Omit<ReactDomProps, keyof SelfProps> & SelfProps

type Props = OwnProps

const DEFAULT = 'default'
const SMALL = 'small'
const MEDIUM = 'medium'
const LARGE = 'large'

const ButtonIcon = memo<{
  icon: FontAwesomeIcon,
  spin?: boolean,
  weight: Weight
}>(({
  icon,
  spin,
  weight,
}) => (
  <FaIcon
    icon={icon}
    spin={spin}
    weight={weight}
  />
))

ButtonIcon.displayName = 'ButtonIcon'

const Button = memo<Props>(forwardRef<HTMLButtonElement, Props>(({
  align,
  blank,
  block,
  children,
  className,
  danger,
  disabled,
  ghost,
  highlight,
  href,
  icon,
  iconWeight,
  link,
  linkBlack,
  mutationLoading,
  noBorder,
  noPadding,
  onCard,
  primary,
  secondary,
  size,
  success,
  tertiary,
  text,
  to,
  variant,
  ...props
}, ref) => {
  const { classes, cx } = useStyles()
  const t = useTranslations()
  const iconOnly: boolean = !text && Children.count(children) === 0
  const resolvedIcon = mutationLoading
    ? (<ButtonIcon
      icon='spinner'
      spin
      weight={iconWeight ?? 'solid'}
    />)
    : icon && typeof icon === 'function'
      ? icon()
      : typeof icon === 'string'
        ? <ButtonIcon
          // TODO: Remove 'as' type assertions because they are unsafe.
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          icon={icon as FontAwesomeIcon}
          weight={iconWeight ?? 'solid'}
        />
        : icon

  const resolveIconWithWrapper = resolvedIcon
    ? (<div
      className={cx({
        [classes.iconWrapper]: !iconOnly,
        [classes.iconWrapperNoSpacing]: iconOnly,
      })}
    >
      { resolvedIcon }
    </div>)
    : null

  const handleOnClick = useCallback<React.MouseEventHandler>((event) => {
    if (props.testID) {
      track2('button_press', { button_id: props.testID })
    }
    if (props.onClick) {
      props.onClick(event)
    }
  }, [props])

  return (
    <MaterialButton
      {...forwardProps(props)}
      color={
        primary
          ? 'primary'
          : secondary
            ? 'secondary'
            : 'primary'
      }
      data-test-id={props.testID}
      data-turbolinks='false'
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      disabled={disabled || mutationLoading}
      disableRipple
      variant={link || highlight ? 'text' : 'contained'}
      {...to
        ? {
          component: Link,
          to,
        }
        : {
          component: 'button',
          href,
        }}
      className={cx({
        [classes.alignLeft]: align === 'left',
        [classes.alignRight]: align === 'right',
        [classes.button]: !blank,
        // @ts-expect-error This condition will always return 'false' since the types 'Size | null | undefined' and '"default"' have no overlap.ts(2367)
        [classes.default]: size === DEFAULT || size === null,
        [classes.small]: size === SMALL,
        [classes.medium]: size === MEDIUM,
        [classes.large]: size === LARGE,
        [classes.danger]: danger,
        [classes.link]: link,
        [classes.linkBlack]: linkBlack,
        [classes.ghost]: ghost,
        [classes.tertiary]: tertiary,
        [classes.primary]: primary,
        [classes.primaryInverted]: variant === 'primary-inverted',
        [classes.secondary]: secondary,
        [classes.success]: success,
        [classes.highlight]: highlight,
        [classes.block]: block,
        [classes.blank]: blank,
        [classes.onCard]: onCard,
        [classes.noBorder]: isNil(noBorder) ? !!icon && Children.count(children) === 0 : noBorder,
        [classes.noPadding]: noPadding,
      }, className)}
      onClick={handleOnClick}
      ref={ref}
      rel={props.target === '_blank' ? 'noopener noreferrer' : undefined}
    >
      { resolveIconWithWrapper }
      { mutationLoading && (text || children) ? t.loading : text ?? children }
    </MaterialButton>
  )
}))

Button.displayName = 'Button'

export default Button
