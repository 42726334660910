import { ModalContext, buildModalId } from 'd2/providers/ModalProvider'
import { d } from 'd2/utils'
import { isEqual } from 'lodash-es'
import { useContext, useMemo } from 'react'
import type {
  ContextValue,
  ModalId,
  ModalKey,
} from 'd2/providers/ModalProvider/types'

export type EnhancedContextValue = ContextValue & {
  isOpen: boolean | null | undefined
}

export type UseModalProps = {
  modalIndex?: string | null,
  modalKey?: ModalKey | null
}

export default function useModal (props?: UseModalProps | null): EnhancedContextValue {
  const {
    modalIndex: useModalIndex,
    modalKey: useModalKey,
  } = d(props)
  const {
    hideModal,
    modalCustomValue,
    modalId,
    modalIndex,
    modalKey,
    showModal,
  }: ContextValue = useContext(ModalContext)

  const useModalId: ModalId | null | undefined = useMemo(
    () => buildModalId(useModalKey, useModalIndex),
    [useModalIndex, useModalKey],
  )

  const isOpen: boolean | null | undefined = useMemo(
    () => !!(useModalId && modalId && isEqual(useModalId, modalId)),
    [modalId, useModalId],
  )
  return useMemo(
    () => ({
      hideModal,
      isOpen,
      modalCustomValue,
      modalId,
      modalIndex,
      modalKey,
      showModal: (key = useModalId, customValue = undefined) => showModal(key, customValue),
    }),
    [
      hideModal,
      isOpen,
      modalCustomValue,
      modalId,
      modalIndex,
      modalKey,
      showModal,
      useModalId,
    ],
  )
}
