import { px } from 'd2/utils/style'
import makeStyles, { CSSObject } from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

const styles = (
  {
    fontBoldStyles,
    fontNormalStyles,
    ...theme
  }: MergedThemes,
) => {
  const shared = {
    ...fontNormalStyles,
    color: theme.palette.grey['500'],
  } as const satisfies CSSObject
  const labelHorizontal = {
    ...shared,
    fontSize: px(12),
  } as const satisfies CSSObject
  const labelTop = {
    ...shared,
    fontSize: px(12),
  } as const satisfies CSSObject

  const INFO_ICON_FONT_SIZE = 12
  const INFO_ICON_MARGIN_LEFT = 4

  return {
    containerForLeftLabel: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
    },
    containerForRightLabel: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    containerForTopCenterLabel: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    containerForTopLabel: {
      display: 'flex',
      flexDirection: 'column',
    },
    formControlRoot: {
      verticalAlign: 'unset',
    },
    labelHorizontal,
    labelHorizontalBold: {
      ...labelHorizontal,
      ...fontBoldStyles,
    },
    labelTop,
    labelTopBold: {
      ...labelTop,
      ...fontBoldStyles,
    },
    labelTopOrPlaceholder: {
      ...shared,
      fontSize: px(16), // have to enlarge this because when the label is above the form control it gets transformed by 0.75
    },
    shared,
    tooltip: {
      cursor: 'pointer',
      fontSize: px(INFO_ICON_FONT_SIZE),
      marginLeft: px(INFO_ICON_MARGIN_LEFT),
      pointerEvents: 'all',
    },
    tooltipFlexbox: {
      lineHeight: 'normal', // This prevents the tooltip icon from adding too much height.
    },
  } as const
}

// TODO: Optimize for typescript
export default makeStyles({
  name: 'InputContainer',
})(styles)
