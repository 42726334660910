import { UseOtpModalParams, UseOtpModalReturn } from 'd2/hooks/useOtpModal/types'
import OneTimePasswordModal from 'd2/components/OneTimePasswordModal'
import useModal from 'd2/hooks/useModal'

const useOtpModal = ({
  modalProps,
}: UseOtpModalParams): UseOtpModalReturn => {
  const { hideModal, isOpen, showModal } = useModal({
    modalIndex: modalProps.modalIndex,
    modalKey: 'OneTimePasswordModal',
  })

  return {
    hideModal,
    otpModal: isOpen ? <OneTimePasswordModal {...modalProps} /> : null,
    showModal,
  }
}

export default useOtpModal
