import { SingleOTPInputProps } from './types'
import {
  memo,
  useLayoutEffect,
  useRef,
} from 'react'
import usePrevious from 'd2/hooks/usePrevious'
import useStyles from '../styles'

export function SingleOTPInputComponent (props: SingleOTPInputProps) {
  const { autoFocus, focus, ...rest } = props
  const {
    classes,
  } = useStyles()
  const inputRef = useRef<HTMLInputElement>(null)
  const prevFocus = usePrevious(!!focus)
  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus()
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus()
        inputRef.current.select()
      }
    }
  }, [autoFocus, focus, prevFocus])

  return (<input
    autoComplete="one-time-code"
    className={classes.input}
    inputMode="numeric"
    pattern="[0-9]"
    ref={inputRef}
    {...rest}
  />)
}

const SingleOTPInput = memo(SingleOTPInputComponent)
export default SingleOTPInput
