import { DocumentNode } from 'd2/types'
import { MutationReducerArg, useSingleMutation } from 'd2/hooks/useMutation'
import {
  OneTimePassword,
  SendOTPCode,
  SendOTPCodeVariables,
} from 'd2/queries'
import { ReducerArg, useQuerySwitch } from 'd2/hooks/useQuerySwitch'
import { gql } from '@apollo/client'

export const QUERY: DocumentNode = gql`
  query OneTimePassword {
    mfa {
      id
      is_admin
      phone
      otp_mfa
      otp_mfa_method
      otp_expired
      otp_authenticator_secret
    }
    observer_signed_in
  }
`

const reducer = ({ data }: ReducerArg<OneTimePassword>) => {
  if (!data) return
  return {
    isAdmin: data.mfa.is_admin,
    observerSignedIn: data.observer_signed_in,
    otpAuthSecret: data.mfa.otp_authenticator_secret,
    otpExpired: data.mfa.otp_expired,
    otpMfa: data.mfa.otp_mfa,
    otpMfaMethod: data.mfa.otp_mfa_method,
    phoneNumber: data.mfa.phone,
  }
}
export function useOneTimePasswordQuery () {
  return useQuerySwitch<OneTimePassword, EO, typeof reducer>(QUERY, {
    fetchPolicy: 'no-cache', // To prevent OTP secret from getting cached in user's browser
    reducer,
  })
}

export const SEND_MUTATION = gql`
  mutation SendOTPCode($input: SendOTPCodeInput!) {
    sendOTPCode(input: $input) {
      user {
        id
        phone
        mfa {
          id
          otp_mfa
          otp_expired
        }
      }
      errors {
        key
        messages
      }
    }
  }
`

const sendReducer = ({ data }: MutationReducerArg<SendOTPCode, SendOTPCodeVariables>) => ({ errors: data?.sendOTPCode.errors })

export const useSendOtpMutation = () => useSingleMutation<SendOTPCode, SendOTPCodeVariables, typeof sendReducer>(SEND_MUTATION, {
  reducer: sendReducer,
})
