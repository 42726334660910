import type {
  FormValues,
  Reducer,
  State,
} from './types'

const buildDefaultFormValues: () => FormValues = () => ({
  address: null,
  address2: null,
  city: null,
  country: null,
  dob: null,
  entityName: null,
  firstName: null,
  lastName: null,
  postal: null,
  signatoryTitle: null,
  signature: null,
  state: null,
})

export const init: () => State = () => ({
  formType: null,
  formValues: buildDefaultFormValues(),
})

const reducer: Reducer = (state = init(), action) => {
  switch (action.type) {
  case 'UCLA_SELECT_FORM_TYPE': {
    return {
      ...state,
      formType: action.payload.formType,
      formValues: {
        ...state.formValues,
      },
    }
  }
  case 'UCLA_UPDATE_FORM': {
    return {
      ...state,
      formValues: {
        ...state.formValues,
        ...action.payload.formValues,
      },
    }
  }
  case 'UCLA_RESET_FORM': {
    return init()
  }
  default: {
    return state
  }
  }
}

export default reducer
