import {
  Col,
  Container,
  Grid,
} from 'd2/components/Grid'
import { MODAL_WIDTH } from './constants'
import { SPACING_ZERO } from 'd2/constants'
import { isD2Path, navigate } from 'd2/utils/Navigation'
import { isNil } from 'lodash-es'
import { makeTable } from 'd2/components/TableV2'
import {
  memo,
  useCallback,
  useMemo,
} from 'react'
import { prettyFileSize, prettyPrice } from 'd2/utils/StringHelpers'
import { useDispatch } from 'react-redux'
import { useLocation } from 'd2/hooks/useRouter'
import { useQuery } from './queries'
import Button from 'd2/components/Button'
import Divider from 'd2/components/Divider'
import Heading2 from 'd2/components/Typography/Heading2'
import Modal from 'd2/components/Modal'
import Spacer from 'd2/components/Spacer'
import StorageConsumptionProgressBar from 'd2/components/StorageConsumptionProgressBar'
import useModal from 'd2/hooks/useModal'
import useStyles from './styles'
import useTranslations from './translations'
import useUrlPagination from 'd2/hooks/useUrlPagination'
import type { Dispatch } from 'd2/actions'
import type { Props } from './types'

const StorageTierModal = memo<Props>((
  {
    forceOpenModal,
    modalKey = 'StorageTier',
  },
) => {
  const { hideModal } = useModal({
    modalKey,
  })
  const dispatch = useDispatch<Dispatch>()
  const closeModalHandler = useCallback(() => {
    hideModal()
  }, [hideModal])
  const location = useLocation()
  const closeModalAndNavigateHandler = useCallback(() => {
    hideModal()
    navigate({
      dispatch,
      location,
      to: '/d2/storage',
    })
  }, [dispatch, hideModal, location])
  const { classes } = useStyles()
  const useTablePagination = () => useUrlPagination({ paramPrefix: 'plans' })
  const { RowCell, Table } = makeTable<{ type: string, current: string, next: string }, '', EO>()
  const [{ me }] = useQuery()
  const t = useTranslations()
  const heading = useMemo(() => (<div>
    <Heading2>
      { t.modalHeading }
    </Heading2>
    <Divider />
    <StorageConsumptionProgressBar />
    <Spacer />
  </div>), [t])
  const onClick = useCallback(() => {
    if (forceOpenModal) {
      closeModalAndNavigateHandler()
    } else {
      closeModalHandler()
    }
    return false
  }, [forceOpenModal, closeModalAndNavigateHandler, closeModalHandler])
  const tableData = useMemo(() => {
    if (!me?.next_required_storage_tier) return []
    const {
      active_storage_tier: activeStorageTier,
      next_required_storage_tier: nextRequiredStorageTier,
    } = me
    const currentStorage = activeStorageTier.tier_permission.size_in_bytes
    const nextStorage = nextRequiredStorageTier.tier_permission.size_in_bytes
    return [
      {
        current: isNil(currentStorage)
          ? t.unlimited
          : prettyFileSize(currentStorage, true),
        next: isNil(nextStorage)
          ? t.unlimited
          : prettyFileSize(nextStorage, true),
        type: t.storage,
      },
      {
        current: `${activeStorageTier.tier_permission.max_artist_count ?? 0}`,
        next: `${nextRequiredStorageTier.tier_permission.max_artist_count ?? 0}`,
        type: t.maxArtists,
      },
      {
        current: `${prettyPrice(activeStorageTier.tier_permission.yearly_price)} ${t.perYear}`,
        next: `${prettyPrice(nextRequiredStorageTier.tier_permission.yearly_price)} ${t.perYear}`,
        type: t.cost,
      },
    ]
  }, [me, t])

  const useTablePagination2 = useCallback(useTablePagination, [])

  return (
    <Modal
      heading={heading}
      maxWidthPx={MODAL_WIDTH}
      modalKey={modalKey}
      onToggleClose={onClick}
      testID='StorageTierModal'
    >
      <Container className={classes.content}>
        <Table
          borderTop
          data={tableData}
          testID='StorageTierModal-Table-plans'
          useTablePagination={useTablePagination2}
        >
          <RowCell
            align='left'
            id='type'
            label=""
          >
            {
              ({ type }) => type
            }
          </RowCell>
          <RowCell
            align='center'
            id='current'
            label={t.currentStoragePlan}
          >
            {
              ({ current }) => current
            }
          </RowCell>
          <RowCell
            align='center'
            id='next'
            label={t.upgradedStoragePlan}
          >
            {
              ({ next }) => next
            }
          </RowCell>
        </Table>
        <Grid
          alignItems='center'
          className={classes.buttonWrapper}
          direction='row-reverse'
          justifyContent='center'
          spacing={SPACING_ZERO}
        >
          <Col md={6}>
            <Button
              block
              id='upgrade-to-next-tier-btn'
              onClick={closeModalHandler}
              primary
              {...isD2Path(location)
                ? {
                  to: '/d2/storage/plans',
                }
                : {
                  href: '/storage/upgrade',
                }}
            >
              { t.upgradeButtonText }
            </Button>
          </Col>
          <Col md={6}>
            <Button
              block
              danger
              onClick={onClick}
            >
              { t.cancelText }
            </Button>
          </Col>
        </Grid>
      </Container>
    </Modal>
  )
},
)

StorageTierModal.displayName = 'StorageTierModal'

export default StorageTierModal
