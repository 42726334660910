import { DocumentNode } from 'd2/types'
import { ReducerArg, useQuerySwitch } from 'd2/hooks/useQuerySwitch'
import { SecuritySettingsPartial } from 'd2/queries'
import { gql } from '@apollo/client'

export const QUERY: DocumentNode = gql`
  query SecuritySettingsPartial {
    mfa {
      id
      phone
      otp_mfa
    }
  }
`

const reducer = ({ data }: ReducerArg<SecuritySettingsPartial>) => {
  if (!data) return

  return {
    otpMfa: data.mfa.otp_mfa,
    phoneNumber: data.mfa.phone,
  }
}
export function useSecuritySettingsQuery () {
  return useQuerySwitch<SecuritySettingsPartial, EO, typeof reducer>(QUERY, {
    reducer,
  })
}
