import { forwardRef, memo } from 'react'
import BlankButton from '../BlankButton'
import useStyles from './styles'
import type { LinkButtonProps } from './types'

const LinkButton = memo<LinkButtonProps>(forwardRef(
  ({
    className,
    ...props
  }, ref) => {
    const { classes } = useStyles()

    return (
      <BlankButton
        {...props}
        className={`${classes.link} ${className ?? ''}`}
        ref={ref}
      />
    )
  },
))

LinkButton.displayName = 'LinkButton'

export default LinkButton
