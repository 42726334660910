import { SPACING } from 'd2/constants'
import { forwardProps } from 'd2/utils/props'
import { isNil, omit } from 'lodash-es'
import { memo } from 'react'
// eslint-disable-next-line consistent-default-export-name/default-import-match-filename
import MuiGrid from '@mui/material/Grid'
import useStyles from './styles'
import useTheme from 'd2/hooks/useTheme'
import type {
  ColOwnProps,
  ContainerOwnProps,
  GridProps,
} from './types'
import type { GridSpacing } from '@mui/material/Grid'

export type { ColOwnProps } from './types'

// TODO: Improve flow typing for these components.

// TODO:
//  - shrinkToContent
//  - fillSpace
export const StretchCol = memo<ColOwnProps>(({
  className,
  ...props
}) => {
  const { classes, cx } = useStyles()
  return (
    // TODO: Optimize for typescript
    <MuiGrid
      {...forwardProps(props)}
      className={cx(classes.col, className)}
      item
    />
  )
})
StretchCol.displayName = 'StretchCol'

export const Col = memo<ColOwnProps>(({ xs, ...props }) => (
  <StretchCol
    xs={xs ?? 12}
    {...props}
  />
))

Col.displayName = 'Col'

export const Grid = memo<GridProps>(({
  centerColumns,
  className,
  fitViewportHeight,
  layout = false, // True when using Grid on a screen level layout, false when using Grid inside cards etc.
  spacing,
  ...props
}) => {
  const { classes, cx } = useStyles()
  const { containerGutterSpacing } = useTheme()

  return (
    <MuiGrid
      {...forwardProps(props)}
      className={cx(className, classes.grid, {
        [classes.fitViewportHeight]: fitViewportHeight,
        [classes.centerColumns]: centerColumns,
      })}
      container
      // TODO: Remove 'as' type assertions because they are unsafe.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      spacing={(layout ? containerGutterSpacing : isNil(spacing) ? SPACING : spacing as number) / 8 as GridSpacing}
    />
  )
})
Grid.displayName = 'Grid'

// TODO: ColProps is not correct here. How to get prop type from 'div' react tag?
export const Container = memo<ContainerOwnProps>(({
  className,
  noPadding,
  noPaddingBottom,
  ...props
}) => {
  const { classes, cx } = useStyles()
  return (
    <div
      {...forwardProps(props)}
      className={cx(className, classes.container, {
        [classes.noPaddingBottom]: noPaddingBottom,
        [classes.noPaddingForAll]: noPadding,
      })}
    />
  )
})
Container.displayName = 'Container'

// TODO: ColProps is not correct here. How to get prop type from 'div' react tag?
export const GridContainer = memo<ContainerOwnProps>(({
  className,
  halfPadding,
  noPadding,
  noPaddingBottom,
  ...props
}) => {
  const { classes, cx } = useStyles()
  return (
    <div
      {...forwardProps(omit(props))}
      className={cx(className, classes.gridContainer, {
        [classes.halfPadding]: halfPadding,
        [classes.noPaddingBottom]: noPaddingBottom,
        [classes.noPaddingForAll]: noPadding,
      })}
    />
  )
})
GridContainer.displayName = 'GridContainer'
