import {
  MAX,
  SPACING,
  SPACING_DOUBLE,
  SPACING_ZERO,
} from 'd2/constants'
import { px } from 'd2/utils/style'
import { screenSmMax } from 'd2/utils/breakpoints'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

const styles = (theme: MergedThemes) => ({
  alignCenter: {
    textAlign: 'center',
  },
  alignLeft: {
    textAlign: 'left',
  },
  alignRight: {
    textAlign: 'right',
  },
  bottomButton: {
    paddingTop: px(SPACING),
    width: MAX,
  },
  childrenWrapper: {
    width: MAX,
  },
  closeButton: {
    color: theme.colors.darkGray,
    cursor: 'pointer',
    fontSize: px(26),
    position: 'absolute',
    right: px(SPACING),
    top: px(SPACING_DOUBLE),
  },
  content: {
    paddingBottom: px(SPACING),
    paddingTop: px(SPACING),
    width: MAX,
  },
  footNote: {
    width: MAX,
  },
  headingWrapper: {
    paddingTop: px(SPACING_DOUBLE),
    width: MAX,
  },
  modalContainer: {
    backgroundColor: theme.palette.common.white,
    boxShadow: '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.2)',
    height: MAX,
    paddingLeft: px(SPACING),
    paddingRight: px(SPACING),
    position: 'relative',
    [`@media (max-width: ${px(screenSmMax)})`]: {
      bottom: 0,
      left: 0,
      position: 'static',
      right: 0,
      top: 0,
    },
  },
  modalWrapper: {
    [`@media (max-width: ${px(screenSmMax)})`]: {
      overflow: 'inherit',
      padding: px(SPACING_ZERO),
    },
    height: MAX,
  },
} as const)

export default makeStyles({
  name: 'SideModal',
})(styles)
