/* eslint-disable react/forbid-elements */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { OperationVariables, useQuery } from '@apollo/client'
import { constant, isNil } from 'lodash-es'
import { d } from 'd2/utils'
import { isProduction } from 'd2/utils/environment'
import Body from 'd2/components/Typography/Body'
import LoadingSpinner from 'd2/components/LoadingSpinner'
import isTestEnv from 'd2/utils/isTestEnv'
import type {
  ApolloClient,
  ApolloError,
  BaseQueryOptions,
  NetworkStatus,
  ObservableQueryFields,
} from '@apollo/client'
import type { DocumentNode } from 'd2/types'
import type { Simplify } from 'type-fest'

// https://blog.logrocket.com/understanding-infer-typescript/

export function useLoadingSwitch (
  {
    error,
    errorWhenMissing,
    loading,
    renderError,
    renderLoading,
    require,
  }: {
    error: Error | null | undefined,
    errorWhenMissing: () => Error,
    loading: boolean,
    renderError: (a: Error) => React$Element | null | undefined,
    renderLoading: () => React$Element | null | undefined,
    require: unknown
  },
): React$Element | null {
  let result: React$Element | null | undefined = null

  if (error) {
    result = renderError(error)
  }

  if (!require) {
    result = loading ? renderLoading() : renderError(errorWhenMissing())
  }

  return result || null
}

export type LoadingSwitchOptionsWithDefaults = {
  error: Error | null | undefined,
  errorWhenMissing?: () => Error,
  loading: boolean,
  renderError?: (a: Error) => React$Element | null | undefined,
  renderLoading?: () => React$Element | null | undefined,
  require: unknown
}

// Default loading switch renders spinner during loading, and an error message if there's an error.
export function useDefaultLoadingSwitch (options: LoadingSwitchOptionsWithDefaults): React$Element | null {
  return useLoadingSwitch({
    ...options,
    errorWhenMissing: options.errorWhenMissing || (() => new Error('Missing required data')),
    renderError: options.renderError || ((error) => (
      <Body>
        <p>
          Oops! Something went wrong.
          { ' ' }
          <a
            href='#'
            onClick={(event) => {
              event.preventDefault()
              if (typeof window === 'undefined') return
              window.location.reload()
            }}
          >
            Reload the page
          </a>
          { ' and try again. ' }
        </p>
        { isProduction()
          ? ''
          : <p>
            <strong>
              DEVELOPER: Search &quot;error&quot; in your yarn server logs. Consider setting Vydia::Settings::LOG_GRAPHQL to true and try again.
            </strong>
          </p> }
        { /* Format error.stack so that each frame is on its own line: */ }
        <pre>
          { `${error.message}. ${isProduction()
            ? ''
            : error.stack?.replace(/\n/g, '\n')
             ?? ''}` }
        </pre>
      </Body>
    )),
    renderLoading: options.renderLoading || (() => (<LoadingSpinner
      fillHeight
      size={50}
    />)),
  })
}

// Ghost loading switch renders null during loading and error.
export function useGhostLoadingSwitch (options: LoadingSwitchOptionsWithDefaults): React$Element | null {
  return useLoadingSwitch({
    ...options,
    errorWhenMissing: options.errorWhenMissing || (() => new Error('Missing required data')),
    renderError: options.renderError || constant(null), // TODO: Even ghost loading switch should display something small when there's an error right? Like "⚠️ <a>Reload</a>"
    renderLoading: options.renderLoading || constant(null),
  })
}

// Most/all of the type exports from apollo libdefs are inexact intersection types, so unfortunately we are required to re-type some of it here.
type GraphqlQueryResult<TData, TVariables extends OperationVariables> = Simplify<
ObservableQueryFields<TData, TVariables> &
{
  called: boolean,
  client: ApolloClient<any>,
  data: TData | null | undefined,
  error?: ApolloError,
  loading: boolean,
  networkStatus: NetworkStatus,
}
>

export type ReducerArg<TData, TVariables extends OperationVariables = EO> = GraphqlQueryResult<TData, TVariables>

type DefaultReducerType<TData, TVariables extends OperationVariables> = ({ data }: GraphqlQueryResult<TData, TVariables>) => TData | undefined
function defaultReducer<TData, TVariables extends OperationVariables> ({ data }: GraphqlQueryResult<TData, TVariables>): TData | undefined {
  if (!data) return

  return data
}

export function useQuerySwitch<
  TData,
  TVariables extends OperationVariables,
  TReducer extends (result: GraphqlQueryResult<TData, TVariables>) => any = DefaultReducerType<TData, TVariables>,
> (
  query: DocumentNode,
  // TODO: Figure out how to type TS so that we require variables when TVariables type is provided & not nullish, but don't require options & don't require options.variables when variables are not required.
  options?: BaseQueryOptions<TVariables> & {
    fallbackPreviousData?: boolean,
    loadingSwitchHook?: (a: LoadingSwitchOptionsWithDefaults) => React$Element | null,
    reducer?: TReducer,
    skip?: boolean,
    suspend?: boolean
  },
): [
    $Call1<TReducer, GraphqlQueryResult<TData, TVariables>>,
    React$Element | null,
  ] {
  let { fallbackPreviousData, loadingSwitchHook: useLoadingSwitchHook, reducer, ...graphqlOptions } = d(options)
  const notNullishReducer = reducer ?? defaultReducer
  graphqlOptions = {
    ...graphqlOptions,
    // This hook replaces fetchPolicy option to 'no-cache' to fix race condition after apollo upgrade. TODO: We shouldn't need this after apollo fixes their shit. Remove the option overrides eventually. From the HOC too
    fetchPolicy: isTestEnv ? 'no-cache' : graphqlOptions.fetchPolicy,
    nextFetchPolicy: isTestEnv ? 'no-cache' : graphqlOptions.nextFetchPolicy,
    pollInterval: isTestEnv ? 0 : graphqlOptions.pollInterval,
  }

  const {
    called,
    client,
    data,
    error,
    fetchMore,
    loading,
    networkStatus,
    previousData,
    refetch,
    reobserve,
    startPolling,
    stopPolling,
    subscribeToMore,
    updateQuery,
    variables,
  } = useQuery<TData, TVariables>(query, graphqlOptions)

  const reduced = notNullishReducer({
    called,
    client,
    data: fallbackPreviousData && previousData && isNil(data) ? previousData : data,
    error,
    fetchMore,
    loading,
    networkStatus,
    refetch,
    reobserve,
    startPolling,
    stopPolling,
    subscribeToMore,
    updateQuery,
    variables,
  })
  // Not sure if passing in the hook is the best way to do it, we might want to update useQuerySwitch to take the loadingSwitch options like renderLoading, etc
  const loadingSwitch: React$Element | null = (useLoadingSwitchHook || useDefaultLoadingSwitch)({
    error,
    loading,
    require: reduced,
  })

  return [reduced, loadingSwitch]
}
