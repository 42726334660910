import { Component, memo } from 'react'
import { forwardProps } from 'd2/utils/props'
import {
  screenLgMin,
  screenMdMin,
  screenSmMax,
  screenXsMax,
} from 'd2/utils/breakpoints'
import { useMediaQuery } from 'react-responsive'
import MediaQuery from 'd2/components/MediaQuery'

type ResponsiveProps = ComponentProps<typeof MediaQuery>

export const Mobile = memo<Omit<ResponsiveProps, 'maxWidth'>>((props) => (
  <MediaQuery
    {...forwardProps(props)}
    maxWidth={screenSmMax}
  />
))

Mobile.displayName = 'Mobile'

export const Phone = memo<Omit<ResponsiveProps, 'maxWidth'>>((props) => (
  <MediaQuery
    {...forwardProps(props)}
    maxWidth={screenXsMax}
  />
))

Phone.displayName = 'Phone'

export const Default = memo<Omit<ResponsiveProps, 'minWidth'>>((props) => (
  <MediaQuery
    {...forwardProps(props)}
    minWidth={screenMdMin}
  />
))

Default.displayName = 'Default'

export function injectResponsive<Config> (
  ResponsiveComponent: typeof Component,
  reducer: (isResponsive: boolean) => Config,
) {
  // eslint-disable-next-line react/display-name
  return (InnerComponent: typeof Component) => (props: any) => (
    <ResponsiveComponent>
      {
        (isResponsive: boolean) => (<InnerComponent
          {...{
            ...props,
            ...reducer(isResponsive),
          }}
        />)
      }
    </ResponsiveComponent>
  )
}

export const injectIsMobile = injectResponsive<{
  isMobile: boolean
}>(// TODO: Remove 'as' type assertions because they are unsafe.
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  Mobile as unknown as typeof Component, (isMobile) => ({ isMobile }))

export function useIsMobile (): boolean {
  return useMediaQuery({ maxWidth: screenSmMax })
}

export function useIsPhone (): boolean {
  return useMediaQuery({ maxWidth: screenXsMax })
}

export function useIsLarge (): boolean {
  return useMediaQuery({ minWidth: screenLgMin })
}
