import useTheme from 'd2/hooks/useTheme'

export default function useRadioSelectedDefault () {
  const theme = useTheme()

  return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 20 20">
    <defs>
      <style>
        .a, .b {
          fill-rule: evenodd;
        }

        .a {
          fill: url(#a);
        }

        .b {
          fill: none;
          stroke: #dfdfdf;
        }

        .c {
          fill: #fff;
        }
      </style>
      <linearGradient id="a" x1="-501.16" y1="523.16" x2="-500.16" y2="522.16" gradientTransform="matrix(19, 0, 0, -19, 9522.5, 9940.5)" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="${theme.branding?.primaryGradientStart ?? 'transparent'}"/>
        <stop offset="1" stop-color="${theme.branding?.primaryGradientEnd ?? 'transparent'}"/>
      </linearGradient>
    </defs>
    <path class="a" d="M10,.5h0A9.5,9.5,0,0,1,19.5,10h0A9.5,9.5,0,0,1,10,19.5h0A9.5,9.5,0,0,1,.5,10h0A9.5,9.5,0,0,1,10,.5Z"/>
    <path class="b" d="M10,.5h0A9.5,9.5,0,0,1,19.5,10h0A9.5,9.5,0,0,1,10,19.5h0A9.5,9.5,0,0,1,.5,10h0A9.5,9.5,0,0,1,10,.5Z"/>
    <path class="c" d="M8,14.47l8.2-8.2a.34.34,0,0,0,0-.46L15.38,5a.32.32,0,0,0-.46,0L7.73,12.22,5.08,9.58a.32.32,0,0,0-.46,0l-.78.78a.34.34,0,0,0,0,.46l3.65,3.65A.33.33,0,0,0,8,14.47Z"/>
  </svg>`
}
