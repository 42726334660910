import type { GenericReducer } from '../types'

export type ScheduleType = 'asap' | 'schedule'

export type State = {
  deployAt: Scalar$DateTime | null | undefined,
  scheduleType: ScheduleType | null | undefined
}

export const init: () => State = () => ({
  deployAt: null,
  scheduleType: null,
})

export type Reducer = GenericReducer<State>
const reducer: Reducer = (state = init(), action) => {
  switch (action.type) {
  case 'SCHEDULE_SELECTOR_UPDATE_SCHEDULE_TYPE': {
    return {
      ...state,
      scheduleType: action.payload.scheduleType,
    }
  }
  case 'SCHEDULE_SELECTOR_UPDATE_DEPLOY_AT': {
    return {
      ...state,
      deployAt: action.payload.deployAt,
    }
  }
  case 'SCHEDULE_SELECTOR_RESET': {
    return init()
  }
  default: {
    return state
  }
  }
}

export default reducer
