import { memo } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Providers from 'd2/providers/standalone'
import useStyles from './styles'

export type OwnProps = {
  fillHeight?: boolean | null,
  size?: number,
  testID?: string
}

type Props = OwnProps

const LoadingSpinner = memo<Props>(({
  fillHeight,
  size,
}) => {
  const { classes, cx } = useStyles()
  return (<div
    className={cx({
      [classes.containerFillHeight]: fillHeight,
    })}
  >
    <div
      className={cx({
        [classes.containerInnerFillHeight]: fillHeight,
      })}
    >
      <div
        className={cx(classes.progressCircleDelay)}
      >
        <CircularProgress
          className={cx(classes.progressCircle)}
          size={size ?? 50}
        />
      </div>
    </div>
  </div>)
})

LoadingSpinner.displayName = 'LoadingSpinner'

export const ComponentWithProviders = memo<EO>(() => (<Providers>
  <LoadingSpinner />
</Providers>))

ComponentWithProviders.displayName = 'LoadingSpinner'

export default LoadingSpinner
