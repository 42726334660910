import { memo, useMemo } from 'react'
import { min } from 'lodash-es'
import { prettyFileSize } from 'd2/utils/StringHelpers'
import { useQuery } from './queries'
import LinearProgress from '@mui/material/LinearProgress'
import useStyles from './styles'
import useTranslations from './translations'
import type { Props } from './types'

const LOADING_CURRENT_SIZE = 1 as const

const StorageConsumptionProgressBar = memo<Props>((
  {
    renderHeader,
  },
) => {
  const { classes, cx } = useStyles()
  const [
    {
      error,
      me,
    },
    querySwitch,
  ] = useQuery()
  const t = useTranslations()
  const currentSize = useMemo<number>(
    () => me?.active_tier_permission.size_in_bytes ?? LOADING_CURRENT_SIZE,
    [me],
  )

  const currentUsage = useMemo<number>(() => me?.current_storage_usage ?? 0, [me])

  const percentUsed = useMemo<number>(() => currentUsage / currentSize * 100, [currentSize, currentUsage])

  if (error) {
    return querySwitch
  }

  return (() => {
    const spaceRemaining: number = Math.max(0, currentSize - currentUsage)

    return (
      <div
        className={classes.progressSectionContainer}
        data-test-id='StorageConsumptionProgressBar'
      >
        { renderHeader?.({
          currentSize,
          currentUsage,
          loading: currentSize === LOADING_CURRENT_SIZE,
          spaceRemaining,
        }) }

        <div className={classes.progressContainer}>
          <p
            className={cx(
              classes.progressText,
              { [classes.progressTextUnder]: Boolean(spaceRemaining) },
            )}
          >
            { currentSize !== LOADING_CURRENT_SIZE && t.progressText(
              {
                currentStorageSize: prettyFileSize(currentSize, true),
                currentStorageUsage: prettyFileSize(currentUsage, true),
              }) }
          </p>
          <LinearProgress
            classes={{
              bar: spaceRemaining === 0 ? classes.progressBarFillFull : classes.progressBarFillNotFull,
              root: classes.progressBarRoot,
            }}
            className={classes.progressBar}
            color='primary'
            data-test-id='StorageConsumptionProgressBar-linear-progress'
            value={min([percentUsed, 100])}
            variant='determinate'
          />
        </div>
      </div>
    )
  })()
})

StorageConsumptionProgressBar.displayName = 'StorageConsumptionProgressBar'

export default StorageConsumptionProgressBar
