import {
  applyMiddleware,
  combineReducers,
  createStore,
} from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { consoleReplacement, isProduction } from 'd2/utils/environment'
import { createBrowserHistory } from 'history'
import { createLogger } from 'redux-logger'
import { createReduxHistoryContext } from 'redux-first-history'
import freeze from 'redux-freeze'
import reducers from 'd2/reducers'
import type { History } from 'history'
import type { Store } from 'd2/types'

export type StoreResources = {
  history: History,
  store: Store
}

const createStoreResources: () => StoreResources = () => {
  const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createBrowserHistory({
      // @ts-expect-error Argument of type '{ getUserConfirmation: (message: any, callback: any) => any; }' is not assignable to parameter of type 'BrowserHistoryOptions'. Object literal may only specify known properties, and 'getUserConfirmation' does not exist in type 'BrowserHistoryOptions'.ts(2345)
      getUserConfirmation: (message, callback) => callback(typeof window !== 'undefined' && window.confirm(message)),
    }),
    reduxTravelling: !isProduction(),
  })

  const reduxMiddleware: Array<any> = []

  if (!isProduction()) {
    reduxMiddleware.push(freeze)
  }

  reduxMiddleware.push(createLogger({
    logger: consoleReplacement,
  }))

  // @ts-expect-error Type 'Store<EmptyObject & { banner: State; contentList: State; createAlbum: State; createAlbumRelease: State; createRelease: State; destinationPicker: State; ... 13 more ...; router: RouterState; }, Action> & { ...; }' is not assignable to type 'Store'.
  // The types of 'getState().router.location' are incompatible between these types.
  // Type 'Location | null | undefined' is not assignable to type 'BrowserLocation'.
  //   Type 'undefined' is not assignable to type 'BrowserLocation'.ts(2322)
  const store: Store = createStore(
    combineReducers({
      router: routerReducer,
      ...reducers,
    }),
    composeWithDevTools(
      applyMiddleware(...reduxMiddleware),
      applyMiddleware(routerMiddleware),
    ),
  )

  const history = createReduxHistory(store)

  return {
    history,
    store,
  }
}

export default createStoreResources
