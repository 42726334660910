/* eslint-disable @typescript-eslint/no-shadow */
import {
  createContext,
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { noop } from 'lodash-es'
import { track2 } from 'd2/analytics'
import type {
  ContextValue,
  HideModalCallback,
  ModalCustomValue,
  ModalKey,
  Props,
  ShowModalCallback,
} from './types'

export const ModalContext = createContext<ContextValue>({
  // TODO: Remove 'as' type assertions because they are unsafe.
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  hideModal: noop as HideModalCallback,
  modalCustomValue: undefined,
  modalId: undefined,
  modalIndex: undefined,
  modalKey: null,
  // TODO: Remove 'as' type assertions because they are unsafe.
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  showModal: noop as ShowModalCallback,
})

export const buildModalId: (b?: ModalKey | null, a?: string | null) => string = (modalKey, modalIndex) => {
  const checkModalIndex = modalIndex ? `-${modalIndex}` : ''
  return `${modalKey ?? ''}${checkModalIndex}`
}

const ModalProvider = memo<Props>(({
  children,
}) => {
  const [modalKey, setModalKey] = useState<ModalKey | null | undefined>(null)
  const [modalCustomValue, setModalCustomValue] = useState<ModalCustomValue | null | undefined>(null)

  const showModal: ShowModalCallback = useCallback(
    (modalKey, customValue) => {
      // @ts-expect-error (auto-migrated from flow FixMe)[incompatible-call] - TODO: fix flow for modals, this is not really the modal key, it is either modalKey | modalId | string
      setModalKey(modalKey)
      setModalCustomValue(customValue)
      track2('show_modal', { modal_key: modalKey })
    },
    [setModalKey],
  )

  const hideModal: HideModalCallback = useCallback(
    () => {
      track2('hide_modal', { modal_key: modalKey })
      setModalKey(null)
    },
    [modalKey, setModalKey],
  )

  const value: ContextValue = useMemo(() => ({
    hideModal,
    modalCustomValue,
    modalId: modalKey,
    modalKey,
    showModal,
  }), [
    hideModal,
    modalCustomValue,
    modalKey,
    showModal,
  ])

  return (
    <ModalContext.Provider
      value={value}
    >
      { children }
    </ModalContext.Provider>
  )
})

export default ModalProvider
