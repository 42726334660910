import {
  ExtraRow,
  ExtraRowCell,
  RowBanner,
  RowCell,
  SharedComponents,
  SubRow,
  SubRowCell,
  Table,
  TablePagination,
} from './components'
import type { MakeTableReturn } from './types'

export function makeTable<TData, TSortEnums extends string | undefined, TSharedComponents extends {
  readonly [x: string]: React$ComponentType<any, any, any>
}, TSubData = EO> (): MakeTableReturn<TData, TSortEnums, TSharedComponents, TSubData> {
  return {
    ExtraRow,
    ExtraRowCell,
    RowBanner,
    RowCell,
    SharedComponents,
    SubRow,
    SubRowCell,
    Table,
    TablePagination,
  }
}
