import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

const styles = (theme: MergedThemes) => ({
  disabled: {
    backgroundColor: `${theme.palette.grey['300']} !important`,
  },
  root: {
    '& span': {
      position: 'relative',
    },
    fontFamily: theme.branding?.primaryFontFamily,
  },
} as const)

export default makeStyles({
  name: 'base',
})(styles)
