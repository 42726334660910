import { FONT_SIZE_DEFAULT, FONT_SIZE_LARGE } from 'd2/constants/fonts'
import {
  MAX,
  NONE,
  SPACING,
  SPACING_HALF,
  SPACING_QUARTER,
  SPACING_ZERO,
} from 'd2/constants'
import { isNil } from 'lodash-es'
import { percent, px } from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

type StylesProps = {
  maxHeight?: string | null,
  maxWidthHeader?: boolean | null,
  minWidth?: string | null,
  stickyScroll?: boolean | null,
  width?: number | null,
  maxWidth?: number | null
}

export const borderStyles = ({
  palette: { grey },
}: MergedThemes) => ({
  border: `${px(1)} solid ${grey['300']}`,
  borderRadius: px(SPACING_ZERO),
  borderWidth: px(SPACING_ZERO),
  boxShadow: 'none',
  minHeight: '2em',
  padding: px(SPACING_QUARTER),
})

// TODO: Move sizes to theme
const styles = (
  theme: MergedThemes,
  {
    maxHeight,
    maxWidth,
    maxWidthHeader,
    minWidth,
    stickyScroll,
    width,
  }: StylesProps,
) =>
  ({
    footerNoBorder: {
      borderBottom: 'none',
    },
    hidden: {
      display: 'none',
    },
    mobilePaginationActions: {
      marginLeft: SPACING_ZERO,
    },
    mobilePaginationToolbar: {
      padding: px(SPACING_ZERO),
    },
    noBorderBottomLast: {
      '&:last-child': {
        borderBottom: px(0),
      },
    },
    noBottomBorder: {
      borderBottom: px(0),
    },
    pagingButton: {
      '&:hover': {
        color: theme.colors.darkGray,
      },
    },
    rowCell: {
      '&:first-child': {
        background: stickyScroll ? theme.colors.white : undefined, // TODO: When rowClass is used, we may need to change this
        left: 0,
        position: stickyScroll ? 'sticky' : 'static',
      },
      ...theme.fontNormalStyles,
      fontSize: FONT_SIZE_LARGE,
      maxWidth: isNil(maxWidth) ? px(250) : px(maxWidth),
      width: stickyScroll ? MAX : width ? percent(width) : 'auto',
    },
    smallPadding: {
      padding: `${px(SPACING)} ${px(SPACING_ZERO)}`,
    },
    smallerPadding: {
      padding: `${px(SPACING_HALF)} ${px(SPACING_ZERO)}`,
    },
    subTable: {
      borderLeft: `${px(1)} solid ${theme.palette.grey['300']}`,
      borderRight: `${px(1)} solid ${theme.palette.grey['300']}`,
    },
    table: {
      minWidth: minWidth ?? undefined,
    },
    tableCell: {
      border: px(SPACING_ZERO),
      padding: `${px(SPACING_HALF)} 0`,
    },
    tableCellBody: {
      '&>div:first-of-type': {
        padding: `${px(SPACING_QUARTER)} 0`,
      },
      '&>div:first-of-type>div': {
        margin: `${px(SPACING_QUARTER)} 0`,
      },
      border: px(1),
      borderColor: theme.palette.grey['300'],
      borderStyle: 'solid',
    },
    tableCellChevron: {
      minWidth: px(40),
      padding: `0 0 0 ${px(SPACING_HALF)}`,
    },
    tableCellWithDivider: {
      padding: `${px(SPACING)} 0`,
    },
    tableHeadCell: {
      ...theme.fontBoldStyles,
      '&:first-child': {
        background: theme.colors.white,
        left: 0,
        position: stickyScroll ? 'sticky' : 'static',
        width: stickyScroll ? '44vw' : 'auto',
        zIndex: 1,
      },
      fontSize: px(FONT_SIZE_DEFAULT),
      maxWidth: maxWidthHeader ? NONE : MAX,
      paddingBottom: px(SPACING_HALF),
      paddingLeft: px(SPACING_ZERO),
      paddingRight: px(SPACING_ZERO),
      paddingTop: px(SPACING_HALF),
    },
    tableWrapper: {
      maxHeight: maxHeight ?? undefined,
      overflowX: 'auto',
    },
    withBorderTop: {
      borderTop: `${px(1)} solid ${theme.palette.grey['300']}`,
    },
  } as const)

export default makeStyles<StylesProps>({
  name: 'TableV2',
})(styles)
