import {
  memo,
  useCallback,
  useEffect,
} from 'react'
import { useQuery } from 'd2/partials/StorageTierModal/queries'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import StorageTierModal from '../StorageTierModal'
import TransitionDurations from 'd2/utils/transition_durations'
import useModal from 'd2/hooks/useModal'
import type { Props } from './types'
import type { TransitionDurationsType } from 'd2/types'

const overlayTransitionDurations: TransitionDurationsType = TransitionDurations('overlay')

const StorageTierModalWrapper = memo<Props>(() => {
  const { isOpen, showModal } = useModal({ modalKey: 'StorageTier' })
  const openModalHandler = useCallback(() => {
    showModal()
  }, [showModal])
  const [{ me }] = useQuery()

  useEffect(() => {
    if (!me) return
    const {
      exceeded_storage_limit: exceededStorageLimit,
      has_hidden_payment_plan: hasHidePaymentPlan,
      next_required_storage_tier: nextRequiredStorageTier,
    } = me
    if (hasHidePaymentPlan || !exceededStorageLimit || !nextRequiredStorageTier) return
    openModalHandler()
  }, [me, openModalHandler])

  if (!isOpen) return null

  return (
    <div>
      <CSSTransitionGroup
        transitionName='overlay'
        {...overlayTransitionDurations}
      >
        <StorageTierModal forceOpenModal />
      </CSSTransitionGroup>
    </div>
  )
})

StorageTierModalWrapper.displayName = 'StorageTierModalWrapper'

export default StorageTierModalWrapper
