import {
  DEFAULT_LINE_HEIGHT,
  FONT_SIZE_DEFAULT,
  FONT_SIZE_EXTRA_LARGE,
  FONT_SIZE_LARGE,
  FONT_SIZE_SMALL,
} from 'd2/constants/fonts'
import {
  SPACING,
  SPACING_DOUBLE,
  TOOLTIP_LINE_HEIGHT,
} from 'd2/constants'
import {
  allLinkStates,
  em,
  px,
} from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

const styles = (
  {
    fontBoldStyles,
    fontNormalStyles,
    ...theme
  }: MergedThemes,
) => ({
  bold: {
    ...fontBoldStyles,
    fontSize: px(FONT_SIZE_LARGE),
    letterSpacing: 0,
    lineHeight: em(DEFAULT_LINE_HEIGHT),
  },
  buttonMargins: {
    margin: `${SPACING} ${px(SPACING_DOUBLE)}`,
  },
  danger: {
    ...allLinkStates({
      color: theme.branding?.danger,
    }),
    cursor: 'pointer',
    fontStyle: 'italic',
  },
  default: {
    ...allLinkStates({
      color: theme.branding?.linkColor,
      cursor: 'pointer',
    }),
  },
  disabled: {
    color: theme.branding?.grayText,
    cursor: 'default',
  },
  font: {
    ...fontNormalStyles,
    fontSize: px(FONT_SIZE_LARGE),
    letterSpacing: 0,
    lineHeight: em(DEFAULT_LINE_HEIGHT),
  },
  italic: {
    fontStyle: 'italic',
  },
  large: {
    fontSize: px(FONT_SIZE_EXTRA_LARGE),
  },
  medium: {
    fontSize: px(FONT_SIZE_DEFAULT),
  },
  noFont: {
    ...fontNormalStyles,
  },
  secondary: {
    ...allLinkStates({
      color: theme.branding?.secondaryColor,
      cursor: 'pointer',
    }),
  },
  small: {
    fontSize: px(FONT_SIZE_SMALL),
  },
  tooltip: {
    ...allLinkStates({
      ...fontBoldStyles,
      color: theme.branding?.isWhitelabel ? theme.colors.white : theme.branding?.linkColor,
      lineHeight: px(TOOLTIP_LINE_HEIGHT),
      textDecoration: theme.branding?.isWhitelabel ? 'underline' : 'none',
    }),
  },
} as const)

export default makeStyles({
  name: 'HyperLink',
})(styles)
