export const scrollTo = ({
  xPosition,
  yPosition,
}: {
  xPosition: number,
  yPosition: number
}) => {
  if (typeof window === 'undefined') return

  try {
    window.scrollTo({
      behavior: 'smooth',
      left: xPosition,
      top: yPosition,
    })
  } catch {
    window.scrollTo(xPosition, yPosition)
  }
}

export const scrollToTop = (): void => {
  scrollTo({
    xPosition: 0,
    yPosition: 0,
  })
}
