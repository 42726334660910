export const MAIN_HEADER_GRADIENT_IMAGE = 6
export const MAIN_HEADER_GRADIENT_OVERLAY = 7
export const MAIN_HEADER_HEADER = 5
export const MAIN_HEADER_IMAGE_BACKGROUND = 3
export const MAIN_HEADER_NAVIGATION_TABS = 8
export const MAIN_HEADER_TITLE_BAR = 8
export const MAIN_HEADER_COLUMN = 9

export const PROGRESS_BAR = 2
export const PROGRESS_CONTAINER = 0
export const PROGRESS_TEXT = 3

export const VIDEO_PLAYER_CONTROLS = 10

export const SCREEN_404_BACKGROUND = -1

export const SCREEN_DISTRIBUTION_BACKGROUND = 0
export const SCREEN_DISTRIBUTION_BACKGROUND_IMAGE = 1
export const SCREEN_DISTRIBUTION_CONTAINER_CONTENT = 10
export const SCREEN_DISTRIBUTION_GRADIENT_OVERLAY = 2

export const OVERLAY_Z_INDEX = 999_999
