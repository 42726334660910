import { useEffect } from 'react'
import raygun from 'd2/utils/raygun'
import useTheme from 'd2/hooks/useTheme'

const DEFAULT_FONT_OPTIONS: {
  style: string,
  weight: string
} = {
  style: 'normal', // TODO: Maybe add this to the theme
  weight: '400', // TODO: Maybe add this to the theme
}

export function useFontLoader () {
  const { branding } = useTheme()

  const loadFont = (webFont: FontFace | null) => {
    if (webFont) {
      webFont.load().then((loadedFont) => {
        try {
          document.fonts.add(loadedFont)
        } catch (error) {
          // TODO: Why are we catching these errors? Isn't it too defensive?
          // eslint-disable-next-line no-console
          console.error('Error in d2/src/hooks/useFontLoader/index.js', error)
        }
      })
        .catch((error) => {
          raygun('send', {
            customData: {
              error,
            },
            error: 'FontLoader Error',
            tags: ['D2', 'Fonts'],
          })
        })
    }
  }

  useEffect(() => {
    if (typeof window === 'undefined') return
    if (!branding) return
    const {
      primaryFontName,
      primaryFontUrl,
      secondaryFontName,
      secondaryFontUrl,
    } = branding

    if (primaryFontUrl) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const primaryFont = window.FontFace
        ? new window.FontFace(
          primaryFontName,
          `url(${primaryFontUrl})`,
          DEFAULT_FONT_OPTIONS,
        )
        : null

      loadFont(primaryFont)
    }
    if (secondaryFontUrl) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const secondaryFont = window.FontFace
        ? new window.FontFace(
          secondaryFontName,
          `url(${secondaryFontUrl})`,
          DEFAULT_FONT_OPTIONS,
        )
        : null

      loadFont(secondaryFont)
    }
  }, [branding])
}
