import { filter } from 'lodash-es'
import type { GenericReducer } from '../types'

export type MobileNavBarMenus = 'account' | 'insights' | 'releases'

export type State = {
  isDrawerOpen: boolean,
  openMenus: MobileNavBarMenus[]
}

const init: () => State = () => ({
  isDrawerOpen: false,
  openMenus: [],
})

export type Reducer = GenericReducer<State>
const reducer: Reducer = (state = init(), action) => {
  switch (action.type) {
  case 'OPEN_MOBILE_NAV_BAR_DRAWER': {
    return {
      ...state,
      isDrawerOpen: true,
    }
  }
  case 'CLOSE_MOBILE_NAV_BAR_DRAWER': {
    return init()
  }
  case 'OPEN_MOBILE_NAV_BAR_MENU': {
    return {
      ...state,
      openMenus: [action.payload, ...state.openMenus],
    }
  }
  case 'CLOSE_MOBILE_NAV_BAR_MENU': {
    const {
      payload,
    }: {
      payload: MobileNavBarMenus
    } = action
    return {
      ...state,
      openMenus: filter(state.openMenus, (value) => value !== payload),
    }
  }
  default: {
    return state
  }
  }
}

export default reducer
