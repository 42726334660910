export const NORMAL = 'normal'

// if you are building a component, use theme properties instead of importing this directly
// for an example, search for '...fontNormalStyles'
export const FONT_AWESOME_5_PRO = '"Font Awesome 6 Pro"'

export const FONT_SIZE_EXTRA_SMALL = 10
export const FONT_SIZE_SMALL = 12
export const FONT_SIZE_MEDIUM = 13
export const FONT_SIZE_DEFAULT = 14
export const FONT_SIZE_LARGE = 16
export const FONT_SIZE_EXTRA_LARGE = 18
export const FONT_SIZE_HUGE = 20
export const FONT_SIZE_HUGE_PLUS = 22
export const H2_FONT_SIZE = 36
export const FONT_SIZE_CARD_TITLE_MEDIUM = 24
export const FONT_SIZE_CARD_TITLE_LARGE = 32

export const FONT_SIZE_MEDIUM_ICON = 22
export const FONT_SIZE_LARGE_ICON = 28
export const FONT_SIZE_EXTRA_LARGE_ICON = 36
export const FONT_SIZE_XXL_ICON = FONT_SIZE_EXTRA_LARGE_ICON * 1.5

// note: use theme props whenever possible. you should almost never use these
export const FONT_WEIGHT_LIGHT = 300
export const FONT_WEIGHT_NORMAL: 'normal' = NORMAL
export const FONT_WEIGHT_BOLD = 600
export const FONT_WEIGHT_EXTRA_BOLD = 700

export const DEFAULT_LINE_HEIGHT = 1.5
export const HEADER_TITLE_LETTER_SPACING = 3
