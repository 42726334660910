import {
  SPACING_EIGHTH,
  SPACING_QUARTER,
  SPACING_ZERO,
} from 'd2/constants'
import { px, rem } from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

const styles = (theme: MergedThemes) => ({
  container: {
    margin: `${SPACING_ZERO} auto`,
    textAlign: 'center',
  },
  input: {
    '@media only screen and (max-width: 425px)': {
      fontSize: rem(1),
      height: rem(3),
      padding: px(SPACING_QUARTER),
      width: rem(2.5),
    },
    '@media only screen and (max-width: 425px) and (max-width: 375px)': {
      fontSize: rem(0.8),
      height: rem(3),
      padding: px(SPACING_EIGHTH),
      width: rem(2.5),
    },
    border: `${px(1)} solid ${theme.colors.gray}`,
    borderRadius: px(4),
    fontSize: rem(2),
    height: rem(4),
    margin: `${SPACING_ZERO} ${rem(0.5)}`,
    textAlign: 'center',
    width: rem(3),
  },
} as const)

export default makeStyles({
  name: 'OTPInput',
})(styles)
