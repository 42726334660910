import { pick } from 'lodash-es'
import type { Action } from 'd2/actions'
import type { GenericReducer } from '../types'

export type ActionPressAction = Action | Array<Action>

export type AllowedType = 'error' | 'info' | 'success'

type PayloadWithActionButton = {
  actionPressAction: ActionPressAction,
  actionTargetBlankLocation?: undefined,
  actionText: string,
  d1RedirectLocation?: string,
  d2RedirectLocation?: string,
  hasDismiss?: boolean,
  persist?: boolean,
  heading?: string,
  message: React$Node,
  type: AllowedType,
}

type PayloadWithTargetBlankLocation = {
  actionPressAction?: undefined,
  actionTargetBlankLocation: string,
  actionText: string,
  d1RedirectLocation?: string,
  d2RedirectLocation?: string,
  hasDismiss?: boolean,
  persist?: boolean,
  heading?: string,
  message: React$Node,
  type: AllowedType,
}

type PayloadWithoutActionButton = {
  actionPressAction?: undefined,
  actionTargetBlankLocation?: undefined,
  actionText?: undefined,
  d1RedirectLocation?: string,
  d2RedirectLocation?: string,
  hasDismiss?: boolean,
  persist?: boolean,
  heading?: string,
  message: React$Node,
  type: AllowedType,
}

export type Payload = PayloadWithActionButton | PayloadWithoutActionButton | PayloadWithTargetBlankLocation

export type State = {
  actionPressAction: ActionPressAction | null | undefined,
  actionTargetBlankLocation: string | null | undefined,
  actionText: string | null | undefined,
  d1RedirectLocation?: string,
  d2RedirectLocation?: string,
  hasDismiss?: boolean,
  persist?: boolean,
  heading?: string,
  message: React$Node,
  snackbarActive: boolean,
  type: AllowedType | null | undefined
}

const init: () => State = () => ({
  actionPressAction: null,
  actionTargetBlankLocation: null,
  actionText: null,
  d1RedirectLocation: '',
  d2RedirectLocation: '',
  hasDismiss: false,
  heading: '',
  message: '',
  persist: false,
  snackbarActive: false,
  type: null,
})

export type Reducer = GenericReducer<State>
const reducer: Reducer = (state = init(), action) => {
  switch (action.type) {
  case 'SHOW_SNACKBAR': {
    return {
      ...init(),
      ...pick(action.payload, [
        'actionPressAction',
        'actionTargetBlankLocation',
        'actionText',
        'd1RedirectLocation',
        'd2RedirectLocation',
        'hasDismiss',
        'persist',
        'heading',
        'message',
        'type',
      ]),
      snackbarActive: true,
    }
  }
  case 'HIDE_SNACKBAR': {
    return {
      ...state,
      snackbarActive: false,
    }
  }
  default: {
    return state
  }
  }
}

export default reducer
