import { em, px } from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

// TODO: Move sizes to theme
const styles = (theme: MergedThemes) => ({
  bodyExtraSmall: {
    fontFamily: theme.branding?.primaryFontFamily,
    fontSize: px(12),
    lineHeight: em(1.5),
  },
} as const)

export default makeStyles({
  name: 'BodyExtraSmall',
})(styles)
