import tinycolor from 'tinycolor2'

const AMAZON_MUSIC_CYAN = '#12AFC0'
const AMAZON_ORANGE = '#FF9900'
const APPLE_MUSIC_BLUE = '#41BEED'
const BLACK = '#000000'
const BUDDHA_GOLD = '#E49C02'
const CERISE = '#E04797'
const DARK_BLUE = '#3A6FB6'
const DARK_GRAY = '#4A4A4A'
const DARK_GREEN = '#24AC5E'
const DARK_RED = '#B90504'
const DARKER_GRAY = '#D1D1D1'
const DEEZER_PURPLE = '#A238FF'
const ELECTRIC_VIOLET = '#E074FF'
const FACEBOOK_BLUE_HOVER = '#263961'
const FACEBOOK_BLUE = '#1877F2'
const FAINT_GRAY = '#EFEFEF'
const GOVERNOR_BAY = '#A2BEFF'
const GRAY = '#AAAAAA'
const GREEN = '#47D87E'
const INSTAGRAM_BLACK = '#000000'
const INSTAGRAM_BLACK_HOVER = '#1F1F1F'
const INSTAGRAM_PINK = '#E04697'
const INSTAGRAM_SALMON = '#E1306C'
const JAPANESE_LAUREL = '#004E03'
const LIGHT_BLUE = '#41BEED'
const LIGHT_GRAY = '#F2F2F2'
const MANZ = '#F8FF2B'
const NEON_VIOLET = '#FF2FE1'
const ORANGE = '#F5A623'
const PERSIAN_RED = '#A81E13'
const PINK = '#e04697'
const PURPLE = '#9000C4'
const RADICAL_RED = '#FD867F'
const RED = '#EB3736'
const SELECTIVE_YELLOW = '#FFBD00'
const SMART_LINK_BUTTON_BLACK = '#1A1A1A'
const SOUNDCLOUD_ORANGE = '#FF7700'
const SPOTIFY_GREEN = '#1DB954'
const SPOTIFY_GREEN_HOVER = '#14A647'
const SUNGLOW = '#D6DE00'
const TOREA_BAY = '#002AA0'
const TRANSPARENT = 'transparent'
const TWITTER_BLUE = '#1DA1F2'
const TWITTER_BLUE_HOVER = '#0F84DF'
const USER_AVATAR_BLUE = '#0078A7'
const USER_AVATAR_BROWN = '#8F5414'
const USER_AVATAR_DARK_BLUE = '#003B75'
const USER_AVATAR_DARK_GRAY = '#1D1D1D'
const USER_AVATAR_DARK_TEAL = '#249FAD'
const USER_AVATAR_GOLD = '#D89E19'
const USER_AVATAR_GRAY = '#5B5B5B'
const USER_AVATAR_MAGENTA = '#A11461'
const VEVO_BLACK = '#000000'
const WEB_ORANGE = '#FFD799'
const WHITE = '#FFFFFF'
const YELLOW = '#fbee48'
const YOUTUBE_BLACK = '#282828'
const YOUTUBE_RED_HOVER = '#b30000'
const YOUTUBE_RED = '#ff0000'
const YOUTUBE_GRAY = '#E2E2E2'

export const colors = {
  amazonMusicCyan: AMAZON_MUSIC_CYAN,
  amazonOrange: AMAZON_ORANGE,
  appleMusicBlack: BLACK,
  appleMusicBlackHover: BLACK,
  appleMusicBlue: APPLE_MUSIC_BLUE,
  audiomackBlack: BLACK,
  audiomackBlackHover: BLACK,
  black: BLACK,
  buddhaGold: BUDDHA_GOLD,
  cerise: CERISE,
  darkBlue: DARK_BLUE,
  darkGray: DARK_GRAY,
  darkGreen: DARK_GREEN,
  darkRed: DARK_RED,
  darkerGray: DARKER_GRAY,
  deezerPurple: DEEZER_PURPLE,
  electricViolet: ELECTRIC_VIOLET,
  facebookBlue: FACEBOOK_BLUE,
  facebookBlueHover: FACEBOOK_BLUE_HOVER,
  faintGray: FAINT_GRAY,
  faintGrayHover: tinycolor(FAINT_GRAY)
    .darken(5)
    .toHexString(),
  governorBay: GOVERNOR_BAY,
  gray: GRAY,
  green: GREEN,
  instagramBlack: INSTAGRAM_BLACK,
  instagramBlackHover: INSTAGRAM_BLACK_HOVER,
  instagramPink: INSTAGRAM_PINK,
  instagramSalmon: INSTAGRAM_SALMON,
  japaneseLaurel: JAPANESE_LAUREL,
  lightBlue: LIGHT_BLUE,
  lightGray: LIGHT_GRAY,
  manz: MANZ,
  neonViolet: NEON_VIOLET,
  orange: ORANGE,
  persianRed: PERSIAN_RED,
  pink: PINK,
  purple: PURPLE,
  radicalRed: RADICAL_RED,
  red: RED,
  selectiveYellow: SELECTIVE_YELLOW,
  smartLinkButtonBlack: SMART_LINK_BUTTON_BLACK,
  smartLinkButtonBlackHover: tinycolor(SMART_LINK_BUTTON_BLACK)
    .darken(5)
    .toHexString(),
  soundcloudOrange: SOUNDCLOUD_ORANGE,
  spotifyGreen: SPOTIFY_GREEN,
  spotifyGreenHover: SPOTIFY_GREEN_HOVER,
  sunglow: SUNGLOW,
  toreaBay: TOREA_BAY,
  transparent: TRANSPARENT,
  twitterBlue: TWITTER_BLUE,
  twitterBlueHover: TWITTER_BLUE_HOVER,
  userAvatarBlue: USER_AVATAR_BLUE,
  userAvatarBrown: USER_AVATAR_BROWN,
  userAvatarDarkBlue: USER_AVATAR_DARK_BLUE,
  userAvatarDarkGray: USER_AVATAR_DARK_GRAY,
  userAvatarDarkTeal: USER_AVATAR_DARK_TEAL,
  userAvatarGold: USER_AVATAR_GOLD,
  userAvatarGray: USER_AVATAR_GRAY,
  userAvatarMagenta: USER_AVATAR_MAGENTA,
  vevoBlack: VEVO_BLACK,
  webOrange: WEB_ORANGE,
  white: WHITE,
  yellow: YELLOW,
  youtubeBlack: YOUTUBE_BLACK,
  youtubeGray: YOUTUBE_GRAY,
  youtubeGrayHover: tinycolor(YOUTUBE_GRAY)
    .darken(5)
    .toHexString(),
  youtubeRed: YOUTUBE_RED,
  youtubeRedHover: YOUTUBE_RED_HOVER,
}

export type Colors = typeof colors
export default colors
