import {
  SPACING,
  SPACING_DOUBLE,
  SPACING_HALF,
  SPACING_QUARTER,
} from 'd2/constants'
import { px } from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'

const styles = () => ({
  default: {
    height: px(SPACING),
  },
  double: {
    height: px(SPACING_DOUBLE),
  },
  half: {
    height: px(SPACING_HALF),
  },
  quarter: {
    height: px(SPACING_QUARTER),
  },
} as const)

export default makeStyles({
  name: 'Spacer',
})(styles)
