export default function useCheckboxNormalDefault () {
  return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <defs>
      <style>
        .a {
          fill: #fff;
          stroke: #dfdfdf;
        }
      </style>
    </defs>
    <rect class="a" x="0.5" y="0.5" width="19" height="19"/>
  </svg>`
}
