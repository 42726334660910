import type { IconButtonProps } from 'd2/components/ButtonV2/IconButton/types'
import type { Variant } from 'd2/components/ButtonV2/base/types'

export const PREMIUM_PARTNER_COLORS = [
  'appleMusicBlack',
  'facebookBlue',
  'instagramBlack',
  'spotifyGreen',
  'twitterBlue',
  'youtubeRed',
]
type PremiumPartnerColors = typeof PREMIUM_PARTNER_COLORS[number]

type SolidButtonOwnProps = {
  color?: PremiumPartnerColors | 'faintGray' | 'primary' | 'secondary' | 'tertiary'
  disableMobile?: boolean
}

type RestIconButtonProps = {
  variant?: Variant
}

export type SolidButtonProps = Omit<Omit<IconButtonProps, keyof RestIconButtonProps>, keyof SolidButtonOwnProps> & SolidButtonOwnProps
