import { forwardRef, memo } from 'react'
import { useIsMobile } from '../../Responsive'
import IconButton from '../IconButton'
import useStyles from './styles'
import type { OutlineButtonProps } from './types'

const OutlineButton = memo<OutlineButtonProps>(forwardRef(
  ({
    className,
    color,
    disableMobile,
    maxWidth,
    ...props
  }, ref) => {
    const { classes } = useStyles({
      color: color ?? 'primary',
      disableMobile,
    })
    const isMobile = useIsMobile()

    return (
      <IconButton
        {...props}
        className={`${classes.outline} ${classes.button} ${className ?? ''}`}
        maxWidth={maxWidth || (!disableMobile && isMobile)}
        ref={ref}
        variant='outlined'
      />
    )
  },
))

OutlineButton.displayName = 'OutlineButton'

export default OutlineButton
