import type { GenericReducer } from '../types'
import type { MutationError } from 'd2/types'

export type Namespace = 'accountDeleteRequest' | 'confirmPassword' | 'deleteData' | 'updateMetadata'

export type State = {
  accountDeleteRequest: Array<MutationError> | null | undefined,
  confirmPassword: Array<MutationError> | null | undefined,
  deleteData: Array<MutationError> | null | undefined,
  updateMetadata: Array<MutationError> | null | undefined
}

export const init: () => State = () => ({
  accountDeleteRequest: undefined,
  confirmPassword: undefined,
  deleteData: undefined,
  updateMetadata: undefined,
})

export type Reducer = GenericReducer<State>
const reducer: Reducer = (state = init(), action) => {
  switch (action.type) {
  case 'ADD_VALIDATION_ERRORS': {
    return {
      ...state,
      [action.payload.namespace]: action.payload.errors,
    }
  }
  case 'CLEAR_VALIDATION_ERRORS': {
    return {
      ...state,
      [action.payload.namespace]: undefined,
    }
  }
  default: {
    return state
  }
  }
}

export default reducer
