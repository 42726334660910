/* eslint-disable */
//  This file was automatically generated and should not be edited.

export default {
  "AccessPolicyAction": ["manage_super_access","manage_executive_access","manage_new_dev_access","manage_accounting_access","manage_client_success_access","manage_content_operations_access","manage_label_artist_relations_access","manage_label_services_access","manage_legal_access","manage_marketing_access","manage_people_ops_access","manage_rights_management_access","manage_royalties_access","view_royalties","manage_user_restrictions","create","read","update","delete","set_admin","update_royalty_split","update_organization_royalty_split","edit_has_label_revenue","observe","observe_mutate","access_dev_features","ingest_statements","ingest_segments","manage_organization_restrictions","move","lock_in_periods","predict_revenue","hubspot","review","wipe_revenue","access_bypass_payout_requirements_features","access_vip_feature","transfer_content","reset_mfa","gdpr_purge","view_tipalti_info","show_in_csm_dropdown","update_csm","deactivate_user","create_s3_bucket","update_status","set_withdrawal_limit","set_mutually_terminated","access_cops_features","partner_xml_override","deploy","reset","reupload_artwork_after_distribution","update_poc","show_in_poc_dropdown","access_lar_features","update_label_rep","show_in_label_rep_dropdown","access_rm_features","change_user_organization","log_termination_notice","access_negative_balance_feature","allow_recoupment_sharing","refund","resolve_split_requests","transfer_balances","manage_content","manage_staff","read_analytics","read_revenue","manage_user_role","be_observed","access_payment_methods","access_payout_settings","own","upload_from_nav_bar"] as const,
  "AccessPolicyModel": ["user","organization","artist","release","broadcast","release_extra","media","album","message","group","recoupment","asset","asset_digest","period","client","signed_agreement","balance_adjustment","claim","vydia","deal","transaction","asset_split_request","composer","cash_advance_recoupment","payee_asset_split","payee_invite"] as const,
  "AdditionalServiceCategory": ["DESIGN","SECURITY","CONTENT"] as const,
  "AdditionalServiceType": ["SOCIAL","PREMIUM_PARTNER","AUDIO"] as const,
  "AdditionalServicesVariant": ["Tabs","Pages"] as const,
  "AgreementByTypeAgreementType": ["merch_agreement","fan_insights_privacy_agreement"] as const,
  "AgreementType": ["UCLA","RCA","CLA","merch_agreement"] as const,
  "AlbumPartnerDataLinkActionType": ["DOWNLOAD","PLAY"] as const,
  "AlbumType": ["ALBUM","COMPILATION","EP","SINGLE"] as const,
  "AlbumsSortBy": ["created_at","id","name","revenue"] as const,
  "AnalyticsCountryCode": ["US","CA","AF","AX","AL","DZ","AS","AD","AO","AI","AQ","AG","AR","AM","AW","AU","AT","AZ","BS","BH","BD","BB","BY","BE","BZ","BJ","BM","BT","BO","BA","BW","BV","BR","IO","BN","BG","BF","BI","KH","CM","CV","KY","CF","TD","CL","CN","CX","CC","CO","KM","CG","CD","CK","CR","CI","HR","CU","CY","CZ","DK","DJ","DM","DO","EC","EG","SV","GQ","ER","EE","ET","FK","FO","FJ","FI","FR","GF","PF","TF","GA","GM","GE","DE","GH","GI","GR","GL","GD","GP","GU","GT","GG","GN","GW","GY","HT","HM","VA","HN","HK","HU","IS","IN","ID","IR","IQ","IE","IM","IL","IT","JM","JP","JE","JO","KZ","KE","KI","KP","KR","KW","KG","LA","LV","LB","LS","LR","LY","LI","LT","LU","MO","MK","MG","MW","MY","MV","ML","MT","MH","MQ","MR","MU","YT","MX","FM","MD","MC","MN","ME","MS","MA","MZ","MM","NA","NR","NP","NL","NC","NZ","NI","NE","NG","NU","NF","MP","NO","OM","PK","PW","PS","PA","PG","PY","PE","PH","PN","PL","PT","PR","QA","RE","RO","RU","RW","BL","SH","KN","LC","MF","PM","VC","WS","SM","ST","SA","SN","RS","SC","SL","SG","SK","SI","SB","SO","ZA","GS","ES","LK","SD","SR","SJ","SZ","SE","CH","SY","TW","TJ","TZ","TH","TL","TG","TK","TO","TT","TN","TR","TM","TC","TV","UG","UA","AE","GB","UM","UY","UZ","VU","VE","VN","VG","VI","WF","EH","YE","ZM","ZW","AN","BQ","CW","SS","SX","UK","XK","ZK","ZZ"] as const,
  "ArtistNetworkAnalyticsChart": ["FACEBOOK_LIKES","INSTAGRAM_FOLLOWERS","SOUNDCLOUD_FOLLOWERS","SPOTIFY_FOLLOWERS","SPOTIFY_LISTENERS","TWITTER_FOLLOWERS","YOUTUBE_SUBSCRIBERS"] as const,
  "ArtistSearchBy": ["artist_name","user_name","user_email"] as const,
  "ArtistsCursorSortBy": ["name","fans_count"] as const,
  "AssetType": ["music_video","sound_recording","composition","web"] as const,
  "AudioAnalyticsByCountrySortBy": ["country_code","total_streams","total_listeners","spotify_streams","apple_music_listeners","apple_music_streams"] as const,
  "AudioAnalyticsNetworks": ["spotify","apple_music","amazon_music"] as const,
  "AudioAnalyticsSortBy": ["total_streams","total_skips","total_saves","total_downloads","spotify_streams","apple_music_downloads","apple_music_streams","amazon_music_streams","spotify_skips","spotify_saves","date"] as const,
  "BrandLogoVariant": ["DEFAULT","NO_TEXT","WHITE_TEXT","SQUARE","SIGN_IN"] as const,
  "BrandSignInTemplate": ["DEFAULT","HORIZONTAL_SPLIT","CENTERED"] as const,
  "BroadcastStatus": ["pending","distributed","action_required","downgraded","complete","accepted","denied","failed","retired","unauthorized","rejected_spam","error","delivered","not_available"] as const,
  "BroadcastType": ["social","premium_partner","network"] as const,
  "BroadcastsSortBy": ["date","network"] as const,
  "CashAdvanceRecoupmentsCursorSortBy": ["id","created_at","recouped_at","user","total_amount","amount_recouped"] as const,
  "CollaboratingArtistContentTypeFilter": ["audio","video","album"] as const,
  "CollaboratingArtistRoleTypeFilter": ["additional_artist","credit","writer","engineer_or_producer","performer"] as const,
  "CollaboratingArtistType": ["additional_artist","credit","writer","engineer_or_producer","performer"] as const,
  "CountryCode": ["US","CA","AF","AX","AL","DZ","AS","AD","AO","AI","AQ","AG","AR","AM","AW","AU","AT","AZ","BS","BH","BD","BB","BY","BE","BZ","BJ","BM","BT","BO","BA","BW","BV","BR","IO","BN","BG","BF","BI","KH","CM","CV","KY","CF","TD","CL","CN","CX","CC","CO","KM","CG","CD","CK","CR","CI","HR","CU","CY","CZ","DK","DJ","DM","DO","EC","EG","SV","GQ","ER","EE","ET","FK","FO","FJ","FI","FR","GF","PF","TF","GA","GM","GE","DE","GH","GI","GR","GL","GD","GP","GU","GT","GG","GN","GW","GY","HT","HM","VA","HN","HK","HU","IS","IN","ID","IR","IQ","IE","IM","IL","IT","JM","JP","JE","JO","KZ","KE","KI","KP","KR","KW","KG","LA","LV","LB","LS","LR","LY","LI","LT","LU","MO","MK","MG","MW","MY","MV","ML","MT","MH","MQ","MR","MU","YT","MX","FM","MD","MC","MN","ME","MS","MA","MZ","MM","NA","NR","NP","NL","NC","NZ","NI","NE","NG","NU","NF","MP","NO","OM","PK","PW","PS","PA","PG","PY","PE","PH","PN","PL","PT","PR","QA","RE","RO","RU","RW","BL","SH","KN","LC","MF","PM","VC","WS","SM","ST","SA","SN","RS","SC","SL","SG","SK","SI","SB","SO","ZA","GS","ES","LK","SD","SR","SJ","SZ","SE","CH","SY","TW","TJ","TZ","TH","TL","TG","TK","TO","TT","TN","TR","TM","TC","TV","UG","UA","AE","GB","UM","UY","UZ","VU","VE","VN","VG","VI","WF","EH","YE","ZM","ZW","ZZ","ZK"] as const,
  "DealReviewStatus": ["PENDING","APPROVED","DENIED","ACTION_REQUIRED"] as const,
  "DesignVariantSource": ["custom","ai","imagemagick"] as const,
  "DestinationStatuses": ["error","delivered","pending","not_available"] as const,
  "EarningsStatementTransformerSources": ["AppleMusicStreaming","AppleMusicBeats","AppleMusicDownloads","Generic","CidSrav","CidSravAdjustments","YoutubeRedSrav","YoutubeSravPublishing","YoutubeRedPublishing","YoutubePublishingAdjustment","YoutubeEntertainmentAdRevenue","YoutubeEntertainmentSubscriptions","SpotifyDirectUs","SpotifyDirectInternational","SpotifyDirectUsEq","SpotifyDirectInternationalEq","YoutubeAdsBackpayAdjustments","YoutubeAudioTierAdjustments","YoutubeSubscriptionAdjustments","YoutubePublishingAdsAdjustments","YoutubePublishingAdsBackpayAdjustments","YoutubePublishingSubscriptionAdjustments","YoutubePublishingSubscriptionNonMusicAdjustments","YoutubeEntertainmentAdsAdjustments"] as const,
  "EventNotificationSubscriptionEvent": ["action_required","deployed","smartlink_is_live"] as const,
  "EventNotificationSubscriptionServiceType": ["sms"] as const,
  "ExperimentGoal": ["ApplyForWhitelabel","PurchaseYoutubeOac","PurchaseAdditionalServices"] as const,
  "ExperimentName": ["WhitelabelBannerAd","YoutubeOacPricing","AdditionalServices","TestExample"] as const,
  "ExplicitType": ["none","explicit","clean"] as const,
  "ExtraLinkType": ["nft","merch_store","tour_links","social_links","other"] as const,
  "FansSortBy": ["name","email","created_at","territory","source"] as const,
  "Features": ["add_record_labels_for_apple_music_audio","additional_services_v2","admin_label_earnings","allow_duplicate_isrcs","allow_negative_balance","beatsource_delivery","beatport_delivery","content_manager_user_role","d2_album_stat_tabs","d2_alpha","d2_creators","d2_dashboard","d2_dev","d2_revenue_albums","d2_revenue_flash_message","d2_smart_links","d2_spotify_for_artists","d2_vevo_analytics","d2_video_uploader","dev_only","distribute_service_access","eligible_for_pub_admin","event_notifications","facebook_deployment","hide_web_content","label_client_advances","lickd_delivery","lime_blue_delivery","traxsource_delivery","marketing","meta_pixel","mfa","mfa_required","organization_release_creation_notification","out_of_date_password","overview_v2","paid_services","priority_scheduling","require_tax_submission","royalty_center_payor","sales_trends","shopify_store","skip_auth_for_withdrawal","skip_cla","skip_minimum_tipalti_revenue","skip_socials","smartlinks_custom_subdomain","smart_links_fan_insights","smart_merch","smartlinks_tab","soundexchange","storage_tier","test_deployers","ucla","unlimited_storage","vip","whitelabel_invite_payee_outside_organization"] as const,
  "Generator": ["a2v","facebook","youtube","royalty","instagram","instagram_business","vydia"] as const,
  "Genre": ["AFRICANDANCEHALL","AFRICANREGGAE","AFRICAN","AFRIKAANS","AFROBEAT","AFROHOUSE","AFROPOP","AFROSOUL","AFROFOLK","AFROFUSION","AFROBEATS","ALTE","ALTERNATIVE","AMAPIANO","AMBIENT","AMERICANA","BACHATA","BASSHOUSE","BENGA","BLUEGRASS","BLUES","BOLLYWOOD","BONGOFLAVA","BREAKBEAT","CANTOPOP","CHILDRENSMUSIC","CHRISTIANGOSPEL","CLASSICAL","COMEDYHUMOR","CONTEMPORARYJAZZ","COOLJAZZ","COUNTRY","COUPDCAL","CROSSOVERJAZZ","CUMBIA","DANCETRAP","DANCE","DEEPHOUSE","DEEPTECH","DEMBOW","DISCO","DOWNTEMPO","DRUMBASS","DUBSTEP","ELECTRO","ELECTRONICDANCE","ELECTRONICA","EXPERIMENTAL","FOLK","FUNK","FUNKYHOUSE","FUSION","GQOM","GRIME","HARDTECHNO","HARDCOREDANCE","HARDCORE","HIGHLIFE","HOLIDAY","HOUSE","INDIANPOP","INDIEPOP","INDIEROCK","INDIE","INSTRUMENTAL","JAZZ","KPOP","KIZOMBA","KUDURO","KWAITO","LATINALTERNATIVE","LATINJAZZ","LATINPOP","LATINO","MAINSTAGEEDM","MAINSTREAMJAZZ","MANDOPOP","MASKANDI","MBALAX","MELODICHOUSE","MERENGUE","METAL","MODERNDANCEHALL","NATURESOUNDS","NDOMBOLO","NEWAGE","POP","PROGRESSIVEHOUSE","PSYTRANCE","PUNK","RBSOUL","RAPHIPHOP","REGGAE","REGGAETON","REGIONALMEXICANO","ROCK","SALSA","SHANGAANELECTRO","SINGERSONGWRITER","SKA","SLEEPSOUNDS","SOUKOUS","SOUNDTRACK","SPOKENWORD","TAARAB","TAIPOP","TECHHOUSE","TECHNO","TRANCE","TROPICAL","UKGARAGEDANCE","VOCALJAZZ","WHITENOISE","WORLD","ZOUGLOU"] as const,
  "GroupedFansGroupBy": ["day","month","territory"] as const,
  "I18nLocale": ["EN","ES"] as const,
  "InvalidMediaReason": ["METADATA_MISSING","COMPOSERS_MISSING","RIGHTS_NOT_SET","POLICIES_NOT_SET"] as const,
  "MediaLanguageCode": ["AF","AR","HY","AS","AZ","BNT","BE","BN","BS","BG","YUEHANT","CA","CE","ZH","HR","CS","DA","NL","EN","ET","FI","FR","KA","DE","EL","GU","HT","HAK","HA","HAW","HE","HI","HU","IS","IG","ID","ZXX","GA","IT","JA","KN","KK","KO","KM","KU","LO","LA","LV","LT","LUG","MS","ML","CMN","MI","MR","MN","NPI","NO","NY","OR","PS","FA","PL","PT","PA","RO","RU","SM","SA","SI","SR","SK","SL","SO","ES","ES419","SW","SV","TL","TY","TA","TE","TH","TO","TR","TW","UK","UR","VI","WO","YI","YO","ZU"] as const,
  "MediaPolicyStatus": ["deployed","pending","failed","downgraded"] as const,
  "MediaPolicyType": ["not_set","permit","monetize","block","none"] as const,
  "MediaSharedWithMe": ["active","archived"] as const,
  "MediaType": ["video","audio","comp"] as const,
  "MediasV2SortBy": ["id","name","date","revenue","file_size","artist_name"] as const,
  "NetworkAnalyticsDateResolution": ["MONTH","DAY"] as const,
  "NetworkAnalyticsNetworkType": ["YOUTUBE_VIDEOS","INSTAGRAM_VIDEOS","INSTAGRAM_STORIES","VEVO_VIDEOS","FACEBOOK_VIDEOS","SPOTIFY_TRACKS","APPLE_MUSIC_TRACKS"] as const,
  "NetworkExtraType": ["CUSTOM_VEVO_CHANNEL","BANNER_DESIGN","UPSELL","EXPEDITED_DELIVERY","QC_CHECK","NO_FOLLOW_UP","CONTENT_ID","WHITELABEL","APPLE_MUSIC_VIDEO_ENCODING","PRIORITY_DEPLOYMENT","YOUTUBE_OAC_REQUEST","COPYRIGHT_REGISTRATION","CUSTOM_TIKTOK_CLIP","LYRIC_SYNC","DOLBY_ATMOS_AUDIO_DELIVERY"] as const,
  "NetworkPlaylistSource": ["SPOTIFY","APPLEMUSIC"] as const,
  "NetworkPlaylistStatus": ["CURRENT","PAST"] as const,
  "NetworkType": ["MONETIZE","DISTRIBUTE","AUDIO","VIDEO","PREMIUM_PARTNER","SOCIAL","EARLY_ACCESS"] as const,
  "NoticeType": ["success","error","warning"] as const,
  "OtpMethodType": ["sms","authenticator"] as const,
  "OverviewTable": ["tracks","albums","videos","networks","countries","monthly"] as const,
  "OwnershipPoliciesSection": ["web","music_video","sound_recording","composition","policies"] as const,
  "PayeeAssetSplitStatus": ["active","accepted","inactive","expired","pending","rejected"] as const,
  "PayeeAssetType": ["music_video","sound_recording"] as const,
  "PayeeInvitesSortBy": ["created_at","email","first_name","last_name"] as const,
  "PlaylistAnalyticsSortBy": ["name","track_name","followers","track_streams","days_in_list","position","peak_position","removed_at"] as const,
  "PrettyTimeFormat": ["long"] as const,
  "PublishableSocial": ["FACEBOOK","INSTAGRAM","TWITTER","VEVO","YOUTUBE"] as const,
  "RankedMediasCursorSortBy": ["todays_rank","yesterdays_rank","todays_streams"] as const,
  "ReconnectableSocial": ["FACEBOOK","YOUTUBE","INSTAGRAM"] as const,
  "RecoupmentStatus": ["ACTIVE","COMPLETED"] as const,
  "ReleaseCalendarStatus": ["accepted","action_required","complete","denied","distributed","downgraded","failed","pending","rejected_spam","retired","submitted","unauthorized","processing","error","delivered","deployed","scheduled","ready"] as const,
  "ReleaseCalendarStatusFilterOption": ["DELIVERED","PENDING","ACTION_REQUIRED","ERROR","DENIED","DOWNGRADED"] as const,
  "RemainingPayeePercentageReason": ["FULLY_ALLOCATED","NO_OWNERSHIP","NO_ASSET"] as const,
  "RightType": ["MUSIC_VIDEO","SOUND_RECORDING","COMPOSITION","WEB"] as const,
  "RightsAssetType": ["specific","except","all"] as const,
  "RoyaltyCenterRole": ["payee","payor"] as const,
  "RoyaltyRevenuesSortBy": ["date","streams","album_sale_revenue","track_sale_revenue","album_units_sold","track_units_sold","net_revenue","gross_revenue","user_revenue","network_name","country_name","album_name","song_name"] as const,
  "SalesTrendGroupBy": ["date","country","network"] as const,
  "SalesTrendsSortBy": ["date","streams","album_sales","gross_revenue","net_revenue","track_sales","network_name","country_code"] as const,
  "SearchByOperator": ["include","exact"] as const,
  "SignedAgreementType": ["UPGRADE_ARTISTS","UPGRADE_ARTIST","RELEASE","UNIVERSAL","RCA","AFFIDAVIT","ADDRESS_CHANGE"] as const,
  "SignedAgreementsSortBy": ["created_at","id"] as const,
  "SleepMode": ["RUBY","PG"] as const,
  "SmartLinksAnalyticsSortBy": ["created_at","presaves","clickthroughs","visits"] as const,
  "SmartLinksAnalyticsTable": ["network","country","presave"] as const,
  "SmartlinksGoogleAnalyticsSortBy": ["country","network","streams_cumulative","downloads_cumulative","presaves_cumulative","unique_clickthroughs_cumulative","percentage_total_clickthroughs","clickthroughs_cumulative"] as const,
  "SmartlinksGoogleAnalyticsTable": ["network","country"] as const,
  "SortOrder": ["asc","desc"] as const,
  "SoundExchangePolicyType": ["not_set","from_inception","from_specific_date","no_claim"] as const,
  "StatementStatus": ["failed","pending","complete"] as const,
  "StatementsGroupBy": ["date","network"] as const,
  "SupportedVideo": ["official_music_video","lyric_video","artwork_video","visualizer","live_performance","behind_the_scenes","teaser_trailer"] as const,
  "TaxFormType": ["W_9","W_8","W_8BEN","W_8BEN_E","W_8EXP","W_8IMY","W_8ECI"] as const,
  "TaxIdType": ["EIN","SSN","FOREIGN_TIN","VAT_ID"] as const,
  "TestExampleVariant": ["Foo","Bar"] as const,
  "ThumbnailSize": ["SMALL","MEDIUM","LARGE"] as const,
  "UgcClaimsNetworkType": ["youtube"] as const,
  "UgcClaimsSortBy": ["estimated_claimed_views","date_claim_occurred"] as const,
  "UploadType": ["video","messageAttachment","a2v","a2vAudio","a2vArtwork","album_artwork","artist_image","extra_link_image","audio","a2vBackgroundImage","reuploadAudio","reuploadVideo","reuploadArtwork","d2Audio","csv","uploadAdm","uploadAtmos","period_statement","merch_design_custom_image"] as const,
  "UserInviteStatus": ["ACCEPTED","PENDING"] as const,
  "UserRole": ["OWNER","ADMIN","USER","CONTENT_MANAGER"] as const,
  "UserSearchBy": ["artist_name","user_name","user_email","user_role"] as const,
  "UsersCursorSortBy": ["id","created_at","last_name","invite_status","estimated_revenue","organization_royalty_split","role"] as const,
  "VideoCategory": ["music","web"] as const,
  "VideoSubcategory": ["film","autos","music","pets","sports","travel","gaming","people","comedy","entertainment","news","howto","education","science","nonprofits"] as const,
  "WebpackAssetType": ["CSS","JS"] as const,
  "WebpackBundle": ["ADMIN","APPLICATION","DASHBOARD_2","PUBLISHER","SIGNUP"] as const,
  "WhitelabelBannerAdVariant": ["Medium","Large"] as const,
  "YoutubeConflictsSortBy": ["created_at","song_name","user_name","artist_name","asset_type","conflicting_owner","conflicting_territories","daily_views","custom_id"] as const,
  "YoutubeOacPricingVariant": ["ThirtyNine","TwentyFour"] as const,
  "YoutubeUgcSearchBy": ["artist_name","user_name","claim_id","ugc_title","ugc_video_id","channel_name","claim_type","channel_id","song_name"] as const,
  "YoutubeUgcSortBy": ["artist_name","user_name","media_id","claim_id","ugc_title","ugc_video_id","channel_name","channel_id","claim_type","claim_date","estimated_claimed_views","song_name"] as const,
  "__DirectiveLocation": ["QUERY","MUTATION","SUBSCRIPTION","FIELD","FRAGMENT_DEFINITION","FRAGMENT_SPREAD","INLINE_FRAGMENT","SCHEMA","SCALAR","OBJECT","FIELD_DEFINITION","ARGUMENT_DEFINITION","INTERFACE","UNION","ENUM","ENUM_VALUE","INPUT_OBJECT","INPUT_FIELD_DEFINITION","VARIABLE_DEFINITION"] as const,
  "__TypeKind": ["SCALAR","OBJECT","INTERFACE","UNION","ENUM","INPUT_OBJECT","LIST","NON_NULL"] as const,
} as const
