import { QRCodeSVG } from 'qrcode.react'
import { memo } from 'react'
import { useQrCodeQuery } from './queries'
import useStyles from './styles'

const OtpQrCode = memo<EO>(() => {
  const [data, querySwitch] = useQrCodeQuery()
  const { classes } = useStyles()
  if (!data?.otpQrCode) return querySwitch

  return (
    <div className={classes.qrCode}>
      <QRCodeSVG
        height='auto'
        value={data.otpQrCode}
        width='100%'
      />
    </div>
  )
})

OtpQrCode.displayName = 'OtpQrCode'

export default OtpQrCode
