import {
  join,
  split,
  startCase,
  uniq,
} from 'lodash-es'

const wordReplacer = (word: string) => {
  switch (word) {
  case 'Ugc': {
    return 'YouTube Video'
  }
  case 'Video': {
    return 'YouTube Video'
  }
  case 'Channel': {
    return 'Youtube Channel'
  }
  case 'Artist': {
    return 'Artist'
  }
  case 'Id': {
    return 'ID'
  }
  case 'Song': {
    return 'Media'
  }
  default: {
    return word
  }
  }
}

export const columnNameReplacer = (columnName: string) => {
  const title = uniq(split(startCase(columnName), ' '))
  const wordReplacedTitle = title.map((word) => wordReplacer(word))
  return join(uniq(wordReplacedTitle), ' ')
}
