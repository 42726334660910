import { FONT_SIZE_LARGE } from 'd2/constants/fonts'
import {
  MAX,
  SPACING_HALF,
  SPACING_ZERO,
  TOOLTIP_LINE_HEIGHT,
} from 'd2/constants'
import { px } from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

type StylesProps = {
  isMobile: boolean,
  hasArrow: boolean | undefined,
}

const BACKGROUND_COLOR_NORMAL = 'rgba(0, 0, 0, 0.75)'
const styles = (
  {
    fontBoldStyles,
    ...theme
  }: MergedThemes,
  { hasArrow, isMobile }: StylesProps,
) => ({
  arrow: {
    '&::before': {
      backgroundColor: BACKGROUND_COLOR_NORMAL,
    },
  },
  popperBottom: {
    marginTop: 10,
  },
  popperHidden: {
    display: 'none',
  },
  popperLeft: {
    marginRight: 10,
  },
  popperRight: {
    marginLeft: 10,
  },
  popperTop: {
    marginBottom: 10,
  },
  tooltip: {
    ...fontBoldStyles,
    '&::after': {
      borderStyle: 'solid',
      content: '""',
      display: hasArrow ? 'none' : 'default',
      position: 'absolute',
    },
    borderRadius: 0,
    fontSize: px(FONT_SIZE_LARGE),
    fontStyle: 'italic',
    lineHeight: px(TOOLTIP_LINE_HEIGHT),
    padding: 0,
    ...isMobile
      ? {
        margin: `${SPACING_ZERO} ${px(SPACING_HALF)}`,
        maxWidth: MAX,
      }
      : {
        margin: 0,
        maxWidth: px(600),
      },
  },
  tooltipError: {
    backgroundColor: theme.branding?.danger,
  },
  tooltipInnerWrapper: {
    padding: `${px(SPACING_HALF)} ${px(SPACING_HALF)}`,
  },
  tooltipNormal: {
    backgroundColor: BACKGROUND_COLOR_NORMAL,
  },
  tooltipPlacementBottom: {
    '&::after': {
      borderWidth: '10px 5px',
      marginLeft: -5,
      marginTop: -6,
      top: 0,
      ...isMobile
        ? { left: '10%' }
        : { left: '50%' },
    },
  },
  tooltipPlacementBottomError: {
    '&::after': {
      borderColor: `transparent transparent ${theme.branding?.danger ?? 'transparent'}`,
    },
  },
  tooltipPlacementBottomNormal: {
    '&::after': {
      borderColor: `transparent transparent ${BACKGROUND_COLOR_NORMAL}`,
    },
  },
  tooltipPlacementLeft: {
    '&::after': {
      borderWidth: '5px 10px',
      left: '100%',
      marginTop: -5,
      top: '50%',
    },
  },
  tooltipPlacementLeftError: {
    '&::after': {
      borderColor: `transparent transparent transparent ${theme.branding?.danger ?? 'transparent'}`,
    },
  },
  tooltipPlacementLeftNormal: {
    '&::after': {
      borderColor: `transparent transparent transparent ${BACKGROUND_COLOR_NORMAL}`,
    },
  },
  tooltipPlacementRight: {
    '&::after': {
      borderWidth: '5px 10px',
      marginTop: -5,
      right: '100%',
      top: '50%',
    },
  },
  tooltipPlacementRightError: {
    '&::after': {
      borderColor: `transparent ${theme.branding?.danger ?? 'transparent'} transparent transparent`,
    },
  },
  tooltipPlacementRightNormal: {
    '&::after': {
      borderColor: `transparent ${BACKGROUND_COLOR_NORMAL} transparent transparent`,
    },
  },
  tooltipPlacementTop: {
    '&::after': {
      borderWidth: '10px 5px',
      marginLeft: -8,
      top: 'calc(100% - 14px)',
      ...isMobile
        ? { left: '10%' }
        : { left: '50%' },
    },
  },
  tooltipPlacementTopError: {
    '&::after': {
      borderColor: `${theme.branding?.danger ?? 'transparent'} transparent transparent`,
    },
  },
  tooltipPlacementTopNormal: {
    '&::after': {
      borderColor: `${BACKGROUND_COLOR_NORMAL} transparent transparent`,
    },
  },
} as const)
export default makeStyles<StylesProps>({
  name: 'Tooltip',
})(styles)
