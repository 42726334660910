import {
  DEFAULT_LINE_HEIGHT,
  FONT_SIZE_DEFAULT,
  FONT_SIZE_EXTRA_LARGE,
  FONT_SIZE_LARGE,
  FONT_SIZE_SMALL,
} from 'd2/constants/fonts'
import {
  MAX,
  SPACING,
  SPACING_DOUBLE,
  SPACING_HALF,
  SPACING_QUARTER,
  SPACING_ZERO,
} from 'd2/constants'
import {
  allLinkStates,
  em,
  px,
} from 'd2/utils/style'
import { screenXsMax } from 'd2/utils/breakpoints'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

const MIN_BUTTON_LARGE_WIDTH = 120
const BUTTON_BORDER = 2
const BUTTON_BORDER_SMALL = 1
const styles = (
  {
    fontNormalStyles,
    ...theme
  }: MergedThemes,
) => ({
  alignLeft: {
    justifyContent: 'flex-start',
  },
  alignRight: {
    justifyContent: 'flex-end',
  },
  blank: {
    ...allLinkStates({
      backgroundColor: theme.colors.transparent,
      borderColor: theme.colors.transparent,
      boxShadow: 'none',
      color: theme.palette.text.primary,
    }),
    backgroundColor: theme.colors.transparent,
    borderColor: theme.colors.transparent,
    margin: 0,
    minHeight: SPACING_ZERO,
    minWidth: SPACING_ZERO,
    textTransform: 'none',
  },
  block: {
    width: MAX,
  },
  button: {
    ...fontNormalStyles,
    '&:disabled': {
      backgroundColor: theme.branding?.disabled,
      color: theme.branding?.disabledText,
    },
    '&:visited': {
      color: theme.branding?.primaryButtonTextColor,
    },
    borderRadius: SPACING_ZERO,
    boxShadow: 'none',
    color: theme.branding?.primaryButtonTextColor,
    lineHeight: em(DEFAULT_LINE_HEIGHT),
    minHeight: SPACING_ZERO,
    minWidth: SPACING_ZERO,
    textAlign: 'center',
    textTransform: 'none',
    [`@media (max-width: ${screenXsMax}px)`]: {
      marginLeft: SPACING_ZERO,
      width: MAX,
    },
    whiteSpace: 'nowrap',
  },
  danger: {
    '&:active': {
      backgroundColor: theme.colors.transparent,
      color: theme.branding?.dangerActive,
    },
    '&:disabled': {
      backgroundColor: theme.colors.transparent,
      color: theme.branding?.disabledText,
    },
    '&:hover': {
      backgroundColor: theme.colors.transparent,
      color: theme.branding?.dangerHover,
    },
    '&:visited': {
      backgroundColor: theme.colors.transparent,
      color: theme.branding?.danger,
    },
    backgroundColor: theme.colors.transparent,
    color: theme.branding?.danger,
  },
  default: {
    fontSize: px(FONT_SIZE_DEFAULT),
    padding: `${px(SPACING_HALF)} ${px(SPACING)}`,
  },
  ghost: {
    '&:disabled': {
      backgroundColor: theme.colors.transparent,
      border: `solid ${px(BUTTON_BORDER_SMALL)} ${theme.branding?.disabledText ?? 'transparent'}`,
      color: theme.branding?.disabledText,
    },
    '&:hover': {
      backgroundColor: theme.branding?.primaryColor,
      color: theme.branding?.primaryButtonTextColor,
    },
    '&:visited': {
      backgroundColor: theme.colors.transparent,
      border: `solid ${px(1)} ${theme.branding?.primaryColor ?? 'transparent'}`,
      color: theme.branding?.primaryColor,
    },
    backgroundColor: theme.colors.transparent,
    border: `solid ${px(1)} ${theme.branding?.primaryColor ?? 'transparent'}`,
    color: theme.branding?.primaryColor,
  },
  highlight: {
    '&:active': {
      color: theme.branding?.highlightActive,
    },
    '&:disabled': {
      backgroundColor: theme.colors.transparent,
      color: theme.branding?.disabledText,
    },
    '&:hover': {
      backgroundColor: theme.colors.transparent,
      color: theme.branding?.highlightHover,
    },
    '&:visited': {
      backgroundColor: theme.colors.transparent,
      color: theme.branding?.highlight,
    },
    backgroundColor: theme.colors.transparent,
    color: theme.branding?.highlight,
  },
  iconWrapper: {
    paddingRight: px(SPACING_HALF),
  },
  iconWrapperNoSpacing: {
    paddingRight: '0 !important',
  },
  large: {
    fontSize: px(FONT_SIZE_EXTRA_LARGE),
    minWidth: px(MIN_BUTTON_LARGE_WIDTH),
    padding: `${px(SPACING)} ${px(SPACING_DOUBLE)}`,
    [`@media (max-width: ${screenXsMax}px)`]: {
      padding: `${px(SPACING_HALF)} ${px(SPACING)}`,
    },
  },
  link: {
    '&:active': {
      color: theme.branding?.linkActive,
    },
    '&:disabled': {
      backgroundColor: theme.colors.transparent,
      color: theme.branding?.disabledText,
    },
    '&:hover': {
      backgroundColor: theme.colors.transparent,
      color: theme.branding?.linkHover,
    },
    '&:visited': {
      backgroundColor: theme.colors.transparent,
      color: theme.branding?.primaryColor,
    },
    backgroundColor: theme.colors.transparent,
    color: theme.branding?.primaryColor,
    padding: SPACING_ZERO,
  },
  linkBlack: {
    '&:active': {
      backgroundColor: theme.colors.transparent,
      color: theme.palette.common.black,
    },
    '&:disabled': {
      backgroundColor: theme.colors.transparent,
      color: theme.branding?.disabledText,
    },
    '&:hover': {
      backgroundColor: theme.colors.transparent,
      color: theme.palette.common.black,
    },
    '&:visited': {
      backgroundColor: theme.colors.transparent,
      color: theme.palette.common.black,
    },
    backgroundColor: theme.colors.transparent,
    color: theme.palette.common.black,
  },
  medium: {
    padding: `${px(SPACING_QUARTER)} ${px(SPACING_HALF)}`,
  },
  noBorder: {
    border: 'none',
  },
  noPadding: {
    padding: 0,
  },
  onCard: {
    '& span span': {
      marginRight: SPACING_ZERO,
      paddingRight: SPACING_ZERO,
    },
    border: SPACING_ZERO,
    fontSize: FONT_SIZE_LARGE,
    margin: SPACING_ZERO,
    padding: SPACING_ZERO,
  },
  primary: {
    '&:focus': {
      outlineOffset: SPACING_ZERO,
    },
  },
  primaryInverted: {
    '&:active, &:hover': {
      backgroundColor: theme.branding?.primaryInvertedHover,
      outlineOffset: SPACING_ZERO,
    },
    backgroundColor: theme.palette.common.white,
    border: `solid ${px(BUTTON_BORDER)} ${theme.branding?.primaryFocusBorder ?? 'transparent'}`,
    color: theme.branding?.primaryColor,
    outlineOffset: SPACING_ZERO,
  },
  secondary: {
    '&:focus': {
      outlineOffset: SPACING_ZERO,
    },
    ...allLinkStates({
      color: theme.branding?.secondaryButtonTextColor,
    }),
  },
  small: {
    fontSize: px(FONT_SIZE_SMALL),
    padding: `${px(SPACING_QUARTER)} ${px(SPACING_HALF)}`,
  },
  success: {
    '&:active': {
      backgroundColor: theme.branding?.successActive,
    },
    '&:focus': {
      outlineOffset: SPACING_ZERO,
    },
    '&:hover': {
      backgroundColor: theme.branding?.successHover,
    },
    backgroundColor: theme.branding?.success,
  },
  tertiary: {
    '&:active': {
      backgroundColor: theme.branding?.tertiaryActive,
    },
    '&:focus': {
      outlineOffset: SPACING_ZERO,
    },
    '&:hover': {
      backgroundColor: theme.branding?.tertiaryHover,
    },
    backgroundColor: theme.branding?.tertiaryColor,
  },
} as const)
export default makeStyles({
  name: 'Button',
})(styles)
