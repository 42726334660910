import { memo } from 'react'
import useStyles from './styles'
import type { Props } from './types'

const HorizontalSpacer = memo<Props>(({
  double,
  half,
}) => {
  const { classes, cx } = useStyles()

  return (<span
    className={cx(classes.default, {
      [classes.half]: half,
      [classes.double]: double,
    })}
  />)
})

HorizontalSpacer.displayName = 'HorizontalSpacer'

export default HorizontalSpacer
