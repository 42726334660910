import { SPACING } from 'd2/constants'
import { px } from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'

const styles = () => ({
  buttonWrapper: {
    marginTop: px(SPACING),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    padding: '0 !important',
  },
} as const)
export default makeStyles({
  name: 'StorageTierModal',
})(styles)
