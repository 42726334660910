import type { GenericReducer } from '../types'
import type {
  NetworkExtra,
  ScheduleType,
  Social,
} from './types'

export type State = {
  deployAt: Scalar$DateTime | null | undefined,
  description: string | null | undefined,
  descriptionChanged: boolean,
  scheduleType: ScheduleType | null | undefined,
  selectedMediaId: string,
  selectedNetworkExtras: NetworkExtra[],
  selectedNetworkIds: string[],
  selectedSocials: Social[]
}

export const init: () => State = () => ({
  deployAt: null,
  description: null,
  descriptionChanged: false,
  scheduleType: null,
  selectedMediaId: '',
  selectedNetworkExtras: [],
  selectedNetworkIds: [],
  selectedSocials: [],
})

export type Reducer = GenericReducer<State>
const reducer: Reducer = (state = init(), action) => {
  switch (action.type) {
  case 'CREATE_RELEASE_DESCRIPTION': {
    return {
      ...state,
      description: action.payload.description,
    }
  }
  case 'CREATE_RELEASE_SELECT_MEDIA': {
    return {
      ...state,
      selectedMediaId: action.payload.selectedMediaId,
    }
  }
  case 'CREATE_RELEASE_SELECT_NETWORK': {
    return {
      ...state,
      selectedNetworkIds: action.payload.networkIds,
    }
  }
  case 'CREATE_RELEASE_SELECT_NETWORK_EXTRAS': {
    return {
      ...state,
      selectedNetworkExtras: action.payload.networkExtras,
    }
  }
  case 'CREATE_RELEASE_SELECT_SOCIALS': {
    return {
      ...state,
      selectedSocials: action.payload.socials,
    }
  }
  case 'CREATE_RELEASE_SET_DEPLOY_AT': {
    return {
      ...state,
      deployAt: action.payload.deployAt,
      scheduleType: action.payload.scheduleType,
    }
  }
  case 'CREATE_RELEASE_SET_DESCRIPTION_CHANGED': {
    return {
      ...state,
      descriptionChanged: action.payload.descriptionChanged,
    }
  }
  case 'CREATE_RELEASE_RESET': {
    return init()
  }
  default: {
    return state
  }
  }
}

export default reducer
