/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { forwardProps } from 'd2/utils/props'
import {
  forwardRef,
  memo,
  useMemo,
} from 'react'
import { includes, omit } from 'lodash-es'
import { isD2Path } from 'd2/utils/Navigation'
import { useSelector } from 'react-redux'
import Link from 'd2/components/Link'
import type { BrowserLocation } from 'd2/types'
import type { Props } from './types'
import type { State } from 'd2/reducers'
// This component takes a `to` prop, then looks at the current location to decide
// if it should use `to` and do SPA nav, or if it should use `href` and do
// legacy d1 navigation.

const AwareLink = memo<Props>(forwardRef((props, ref) => {
  const toOrHref: string | null | undefined = props.to || props.href
  const location = useSelector<State, BrowserLocation>(({ router }) => router.location)
  const d2ToD2 = useMemo<boolean>(
    () => isD2Path(location) && !!toOrHref && isD2Path(toOrHref),
    [location, toOrHref],
  )
  // makes sure that if the props.to contains a whole url to use the anchor tag rather than Link
  // Link will append the whole url to a 'd2/' prefix
  return d2ToD2 && !includes(toOrHref, 'https://')
    ? (
      // SPA nav
      // eslint-disable-next-line react/forbid-elements
      <Link
        data-turbolinks='false'
        // @ts-expect-error TODO: Type '{ to: string; replace?: boolean | undefined; children?: ReactNode; className?: string | undefined; onClick?: ((event: SyntheticEvent<HTMLButtonElement, Event>) => Promise<...> | ... 1 more ... | undefined) | undefined; title?: string | ... 1 more ... | undefined; "data-turbolinks": string; innerRef: ForwardedRef<......' is not assignable to type 'IntrinsicAttributes & Props'. Property 'innerRef' does not exist on type 'IntrinsicAttributes & Props'.ts(2322)
        innerRef={ref}
        {...forwardProps(omit(props, ['href', 'to']))}
        to={toOrHref ?? ''}
      />
    )
    : (
      // Legacy nav
      // eslint-disable-next-line jsx-a11y/anchor-has-content, react/forbid-elements
      <a
        data-turbolinks='false'
        {...forwardProps(omit(props, ['href', 'to']))}
        href={toOrHref}
        // @ts-expect-error ref can be a function apparently? Not sure how to handle properly with forwardRef
        ref={ref}
      />
    )
}))

AwareLink.displayName = 'AwareLink'

export default AwareLink
