/* eslint-disable @typescript-eslint/default-param-last */
import {
  compact,
  constant,
  filter,
  find,
  noop,
  reject,
  some,
} from 'lodash-es'
import {
  createContext,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import type {
  CalculateTotalAmountType,
  ContextType,
  Product,
  ProviderType,
  Value,
} from './types'

// TODO: Remove 'as' type assertions because they are unsafe.
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const CartContext: ContextType = createContext({
  emptyCart: noop,
  getProduct: noop,
  isProductInCart: constant(false),
  products: [],
  setProducts: noop,
  totalAmount: 0,
  updateCart: undefined,
} as Value)

const calculateTotalAmount: CalculateTotalAmountType = (products = [], callback) => {
  let temporaryTotalAmount = 0
  for (const product of products) {
    temporaryTotalAmount += (product.price * product.quantity) || 0
  }
  callback(temporaryTotalAmount)
}

const findProductInCart = ({ id, type }: {
  id: string,
  type: string,
}, productList: Product[]) => find(productList, {
  id,
  type,
})

const CartProvider: ProviderType = memo(({ children }) => {
  const [totalAmount, setTotalAmount] = useState(0)
  const [products, setProducts] = useState<Product[]>([])

  useEffect(() => {
    calculateTotalAmount(products, setTotalAmount)
  }, [products, setTotalAmount])

  const isProductInCart = useCallback(({ id, type }: {
    id: string,
    type: string,
  }) => !!findProductInCart({
    id,
    type,
  }, products), [products])

  const updateCart = useCallback((product: Product) => {
    setProducts((currentProductList) => {
      const currentProduct = findProductInCart({
        id: product.id,
        type: product.type,
      }, currentProductList)
      if (currentProduct && product.quantity === 0) {
        return reject(currentProductList, {
          id: product.id,
          type: product.type,
        })
      }

      return currentProduct
        ? currentProductList.map((productInCart) =>
          productInCart.id === product.id && productInCart.type === product.type
            ? {
              ...productInCart,
              ...product,
            }
            : productInCart,
        )
        : some(product.additional_services)
          ? [
            ...compact(currentProductList.map((productInList: Product): Product | null | undefined => {
              const resolvedQuantity: number = productInList.quantity - filter(product.additional_services, ({ id }) => id === productInList.id && productInList.type === 'service').length
              if (resolvedQuantity === 0) return null
              return {
                ...productInList,
                quantity: resolvedQuantity,
              }
            })),
            product,
          ]
          : [...currentProductList, product]
    })
  }, [])

  return (
    <CartContext.Provider
      value={useMemo(() => ({
        emptyCart: () => setProducts([]),
        getProduct: ({ id, type }) => findProductInCart({
          id,
          type,
        }, products),
        isProductInCart,
        products,
        setProducts,
        totalAmount,
        updateCart,
      }), [
        isProductInCart,
        products,
        totalAmount,
        updateCart,
      ])}
    >
      { children }
    </CartContext.Provider>
  )
})

export default CartProvider
