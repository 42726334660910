import { gql } from '@apollo/client'
import {
  memo,
  useEffect,
  useMemo,
} from 'react'
import { useLocation } from 'd2/hooks/useRouter'
import { useQuerySwitch } from 'd2/hooks/useQuerySwitch'
import useSnackbar from 'd2/hooks/useSnackbar'
import type { ReducerArg } from 'd2/hooks/useQuerySwitch'
import type { SessionNotices } from 'd2/queries'

type Props = {
  isTddRoot?: boolean
}

const reducer = ({ data }: ReducerArg<SessionNotices>) => data?.session && data.session.notices
const useQuery = ({
  skip,
}: {
  skip: boolean
}) => useQuerySwitch<SessionNotices, EO, typeof reducer>(gql`
  query SessionNotices {
    session {
      id
      notices {
        type
        message
        unescaped
      }
    }
  }
`, {
  pollInterval: 15 * 60 * 1000, // Might as well query every 15 minutes instead of only on initial load.
  reducer,
  skip,
})

const SessionNoticesShowSnackbars = memo<Props>(({
  isTddRoot: isTddRootProp,
}) => {
  const { showSnackbar } = useSnackbar()
  const location = useLocation()

  const isTddRoot = useMemo(
    () => typeof isTddRootProp === 'boolean'
      ? isTddRootProp
      : location.pathname === '/d2/tdd' || location.pathname === '/d2/tdd/blank_partial_route',
    [isTddRootProp, location],
  )

  const [notices] = useQuery({ skip: isTddRoot })

  useEffect(() => {
    if (notices?.length) {
      for (const { message, type, unescaped: _unescaped } of notices) {
        // TODO: Right now I think showing multiple snackbars isn't supported and they will overwrite each other.
        // TODO: Support dangerouslySetInnerHTML in snackbars, and utilize `unescaped` option.
        showSnackbar({
          message,
          type: type === 'success' ? 'success' : 'error',
        })
      }
    }
  }, [notices, showSnackbar])

  return null
})

export default SessionNoticesShowSnackbars
