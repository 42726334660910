import { capitalize, split } from 'lodash-es'
import { forwardProps } from 'd2/utils/props'
import {
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { useIsMobile } from '../Responsive'
// eslint-disable-next-line consistent-default-export-name/default-import-match-filename
import MaterialTooltip from '@mui/material/Tooltip'
import useStyles from './styles'
import type { Props } from './types'

const Tooltip = memo<Props>(({
  className,
  disableTooltip = false,
  hasArrow = false,
  placement = 'top',
  title = '',
  type = 'normal',
  ...props
}) => {
  const isMobile = useIsMobile()
  const { classes, cx } = useStyles({
    hasArrow,
    isMobile,
  })
  const [open, setOpen] = useState<boolean>(false)

  const onBlur = useCallback(() => {
    setOpen(false)
  }, [])

  const onFocus = useCallback(() => {
    setOpen(true)
  }, [])

  const tooltipTitle = useMemo(() => ((
    <div
      className={classes.tooltipInnerWrapper}
    >
      { title }
    </div>
  )), [title, classes])

  const onMouseEnter = useCallback(() => {
    setOpen(true)
  }, [])

  return (
    <div
      className={cx(className)}
      onMouseLeave={() => {
        setOpen(false)
      }}
    >
      <MaterialTooltip
        arrow={hasArrow}
        onBlur={onBlur}
        onFocus={onFocus}
        onMouseEnter={onMouseEnter}
        open={!disableTooltip && open}
        {...forwardProps(props)}
        classes={{
          arrow: cx(classes.arrow),
          popper: cx(
            // @ts-expect-error Element implicitly has an 'any' type because expression of type '`popper${string}`' can't be used to index type 'ClassNameMap<{ popperBottom: { marginTop: number; }; popperHidden: { display: string; }; popperLeft: { marginRight: number; }; popperRight: { marginLeft: number; }; popperTop: { marginBottom: number; }; tooltip: { ...; }; ... 14 more ...; tooltipPlacementTopNormal: { ...; }; }>'.ts(7053)
            classes[`popper${capitalize(split(placement, '-', 1)[0])}`],
            {
              [classes.popperHidden]: title === '',
            },
          ),
          tooltip: cx(
            classes.tooltip,
            // @ts-expect-error Element implicitly has an 'any' type because expression of type '`tooltip${string}`' can't be used to index type 'ClassNameMap<{ popperBottom: { marginTop: number; }; popperHidden: { display: string; }; popperLeft: { marginRight: number; }; popperRight: { marginLeft: number; }; popperTop: { marginBottom: number; }; tooltip: { ...; }; ... 14 more ...; tooltipPlacementTopNormal: { ...; }; }>'.ts(7053)
            classes[`tooltip${capitalize(type)}`],
            // @ts-expect-error Element implicitly has an 'any' type because expression of type '`tooltip${string}`' can't be used to index type 'ClassNameMap<{ popperBottom: { marginTop: number; }; popperHidden: { display: string; }; popperLeft: { marginRight: number; }; popperRight: { marginLeft: number; }; popperTop: { marginBottom: number; }; tooltip: { ...; }; ... 14 more ...; tooltipPlacementTopNormal: { ...; }; }>'.ts(7053)
            classes[`tooltipPlacement${capitalize(split(placement, '-', 1)[0])}`],
            // @ts-expect-error Element implicitly has an 'any' type because expression of type '`tooltip${string}`' can't be used to index type 'ClassNameMap<{ popperBottom: { marginTop: number; }; popperHidden: { display: string; }; popperLeft: { marginRight: number; }; popperRight: { marginLeft: number; }; popperTop: { marginBottom: number; }; tooltip: { ...; }; ... 14 more ...; tooltipPlacementTopNormal: { ...; }; }>'.ts(7053)
            classes[`tooltipPlacement${capitalize(split(placement, '-', 1)[0])}${capitalize(type)}`],
          ),
        }}
        placement={placement}
        title={tooltipTitle}
      />
    </div>
  )
})

Tooltip.displayName = 'Tooltip'

export default Tooltip
