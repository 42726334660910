import { onlyText } from 'react-children-utilities'
import { size, trim } from 'lodash-es'

// TODO: If needed, pull this out into a shared utility.

export function forwardProps<T extends {
  [x: string]: any
}> (props: T) {
  const {
    /* eslint-disable unused-imports/no-unused-vars,@typescript-eslint/no-unused-vars */
    fullWidth,
    testID,
    textColor,
    theme,
    /* eslint-enable unused-imports/no-unused-vars,@typescript-eslint/no-unused-vars */
    ...rest
  } = props

  return rest
}

export function nodeIncludesText (node?: React$Node | null): boolean {
  return !!node && size(trim(onlyText(node))) > 0
}
