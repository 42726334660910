import {
  SPACING,
  SPACING_DOUBLE,
  SPACING_HALF,
} from 'd2/constants'
import { px } from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'

const styles = () => ({
  default: {
    paddingRight: px(SPACING),
  },
  double: {
    paddingRight: px(SPACING_DOUBLE),
  },
  half: {
    paddingRight: px(SPACING_HALF),
  },
} as const)

export default makeStyles({
  name: 'HorizontalSpacer',
})(styles)
