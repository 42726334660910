import { memo, useEffect } from 'react'

/*

BodyClass
--------------------

Append a class to the html document body when mounted and remove the class on unmount.

*/

export type Props = {
  className: string
}

const BodyClass = memo<Props>(({
  className,
}) => {
  useEffect(() => {
    let body: HTMLElement | null | undefined
    try {
      body = document.body
    } catch (error) {
      // TODO: Why are we catching these errors? Isn't it too defensive?
      // eslint-disable-next-line no-console
      console.error('Error in d2/src/hocs/BodyClass/index.js', error)
    }
    if (body) {
      body.classList.add(className)
    }
    return () => {
      if (body) {
        body.classList.remove(className)
      }
    }
  }, [className])

  return null
})

BodyClass.displayName = 'BodyClass'

export default BodyClass
