import { px } from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

export const LINE_HEIGHT = 24

// TODO: Move sizes to theme
const styles = (theme: MergedThemes) => ({
  body: {
    fontFamily: theme.branding?.primaryFontFamily,
    fontSize: px(16),
    lineHeight: px(LINE_HEIGHT),
  },
} as const)

export default makeStyles({
  name: 'Body',
})(styles)
