import {
  Children,
  forwardRef,
  memo,
} from 'react'
import { isFunction, isString } from 'lodash-es'
import FaIcon from 'd2/components/FaIcon'
import SizedButton from '../SizedButton'
import useStyles from './styles'
import type { IconButtonProps } from './types'

const IconButton = memo<IconButtonProps>(forwardRef(
  ({
    children,
    className,
    icon,
    iconSpacing,
    iconWeight,
    noPadding,
    spin,
    ...props
  }, ref) => {
    const needSpacing: boolean = iconSpacing || (!!icon && Children.count(children) > 0)
    const { classes } = useStyles({ needSpacing })
    const resolvedIcon = icon && isFunction(icon)
      ? icon()
      : isString(icon)
        ? <FaIcon
          icon={icon}
          spin={spin}
          weight={iconWeight ?? 'solid'}
        />
        : icon

    return (
      <SizedButton
        {...props}
        className={`${classes.root} ${className ?? ''}`}
        noPadding={noPadding}
        ref={ref}
      >
        <div
          className={classes.iconWrapper}
        >
          { resolvedIcon }
        </div>
        { children }
      </SizedButton>
    )
  },
))

IconButton.displayName = 'IconButton'

export default IconButton
