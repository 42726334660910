import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

const styles = (theme: MergedThemes) => ({
  grayText: {
    '&:active': {
      color: theme.colors.darkGray,
    },
    '&:focus': {
      color: theme.colors.darkGray,
    },
    '&:hover': {
      color: theme.colors.darkGray,
    },
    color: theme.colors.darkGray,
  },
} as const)

export default makeStyles({
  name: 'TableSortLabel',
})(styles)
