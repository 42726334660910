import { gql } from '@apollo/client'
import { useQuerySwitch } from 'd2/hooks/useQuerySwitch'
import type { ReducerArg } from 'd2/hooks/useQuerySwitch'
import type { StorageTierModal } from 'd2/queries'

const reducer = ({
  data,
  error,
  loading,
}: ReducerArg<StorageTierModal>) => ({
  ...data,
  error,
  loading,
})
export const useQuery = () => useQuerySwitch<StorageTierModal, EO, typeof reducer>(gql`
  query StorageTierModal {
    me {
      id
      payment_methods_count
      exceeded_storage_limit
      has_hidden_payment_plan
      current_storage_usage
      active_storage_tier {
        id
        tier_permission {
          id
          name
          yearly_price
          description
          size_in_bytes
          max_artist_count
        }
      }
      next_required_storage_tier {
        id
        tier_permission {
          id
          name
          yearly_price
          description
          size_in_bytes
          max_artist_count
        }
      }
    }
  }
`, {
  reducer,
})
