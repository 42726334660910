import type { Exact } from 'd2/types'
import type { GenericReducer } from '../types'

// TODO: How to use an array instead? The values do nothing here.
export const LIMITED_BETA_KEYS = Object.freeze({
  LimitedBeta: undefined, // TODO: update to valid key when we have another key besides RoyaltyCenter
  RoyaltyCenter: undefined,
})

export type LimitedBetaFeatureState = {
  canContinue: boolean | null | undefined
}

export type LimitedBetaFeature = keyof typeof LIMITED_BETA_KEYS

export type State = Exact<{
  [k in LimitedBetaFeature]?: LimitedBetaFeatureState | null | undefined;
}>

export const init: () => State = () => ({})

export type Reducer = GenericReducer<State>
const reducer: Reducer = (state = init(), action) => {
  switch (action.type) {
  case 'SHOW_LIMITED_BETA': {
    const { payload: { feature } } = action

    return {
      ...state,
      [feature]: {
        ...state[feature],
        canContinue: true,
      },
    }
  }
  case 'HIDE_LIMITED_BETA': {
    const { payload: { feature } } = action

    return {
      ...state,
      [feature]: {
        ...state[feature],
        canContinue: false,
      },
    }
  }
  default: {
    return state
  }
  }
}

export default reducer
