import { SPACING_HALF } from 'd2/constants'
import { px } from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

const SWITCH_HEIGHT = 22
const SWITCH_WIDTH = 40

type ClassNames = 'checked' | 'track'

type StylesProps = {
  checked: boolean | undefined,
  labelPlacement?: 'bottom' | 'end' | 'start' | 'top',
  secondary?: boolean | undefined,
}
// Style was taking from the iOS example here https://material-ui.com/components/switches/
const styles = (theme: MergedThemes, props: StylesProps, classes: Record<ClassNames, unknown>) => ({
  checked: {},
  focusVisible: {},
  label: {
    marginLeft: 0,
    marginRight: 0,
  },
  root: {
    height: px(SWITCH_HEIGHT),
    margin: props.labelPlacement === 'end' ? `0 ${px(SPACING_HALF)} 0 0` : `0 0 0 ${px(SPACING_HALF)}`,
    overflow: 'visible',
    padding: 0,
    width: px(SWITCH_WIDTH),
  },
  switchBase: {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    [`& .${classes.checked}`]: {
      color: `${theme.colors.faintGray} !important`,
      transform: 'translateX(16px)',
    },
    color: `${theme.colors.faintGray} !important`,
    padding: 1,
  },
  thumb: {
    height: px(SWITCH_HEIGHT - 2),
    width: px(SWITCH_HEIGHT - 2),
  },
  track: {
    ...props.checked
      ? props.secondary
        ? { backgroundColor: `${theme.branding?.secondaryColor ?? 'transparent'} !important` }
        : { backgroundColor: `${theme.branding?.primaryColor ?? 'transparent'} !important` }
      : { backgroundColor: `${theme.colors.faintGray} !important` },
    border: `1px solid ${theme.palette.grey['400']}`,
    borderRadius: px((SWITCH_HEIGHT + 2) / 2), // Plus 2 because of the 1px border
    opacity: '1 !important',
    transition: theme.transitions.create(['background-color', 'border']),
  },
} as const)

export default makeStyles<StylesProps, ClassNames>({
  name: 'Switch',
})(styles)
