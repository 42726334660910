import {
  createContext,
  memo,
  useMemo,
} from 'react'
import { difference } from 'lodash-es'
import { gql } from '@apollo/client'
import { isProduction } from 'd2/utils/environment'
import { useGhostLoadingSwitch, useQuerySwitch } from 'd2/hooks/useQuerySwitch'
import type { AssetVersion } from 'd2/queries'
import type { ContextValue, Props } from './types'
import type { DocumentNode } from 'd2/types'
import type { ReducerArg } from 'd2/hooks/useQuerySwitch'

const QUERY: DocumentNode = gql`
  query AssetVersion {
    webpack_asset_uris(bundle: DASHBOARD_2, type: JS)
  }
`

// TODO: Remove 'as' type assertions because they are unsafe.
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const AssetVersionContext: React$Context<ContextValue> = createContext({
  stale: false,
} as ContextValue)

const reducer = ({ data }: ReducerArg<AssetVersion>) => data?.webpack_asset_uris

const AssetVersionProvider = memo<Props>(({
  children,
}) => {
  const [webpackAssetUris] = useQuerySwitch<AssetVersion, EO, typeof reducer>(QUERY, useMemo(
    () => ({
      loadingSwitchHook: useGhostLoadingSwitch,
      pollInterval: isProduction() ? 60 * 1000 : 0, // In dev and test, we don't need to poll because webpack-dev-server will always serve the latest version and we know to refresh.
      reducer,
    }),
    [],
  ))

  // TODO: Disable in dev and test env?

  const scriptSrcsInDocument = useMemo<string[]>(() => {
    let scripts: HTMLScriptElement[] | NodeListOf<HTMLScriptElement>
    try {
      scripts = document.querySelectorAll('script')
    } catch (error) {
      // TODO: Why are we catching these errors? Isn't it too defensive?
      // eslint-disable-next-line no-console
      console.error('Error in d2/src/providers/AssetVersionProvider/index.js', error)
      scripts = []
    }
    // TODO: Remove 'as' type assertions because they are unsafe.
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return Array.prototype.map.call(scripts, (el: HTMLScriptElement) => el.src as string).filter(Boolean) as string[]
  }, [])

  // TODO: Css too!
  // const linkHrefsInDocument = useMemo(() => compact(map(document.getElementsByTagName("link"), (el) => el.href), [join(webpackAssetUrisCss, "_")])

  const stale: boolean = useMemo(() =>
    !!webpackAssetUris
    && scriptSrcsInDocument.length > 0
    && difference(
      webpackAssetUris,
      scriptSrcsInDocument.map((src) => decodeURI(src)),
    ).length > 0,
  [webpackAssetUris, scriptSrcsInDocument],
  )

  const value: ContextValue = useMemo(() => ({
    stale,
  }), [stale])

  return (
    <AssetVersionContext.Provider
      value={value}
    >
      { children }
    </AssetVersionContext.Provider>
  )
})

AssetVersionProvider.displayName = 'AssetVersionProvider'

export default AssetVersionProvider
