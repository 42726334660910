import type { GenericReducer } from '../types'

export type State = {
  isSticky: boolean
}

const init: () => State = () => ({
  isSticky: false,
})

export type Reducer = GenericReducer<State>
const reducer: Reducer = (state = init(), action) => {
  switch (action.type) {
  case 'HEADER_STICK': {
    return {
      ...state,
      isSticky: true,
    }
  }
  case 'HEADER_UNSTICK': {
    return {
      ...state,
      isSticky: false,
    }
  }
  default: {
    return state
  }
  }
}

export default reducer
