import { gql } from '@apollo/client'
import { useSingleMutation } from 'd2/hooks/useMutation'
import type { DocumentNode } from 'd2/types'
import type { MutationReducerArg } from 'd2/hooks/useMutation'
import type { PasswordConfirm, PasswordConfirmVariables } from 'd2/queries'

const MUTATION: DocumentNode = gql`
  mutation PasswordConfirm($input: ConfirmPasswordInput!) {
    confirmPassword(input: $input) {
      errors {
        key
        messages
      }
    }
  }
`

const reducer = ({ data }: MutationReducerArg<PasswordConfirm, PasswordConfirmVariables>) => data?.confirmPassword
export function useConfirmPassword () {
  return useSingleMutation<PasswordConfirm, PasswordConfirmVariables, typeof reducer>(MUTATION, {
    reducer,
  })
}
