import {
  MAX,
  PICKER_CHECKBOX_ICON_SIZE,
  PICKER_CONTAINER_BORDER,
  PICKER_CONTAINER_BORDER_DISABLED,
  PICKER_CONTAINER_BORDER_HIGHLIGHT,
  PICKER_IMAGE_WIDTH,
  PICKER_IMAGE_WIDTH_LARGE,
  SPACING,
  SPACING_DOUBLE,
  SPACING_HALF,
} from 'd2/constants'
import { alpha } from '@mui/material/styles'
import { px } from 'd2/utils/style'
import { zDepthAnimationOnHover } from 'd2/animations'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

const PICKER_ITEM_PADDING: string = px(SPACING_HALF)

const styles = (theme: MergedThemes) => ({
  alternateOutline: {
    borderBottom: `${px(1)} solid ${theme.palette.grey.A100} !important`,
  },
  checkbox: {
    height: 'auto', // override material UI's 48px widths
    width: 'auto',
  },
  checkboxWrapper: {
    margin: 0,
  },
  checkboxWrapperBottomSelect: {
    '&:after': {
      content: '" Select"',
    },
    bottom: px(SPACING),
    left: 0,
    margin: 0,
    position: 'absolute',
    textAlign: 'center',
    width: '100%',
  },
  container: {
    height: MAX,
    padding: '0 !important',
  },
  disabled: {
    backgroundColor: theme.branding?.disabled,
    cursor: 'default',
    outline: `${px(PICKER_CONTAINER_BORDER_DISABLED)} solid ${theme.palette.grey.A100} !important`,
  },
  highlightBackground: {
    backgroundColor: theme.branding && alpha(theme.branding.primaryColor, 0.15),
  },
  hover: {
    ...zDepthAnimationOnHover,
  },
  hoverSelected: {
    boxShadow: `0 ${px(6)} ${px(16)} 0 rgba(212, 212, 212, 0.5)`,
  },
  icon: {
    height: px(PICKER_CHECKBOX_ICON_SIZE),
    padding: 0,
    width: px(PICKER_CHECKBOX_ICON_SIZE),
  },
  image: {
    height: px(PICKER_IMAGE_WIDTH),
    width: px(PICKER_IMAGE_WIDTH),
  },
  imageBottomSelect: {
    height: '180px', // TODO: remove hardcoded value & set sizing appropriately for non-mobile screens. Should fit vertically on mobile screens.
    marginBottom: px(SPACING_DOUBLE),
    width: '100%',
  },
  imageLarge: {
    height: px(PICKER_IMAGE_WIDTH_LARGE),
    width: px(PICKER_IMAGE_WIDTH_LARGE),
  },
  label: {
    '&:first-of-type': {
      margin: `0 ${px(SPACING)} 0 0`,
    },
    margin: `0 ${px(SPACING)} 0 ${px(SPACING)}`,
  },
  labelSmall: {
    '&:first-of-type': {
      margin: `0 ${px(SPACING_HALF)} 0 0`,
    },
    margin: `0 ${px(SPACING_HALF)} 0 ${px(SPACING_HALF)}`,
  },
  metadataContainerBottomSelect: {
    padding: 0,
  },
  notSelected: {
    outline: `${px(PICKER_CONTAINER_BORDER)} solid ${theme.colors.faintGray}`,
  },
  pickerButton: {
    padding: PICKER_ITEM_PADDING,
    textAlign: 'left',
    width: MAX,
  },
  pickerItemSmall: {
    padding: PICKER_ITEM_PADDING,
  },
  selected: {
    outline: `${px(PICKER_CONTAINER_BORDER_HIGHLIGHT)} solid ${theme.branding?.primaryColor ?? 'transparent'}`,
  },
} as const)
export default makeStyles({
  name: 'Picker',
})(styles)
