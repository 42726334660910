import { isDevelopment } from 'd2/utils/environment'
import { memo } from 'react'
import ErrorBoundary, { ErrorBoundaryProps } from 'react-error-boundary'
import isTestEnv from 'd2/utils/isTestEnv'
import raygun from 'd2/utils/raygun'

// TODO: Put in examples
// TODO: Messaging & layout.
const Fallback: ErrorBoundaryProps['FallbackComponent'] = ({
  componentStack,
  error,
}) => (
  <>
    <div>
      Oops! Something went wrong.
      { ' ' }
      { /* eslint-disable-next-line react/forbid-elements */ }
      <a
        href='#'
        onClick={(event) => {
          event.preventDefault()
          if (typeof window === 'undefined') return
          window.location.reload()
        }}
      >
        Reload the page
      </a>
      { ' ' }
      and try again.
    </div>
    { isDevelopment() && (
      <div>
        <pre>
          { error?.toString() }
        </pre>
        <pre>
          { componentStack }
        </pre>
      </div>
    ) }
  </>
)

const onError: ErrorBoundaryProps['onError'] = (error, componentStack) => {
  // TODO: Is this doing anything? Jest still seems to continue along and doesn't care that there was an error thrown.
  if (isTestEnv) throw error

  raygun('send', {
    customData: {
      componentStack,
    },
    error,
    tags: ['ReactErrorBoundary'],
  })
}

const ApplicationErrorBoundary = memo<{
  children: React$Node
}>(({
  children,
}) => (
  isTestEnv
    ? <>
      { children }
    </>
    : (
      <ErrorBoundary
        FallbackComponent={Fallback}
        onError={onError}
      >
        { children }
      </ErrorBoundary>
    )
))

export default ApplicationErrorBoundary
