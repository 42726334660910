/* eslint-disable babel/camelcase */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { RightsAssetType } from 'd2/queries'
import {
  clone,
  constant,
  every,
  find,
  findIndex,
  isEmpty,
  isNil,
  mapValues,
  pickBy,
  reduce,
  slice,
  tap,
} from 'lodash-es'
import { d } from 'd2/utils'
import type {
  ArtistObject,
  CountryCode,
  ExplicitType,
  Genre,
  MediaLanguageCode,
  MediaPolicyType,
  SoundExchangePolicyType,
  SupportedVideo,
  UploadType,
} from 'd2/queries'
import type { Exact } from 'd2/types'
import type { GenericReducer } from '../types'

// Duplicated from d2/src/hooks/useUploader/index.js during TS migration
export type UploadingFile = {
  duration?: number,
  id: string,
  name: string,
  progress: number,
  s3Key: string,
  size: number,
  title: string,
  type: UploadType,
  error: boolean,
  errorReason: string,
}

export type RightsObject = {
  assetType: RightsAssetType,
  geoRestrictions: CountryCode[],
  geoSameAsMusic: boolean,
  rightControl: boolean | null | undefined
}

export type Composer = {
  composerId: string | null | undefined,
  email?: string | null,
  invite?: boolean | null,
  invitedAt?: string,
  name: string,
  ownershipPercentage?: number | null,
  ownExclusiveRights?: boolean | null,
  perfRightsOrg?: string | null
}

export type AppliedToAll = {
  album: boolean,
  artistId: boolean,
  copyrightName: boolean,
  copyrightYear: boolean,
  countryOfRecording: boolean,
  director: boolean,
  explicitType: boolean,
  genre: boolean,
  isCover: boolean,
  label: boolean,
  language: boolean,
  lyrics: boolean,
  productionCompany: boolean,
  secondaryGenre: boolean,
  subcategory: boolean,
  videoProducer: boolean
}

export type AppliedToAllCheckboxValue = boolean | null

export type AppliedToAllCheckboxValues = {
  artistId: AppliedToAllCheckboxValue,
  copyrightName: AppliedToAllCheckboxValue,
  copyrightYear: AppliedToAllCheckboxValue,
  countryOfRecording: AppliedToAllCheckboxValue,
  genre: AppliedToAllCheckboxValue,
  language: AppliedToAllCheckboxValue
}

export type AppliedToAllAttributesEnum = `${keyof AppliedToAll}`

export type AppliedToAllAttributeValuesEmpty = Exact<EO>
export type AppliedToAllAttributeValuesExact = {
  artistId?: string | null,
  copyrightName?: string | null,
  copyrightYear?: number | null,
  genre?: Genre | null,
  isCover?: boolean,
  explicitType?: ExplicitType | null,
  language?: MediaLanguageCode | null
}

export type DefaultMetadata = {
  copyrightName: string | null | undefined,
  copyrightYear: number | null | undefined,
  genre: Genre | null | undefined,
  label: string | null | undefined,
  language: MediaLanguageCode | null | undefined,
  secondaryGenre: Genre | null | undefined
}

export type VideoCategory = 'music' | 'web'

export type AppliedToAllAttributeValues = AppliedToAllAttributeValuesEmpty | AppliedToAllAttributeValuesExact

export type File = {
  additionalArtists: ArtistObject[],
  additionalCredits: ArtistObject[],
  album: string | null | undefined,
  appliedToAll: AppliedToAll,
  artistId: string | null | undefined,
  claimFromDate: Scalar$DateTime | null | undefined,
  composers: Composer[] | null | undefined,
  compositionRights: RightsObject,
  controlAllRights: boolean | null | undefined,
  copyrightName: string | null | undefined,
  copyrightYear: number | null | undefined,
  countryOfRecording?: CountryCode,
  director: string | null | undefined,
  duration: number | null | undefined,
  error: boolean | null | undefined,
  errorReason: string | null | undefined,
  existingAudioMetadataId?: string | null | undefined,
  explicitType: ExplicitType | null | undefined,
  externalUrl?: string,
  facebook: MediaPolicyType | null | undefined,
  fileName: string,
  fileSize?: number,
  genre: Genre | null | undefined,
  hasExistingAudioMetadata?: boolean | null | undefined,
  instagram: MediaPolicyType | null | undefined,
  isCover: boolean,
  isrc: string | null | undefined,
  iswc: string | null | undefined,
  label: string | null | undefined,
  language: MediaLanguageCode | null | undefined,
  lyrics: string | null | undefined,
  mediaType: string | null | undefined,
  musicVideoRights: RightsObject,
  musicVideoType: SupportedVideo | null | undefined,
  productionCompany: string | null | undefined,
  removed: boolean,
  s3Key?: string,
  secondaryGenre: Genre | null | undefined,
  soundRecordingRights: RightsObject,
  soundExchange: SoundExchangePolicyType | null | undefined,
  subcategory: string | null | undefined,
  clipStartTime?: string | null | undefined,
  title: string,
  uploadId?: string,
  uploadProgress: number,
  videoCategory: VideoCategory | null | undefined,
  videoIsrc: string | null | undefined,
  videoProducer: string | null | undefined,
  wasValidated: boolean,
  webRights: RightsObject,
  writerCredits: string[] | null | undefined,
  youtube: MediaPolicyType | null | undefined
}

export type CompFile = {
  additionalArtists?: ArtistObject[],
  additionalCredits?: ArtistObject[],
  artist_id: string | null | undefined,
  composers: Array<Composer> | null | undefined,
  compositionRights: RightsObject,
  copyright_name: string | null | undefined,
  copyright_year: number | null | undefined,
  facebook: MediaPolicyType | null | undefined,
  genre: Genre | null | undefined,
  instagram: MediaPolicyType | null | undefined,
  isrc: string | null | undefined,
  iswc: string | null | undefined,
  label: string | null | undefined,
  lyrics: string | null | undefined,
  secondary_genre: Genre | null | undefined,
  title: string,
  writerCredits: string[] | null | undefined,
  youtube: MediaPolicyType | null | undefined,
  soundExchange: SoundExchangePolicyType | null | undefined,
}

export type State = {
  activeAccordion: string | null | undefined,
  defaultMetadata: DefaultMetadata,
  files: File[],
  selectedArtistId: string | null | undefined,
  selectedFileIndex: number | null | undefined,
  selectedUserId: string | null | undefined,
  successPath: string | null | undefined
}

export type StateAppliedToAllAttributeValues = {
  activeAccordion?: string | null,
  files: File[],
  selectedFileIndex: number | null | undefined,
  successPath?: string | null
}

export type NewFile = {
  duration?: number | null,
  externalUrl?: string,
  fileName: string,
  fileSize?: number,
  s3Key?: string,
  title: string,
  uploadId?: string
}

const initialDefaultMetadata: DefaultMetadata = {
  copyrightName: null,
  copyrightYear: null,
  genre: null,
  label: null,
  language: null,
  secondaryGenre: null,
}

export const init: () => State = () => ({
  activeAccordion: null,
  defaultMetadata: initialDefaultMetadata,
  files: [],
  selectedArtistId: null,
  selectedFileIndex: null,
  selectedUserId: null,
  successPath: null,
})

const DEFAULT_VIDEO_CATEGORY = 'music'

const getAppliedToAllAttributeValuesFromState: (a: State) => AppliedToAllAttributeValues = ({ files }) =>
  reduce(files, (attributeValuesFromState, file) => ({
    ...attributeValuesFromState,
    ...mapValues(pickBy(file.appliedToAll), (_, attribute: AppliedToAllAttributesEnum) => file[attribute]),
  }), {})

export const mapNewFiles: (
  a: {
    file: NewFile,
    state: State
  }
) => File = ({
  file: {
    duration,
    externalUrl,
    fileName,
    fileSize,
    s3Key,
    title,
    uploadId,
  },
  state,
}) => {
  const attributeValues = getAppliedToAllAttributeValuesFromState(state)
  const rightsAttributes: RightsObject = {
    assetType: RightsAssetType.all,
    geoRestrictions: [],
    geoSameAsMusic: false,
    rightControl: null,
  }

  return {
    additionalArtists: [],
    additionalCredits: [],
    album: null,
    appliedToAll: {

      album: false,
      artistId: false,
      copyrightName: false,
      copyrightYear: false,
      countryOfRecording: false,
      director: false,
      explicitType: false,
      genre: false,
      isCover: false,
      label: false,
      language: false,
      lyrics: false,
      productionCompany: false,
      secondaryGenre: false,
      subcategory: false,
      videoProducer: false,
      ...mapValues(attributeValues, constant<true>(true)),
    },
    artistId: state.selectedArtistId,
    claimFromDate: null,
    clipStartTime: null,
    composers: [],
    compositionRights: { ...rightsAttributes },
    controlAllRights: false,
    copyrightName: null,
    copyrightYear: null,
    director: null,
    duration,
    error: false,
    errorReason: null,
    existingAudioMetadataId: null,
    explicitType: null,
    externalUrl,
    facebook: null,
    fileName,
    fileSize,
    genre: null,
    hasExistingAudioMetadata: null,
    instagram: null,
    isCover: false,
    isrc: null,
    iswc: null,
    label: null,
    language: null,
    lyrics: null,
    mediaType: null,
    musicVideoRights: { ...rightsAttributes },
    musicVideoType: null,
    productionCompany: null,
    removed: false,
    s3Key,
    secondaryGenre: null,
    soundExchange: null,
    soundRecordingRights: { ...rightsAttributes },
    subcategory: null,
    title,
    uploadId,
    uploadProgress: 0,
    videoCategory: DEFAULT_VIDEO_CATEGORY,
    videoIsrc: null,
    videoProducer: null,
    wasValidated: false,
    webRights: { ...rightsAttributes },
    writerCredits: [],
    youtube: null,
    ...pickBy(state.defaultMetadata),
    ...attributeValues,
  }
}

export type Reducer = GenericReducer<State>
const reducer: Reducer = (state = init(), action) => {
  switch (action.type) {
  case 'SET_SELECTED_UPLOAD_ARTIST': {
    return {
      ...state,
      selectedArtistId: action.payload.selectedArtistId,
    }
  }
  case 'SET_ACTIVE_ACCORDION': {
    return {
      ...state,
      activeAccordion: action.payload.accordion,
    }
  }
  case 'UPLOAD_MEDIA_SET_SUCCESS_PATH': {
    return {
      ...state,
      successPath: action.payload.successPath,
    }
  }
  case 'UPLOAD_MEDIA_SET_VALIDATED_FLAGS': {
    const files = state.files.map((file) => find(action.payload.files, (payloadFile) => payloadFile.uploadId === file.uploadId)
      ? {
        ...file,
        wasValidated: true,
      }
      : file)

    return {
      ...state,
      files,
    }
  }
  case 'UPLOAD_MEDIA_ADD_FILES': {
    const files = [
      ...state.files,
      ...action.payload.files.map((file) => mapNewFiles({
        file,
        state,
      })),
    ]
    return {
      ...state,
      files,
      selectedFileIndex: firstNotRemovedFileIndex(files),
    }
  }
  case 'UPLOAD_MEDIA_SYNC_UPLOADING_FILES': {
    // eslint-disable-next-line lodash/matches-shorthand
    const getUploadingFileById: (a: string) => UploadingFile | null | undefined = (id) => find(action.payload.uploadingFiles, (file) => file.id === id)

    const files = state.files.map((file) => {
      if (isNil(file.uploadId)) {
        return file
      }

      const uploadingFile: UploadingFile | null | undefined = getUploadingFileById(file.uploadId)
      const { error, errorReason, progress, s3Key } = d(uploadingFile)

      return {
        ...file,
        error,
        errorReason,
        s3Key,
        uploadProgress: progress ?? 0,
      }
    })
    return {
      ...state,
      files,
    }
  }
  case 'UPLOAD_MEDIA_ERROR': {
    return {
      ...state,
      files: [
        ...slice(state.files, 0, action.payload.fileIndex),
        // TODO: Remove 'as' type assertions because they are unsafe.
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        {
          ...state.files[action.payload.fileIndex],
          error: true,
          errorReason: action.payload.errorReason,
        } as File,
        ...slice(state.files, action.payload.fileIndex + 1),
      ],
    }
  }
  case 'UPLOAD_MEDIA_INCREMENT_FILE_INDEX': {
    return {
      ...state,
      selectedFileIndex: state.selectedFileIndex === null ? 0 : findIndex(state.files, (file, index: any) => (index > (state.selectedFileIndex ?? 0)) && !file.removed),
    }
  }
  case 'UPLOAD_MEDIA_SELECT_FILE_INDEX': {
    return {
      ...state,
      selectedFileIndex: action.payload.selectedFileIndex,
    }
  }

  case 'UPLOAD_MEDIA_UPDATE_METADATA': {
    const updatedComposers: Composer[] = ('isCover' in action.payload.fileMetadata && action.payload.fileMetadata.isCover && isEmpty(action.payload.fileMetadata.composers)
      ? [
        {
          composerId: null,
          invite: null,
          name: '',
          ownExclusiveRights: false,
        },
      ]
      : action.payload.fileMetadata.composers ?? []).map(({
      invite,
      ...composer
    }) => ({
      ...composer,
      invite: invite && composer.ownExclusiveRights === true ? false : invite,
    }))
    return {
      ...state,
      files: [
        ...slice(state.files, 0, state.selectedFileIndex ?? 0),
        // TODO: Remove 'as' type assertions because they are unsafe.
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        {
          ...action.payload.fileMetadata,
          artistId: ('artistId' in action.payload.fileMetadata && action.payload.fileMetadata.artistId) ?? state.selectedArtistId,
          composers: updatedComposers,
          compositionRights: {
            ...action.payload.fileMetadata.compositionRights,
            rightControl: 'isCover' in action.payload.fileMetadata && action.payload.fileMetadata.isCover ? false : action.payload.fileMetadata.compositionRights.rightControl,
          },

        } as File,
        ...slice(state.files, (state.selectedFileIndex ?? 0) + 1),
      ],
    }
  }
  case 'UPLOAD_MEDIA_UPDATE_APPLIED_TO_ALL': {
    return {
      ...state,
      files: [
        ...slice(state.files, 0, action.payload.fileIndex),
        // TODO: Remove 'as' type assertions because they are unsafe.
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        {
          ...state.files[action.payload.fileIndex],
          appliedToAll: {
            ...state.files[action.payload.fileIndex]?.appliedToAll,
            [action.payload.attribute]: action.payload.appliedToAll,
          },
        } as File,
        ...slice(state.files, action.payload.fileIndex + 1),
      ].map((file) => (
        // Update the other files with selectedFile attribute value
        action.payload.appliedToAll
          // TODO: Remove 'as' type assertions because they are unsafe.
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          ? {
            ...file,
            appliedToAll: tap(clone(file.appliedToAll), (appliedToAll) => {
              appliedToAll[action.payload.attribute] = action.payload.appliedToAll
            }),
            // @ts-expect-error (auto-migrated from flow FixMe)[invalid-computed-prop] flow can't tell that it will be the same attribute and so same type.
            [action.payload.attribute]: state.files[action.payload.fileIndex][action.payload.attribute],
          } as File
          : file
      )),
    }
  }
  case 'UPLOAD_MEDIA_REMOVE_FILE': {
    const files = state.files.map((file, index: number) => index === action.payload.fileIndex
      ? {
        ...file,
        removed: true,
        s3Key: undefined,
      } satisfies File
      : file)
    let { selectedFileIndex } = state

    if (selectedFileIndex === action.payload.fileIndex) {
      selectedFileIndex = firstNotRemovedFileIndex(files)
    }

    return {
      ...state,
      files,
      selectedFileIndex,
    }
  }
  case 'UPLOAD_MEDIA_REMOVE_ALL_FILES': {
    const files = state.files.map((file) => ({
      ...file,
      removed: true,
    }))
    const selectedFileIndex = firstNotRemovedFileIndex(files)

    return {
      ...state,
      files,
      selectedFileIndex,
    }
  }
  case 'UPLOAD_MEDIA_UPDATE_OWN_EXCLUSIVE_RIGHTS': {
    const { files, selectedFileIndex } = state
    let file: File | null | undefined
    if (!isNil(selectedFileIndex)) {
      file = files[selectedFileIndex]
    }
    const composers: Composer[] = file?.composers ?? []
    const ownExclusiveRights: boolean | null | undefined = action.payload.ownExclusiveRights === false ? false : undefined
    const updatedComposers: Composer[] = isEmpty(composers)
      ? [
        {
          composerId: null,
          name: '',
          ownExclusiveRights,
        },
      ]
      : composers.map((composer) => ({
        ...composer,
        ownExclusiveRights,
      }))

    return {
      ...state,
      files: [
        ...slice(files, 0, selectedFileIndex ?? 0),
        // TODO: Remove 'as' type assertions because they are unsafe.
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        {
          ...file,
          composers: updatedComposers,
        } as File,
        ...slice(files, (selectedFileIndex ?? 0) + 1),
      ],
    }
  }
  case 'UPLOAD_MEDIA_ADD_COMPOSER': {
    const { files, selectedFileIndex } = state
    let file: File | null | undefined
    if (!isNil(selectedFileIndex)) {
      file = files[selectedFileIndex]
    }
    const composers: Composer[] = file?.composers ?? []
    const ownExclusiveRights: boolean | null | undefined = file?.compositionRights && file.compositionRights.rightControl === false ? false : undefined
    const composer: Composer = {
      composerId: null,
      name: '',
      ownExclusiveRights,
    }

    return {
      ...state,
      files: [
        ...slice(files, 0, selectedFileIndex ?? 0),
        // TODO: Remove 'as' type assertions because they are unsafe.
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        {
          ...file,
          composers: [
            ...composers,
            {
              ...composer,
              ...action.payload.composer,
            },
          ],
        } as File,
        ...slice(files, (selectedFileIndex ?? 0) + 1),
      ],
    }
  }
  case 'UPLOAD_MEDIA_UPDATE_DEFAULT_METADATA': {
    const { files } = state
    return {
      ...state,
      defaultMetadata: action.payload.artistDefaultMetadata,
      files: files.map((file) => ({
        ...file,
        ...pickBy(action.payload.artistDefaultMetadata),
      })),
    }
  }
  case 'UPLOAD_MEDIA_UPDATE_FILE_WITH_DEFAULT_METADATA': {
    const { files, selectedFileIndex } = state
    if (isNil(selectedFileIndex)) {
      return state
    }
    const file = files[selectedFileIndex]
    return {
      ...state,
      files: [
        ...slice(files, 0, selectedFileIndex),
        // TODO: Remove 'as' type assertions because they are unsafe.
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        {
          ...file,
          ...pickBy(action.payload.artistDefaultMetadata),
        } as File,
        ...slice(files, selectedFileIndex + 1),
      ],
    }
  }
  case 'UPLOAD_MEDIA_RESET': {
    return init()
  }
  default: {
    return state
  }
  }
}

export const fileAppliedToAllAttributeCheckboxValue: (
  a: {
    attribute: AppliedToAllAttributesEnum,
    file: File,
    files: File[]
  }
) => AppliedToAllCheckboxValue = ({
  attribute,
  file,
  files,
}) => {
  const attributeIsAppliedToAll: (a: File) => boolean = ({ appliedToAll }) => !!appliedToAll[attribute]
  // Unchecked
  if (!attributeIsAppliedToAll(file)) {
    return false
  }
  // Checked
  if (every(files, attributeIsAppliedToAll)) {
    return true
  }
  // Indeterminate
  return null
}

export const selectedFileAppliedToAllCheckboxValues: (a: StateAppliedToAllAttributeValues) => AppliedToAllCheckboxValues | null | undefined = ({
  files,
  selectedFileIndex,
}) => {
  if (isNil(selectedFileIndex)) {
    return
  }
  const {
    [selectedFileIndex]: selectedFile,
  }: File[] = files
  if (!selectedFile) return

  // TODO: Remove 'as' type assertions because they are unsafe.
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return mapValues(selectedFile.appliedToAll, (_, attribute: AppliedToAllAttributesEnum) => fileAppliedToAllAttributeCheckboxValue({
    attribute,
    file: selectedFile,
    files,
  })) as AppliedToAllCheckboxValues
}

function firstNotRemovedFileIndex (files: File[]): number | null {
  const firstIndex: number = findIndex(files, ({ removed }) => !removed)
  return firstIndex === -1 ? null : firstIndex
}

export default reducer
