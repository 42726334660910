import banner from './Banner'
import contentList from './ContentList'
import createAlbum from './CreateAlbum'
import createAlbumRelease from './CreateAlbumRelease'
import createRelease from './CreateRelease'
import destinationPicker from './DestinationPicker'
import device from './Device'
import limitedBeta from './LimitedBeta'
import mainHeader from './MainHeader'
import mobileNavBar from './MobileNavBar'
import modal from './Modal'
import scheduleSelector from './ScheduleSelector'
import selectableItems from './SelectableItems'
import snackbar from './Snackbar'
import storageTier from './StorageTier'
import ucla from './Ucla'
import ui from './Ui'
import uploadMedia from './UploadMedia'
import validationErrors from './ValidationErrors'
import type { Reducer as BannerReducer, State as BannerState } from './Banner'
import type { BrowserLocation } from 'd2/types'
import type { Reducer as ContentListReducer, State as ContentListState } from './ContentList'
import type { Reducer as CreateAlbumReducer, State as CreateAlbumState } from './CreateAlbum'
import type { Reducer as CreateAlbumReleaseReducer, State as CreateAlbumReleaseState } from './CreateAlbumRelease'
import type { Reducer as CreateReleaseReducer, State as CreateReleaseState } from './CreateRelease'
import type { Reducer as DestinationPickerReducer, State as DestinationPickerState } from './DestinationPicker'
import type { Reducer as DeviceReducer, State as DeviceState } from './Device'
import type { State as LimitedBetaFeatureState, Reducer as LimitedBetaReducer } from './LimitedBeta'
import type { Reducer as MainHeaderReducer, State as MainHeaderState } from './MainHeader'
import type { Reducer as MobileNavBarReducer, State as MobileNavBarState } from './MobileNavBar'
import type { Reducer as ModalReducer, State as ModalState } from './Modal'
import type { Reducer as ScheduleSelectorReducer, State as ScheduleSelectorState } from './ScheduleSelector'
import type { Reducer as SelectableItemsReducer, State as SelectableItemsState } from './SelectableItems'
import type { Reducer as SnackbarReducer, State as SnackbarState } from './Snackbar'
import type { Reducer as StorageTierReducer, State as StorageTierState } from './StorageTier'
import type { Reducer as UclaReducer, State as UclaState } from './Ucla/types'
import type { Reducer as UiReducer, State as UiState } from './Ui'
import type { Reducer as UploadMediaReducer, State as UploadMediaState } from './UploadMedia'
import type { Reducer as ValidationErrorsReducer, State as ValidationErrorsState } from './ValidationErrors'

export type State = {
  banner: BannerState,
  contentList: ContentListState,
  createAlbum: CreateAlbumState,
  createAlbumRelease: CreateAlbumReleaseState,
  createRelease: CreateReleaseState,
  destinationPicker: DestinationPickerState,
  device: DeviceState,
  limitedBeta: LimitedBetaFeatureState,
  mainHeader: MainHeaderState,
  mobileNavBar: MobileNavBarState,
  modal: ModalState,
  router: {
    location: BrowserLocation
  },
  scheduleSelector: ScheduleSelectorState,
  selectableItems: SelectableItemsState,
  snackbar: SnackbarState,
  storageTier: StorageTierState,
  ucla: UclaState,
  ui: UiState,
  uploadMedia: UploadMediaState,
  validationErrors: ValidationErrorsState
}

export type Reducers = {
  banner: BannerReducer,
  contentList: ContentListReducer,
  createAlbum: CreateAlbumReducer,
  createAlbumRelease: CreateAlbumReleaseReducer,
  createRelease: CreateReleaseReducer,
  destinationPicker: DestinationPickerReducer,
  device: DeviceReducer,
  limitedBeta: LimitedBetaReducer,
  mainHeader: MainHeaderReducer,
  mobileNavBar: MobileNavBarReducer,
  modal: ModalReducer,
  scheduleSelector: ScheduleSelectorReducer,
  selectableItems: SelectableItemsReducer,
  snackbar: SnackbarReducer,
  storageTier: StorageTierReducer,
  ucla: UclaReducer,
  ui: UiReducer,
  uploadMedia: UploadMediaReducer,
  validationErrors: ValidationErrorsReducer
}

const reducers: Reducers = {
  banner,
  contentList,
  createAlbum,
  createAlbumRelease,
  createRelease,
  destinationPicker,
  device,
  limitedBeta,
  mainHeader,
  mobileNavBar,
  modal,
  scheduleSelector,
  selectableItems,
  snackbar,
  storageTier,
  ucla,
  ui,
  uploadMedia,
  validationErrors,
}

export default reducers
