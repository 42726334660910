/* eslint-disable babel/camelcase */
import { z } from 'zod'
import type { Action } from 'd2/actions'
import type { ApolloError, DocumentNode } from '@apollo/client'
import type { BrandSignInTemplate } from 'd2/queries'
import type { Colors } from 'd2/constants/colors'
import type { ElementType } from 'react'
import type { Store as GenericStore } from 'redux'
import type { Mutations, Queries } from 'd2/queries/types'
import type { Sizing } from 'd2/providers/ThemeProvider'
import type { State } from 'd2/reducers'

export type {
  DocumentNode, ApolloClient,
} from '@apollo/client'

export type { ReactDomProps } from './ReactDomProps'

export type Store = GenericStore<State, Action>

export type MixedObject = Partial<{
  [x: string]: unknown | null | undefined
}>

export type SortDirection = 'asc' | 'desc'
export const SortDirectionEnum = z.enum(['asc', 'desc'])
export const SortParams = z.object({
  sortBy: z.string(),
  sortDirection: SortDirectionEnum,
})

type PaletteMode = 'dark' | 'light'

type Color = {
  '50': string,
  '100': string,
  '200': string,
  '300': string,
  '400': string,
  '500': string,
  '600': string,
  '700': string,
  '800': string,
  '900': string,
  A100: string,
  A200: string,
  A400: string,
  A700: string,
}

export type Exact<T> = Partial<T> & T

export type BrowserLocation = {
  pathname: string,
  search: string,
  hash: string,
  state?: any,
  key?: string,
}

export type Style = unknown

export type Styles = {
  [x: string]: Style
}

export type BrandingTheme = {
  alternateBackgroundColor: string,
  applicationUrl: string | null | undefined,
  checkboxListBorder: string,
  customTermsOfUseUrl: string | null | undefined,
  danger: string,
  dangerActive: string,
  dangerHover: string,
  dashboardGraphPalette: string[],
  dashboardUrl: string | null | undefined,
  defaultTextColor: string,
  disabled: string,
  disabledText: string,
  grayText: string,
  hasOpenSignup: boolean,
  headerBackgroundColor: string,
  headerGradient: string,
  headerGradientEnd: string,
  headerGradientStart: string,
  headerTabTextColor: string,
  headerTabTextSelectedColor: string,
  headerTextColor: string,
  highlight: string,
  highlightActive: string,
  highlightHover: string,
  hoverColor: string,
  info: string,
  infoActive: string,
  infoHover: string,
  isWhitelabel: boolean,
  lightestGrayBorder: string,
  lightestGrayChart: string,
  linkActive: string,
  linkColor: string,
  linkHover: string,
  logoUrlDefault: string,
  logoUrlNoText: string,
  logoUrlSignIn: string,
  logoUrlSquare: string,
  logoUrlWhiteText: string,
  name: string,
  navLogoUrl: string,
  navOrgLinkMobileColor: string,
  primaryActive: string,
  primaryButtonTextColor: string,
  primaryColor: string,
  primaryContrastTextColor: string,
  primaryFocusBorder: string,
  primaryFontFallback: string,
  primaryFontFamily: string,
  primaryFontName: string,
  primaryFontUrl: string | null | undefined,
  primaryGradient: string,
  primaryGradientEnd: string,
  primaryGradientStart: string,
  primaryHover: string,
  primaryInvertedHover: string,
  requestInviteText: string | null | undefined,
  secondaryActive: string,
  secondaryButtonTextColor: string,
  secondaryColor: string,
  secondaryFocusBorder: string,
  secondaryFontFallback: string,
  secondaryFontFamily: string,
  secondaryFontName: string,
  secondaryFontUrl: string | null | undefined,
  secondaryGradient: string,
  secondaryGradientEnd: string,
  secondaryGradientStart: string,
  secondaryHover: string,
  signInAdditionalImageOneMaxWidthPx: number | null | undefined,
  signInAdditionalImageOneUrl: string | null | undefined,
  signInAdditionalImageTwoMaxWidthPx: number | null | undefined,
  signInAdditionalImageTwoUrl: string | null | undefined,
  signInBackgroundImageUrl: string | null | undefined,
  signInCarouselTextColor: string | null | undefined,
  signInCustomBackgroundColor: string | null | undefined,
  signInCustomTagline: string | null | undefined,
  signInGradientEnd: string | null | undefined,
  signInGradientStart: string | null | undefined,
  signInLogoMaxWidthPx: number | null | undefined,
  signInTaglineTextColor: string | null | undefined,
  signInTemplate: BrandSignInTemplate,
  success: string,
  successActive: string,
  successHover: string,
  supportUrl: string,
  tertiaryActive: string,
  tertiaryColor: string,
  tertiaryHover: string,
  userAvatarColors: string[],
  warning: string,
  warningActive: string,
  warningHover: string,
  white: string,
  whiteColor: string,
}

type FontWeightStyle = {
  fontWeight: number | 'normal'
}

export type FontWeightStyles = {
  fontBoldStyles: FontWeightStyle,
  fontExtraBoldStyles: FontWeightStyle,
  fontLightStyles: FontWeightStyle,
  fontNormalStyles: FontWeightStyle
}

type PaletteColor = {
  contrastText: string,
  dark: string,
  light: string,
  main: string
}

export type ColorObject = Color & PaletteColor & {
  contrastDefaultColor: PaletteMode,
  isDark: boolean
}

type TypeAction = {
  activatedOpacity: number,
  active: string,
  disabled: string,
  disabledBackground: string,
  disabledOpacity: number,
  focus: string,
  focusOpacity: number,
  hover: string,
  hoverOpacity: number,
  selected: string,
  selectedOpacity: number
}

export type PaletteColorOptions = {
  contrastText?: string,
  dark?: string,
  light?: string,
  main: string
}

type PaletteAugmentColorOptions = {
  color: PaletteColorOptions,
  darkShade?: number | string,
  lightShade?: number | string,
  mainShade?: number | string,
  name?: number | string
}

type TypeBackground = {
  default: string,
  paper: string
}

type CommonColors = {
  black: string,
  white: string
}

export type TypeDivider = string

type TypeText = {
  disabled: string,
  primary: string,
  secondary: string
}

export type PaletteTonalOffset = number | {
  dark: number,
  light: number
}

export type Palette = {
  action: TypeAction,
  augmentColor: (options: PaletteAugmentColorOptions) => ColorObject,
  background: TypeBackground,
  common: CommonColors,
  contrastThreshold: number,
  divider: TypeDivider,
  error: ColorObject,
  getContrastText: (background: string) => string,
  grey: Color,
  info: ColorObject,
  mode: PaletteMode,
  primary: ColorObject,
  secondary: ColorObject,
  success: ColorObject,
  text: TypeText,
  tonalOffset: PaletteTonalOffset,
  warning: ColorObject
}

export type Breakpoint = 'lg' | 'md' | 'sm' | 'xl' | 'xs'
export type BreakpointValues = {
  [k in Breakpoint]: number;
}

export interface Breakpoints {
  between: (start: Breakpoint | number, end: Breakpoint | number) => string;
  down: (key: Breakpoint | number) => string;
  keys: Breakpoint[];
  only: (key: Breakpoint) => string;
  up: (key: Breakpoint | number) => string;
  values: BreakpointValues;
  width: (key: Breakpoint) => number;
}

type CSSProperties = {
  [k: string]: CSSProperties | any
}

type Mixins = {
  toolbar: CSSProperties
  // ... use interface declaration merging to add custom mixins
}

export type Shadows = ['none', string, string, string, string, string, string, string, string, string, string, string, string, string, string, string, string, string, string, string, string, string, string, string, string]

export interface Duration {
  complex: number;
  enteringScreen: number;
  leavingScreen: number;
  short: number;
  shorter: number;
  shortest: number;
  standard: number;
}

type Easing = {
  easeIn: string,
  easeInOut: string,
  easeOut: string,
  sharp: string
}

type TransitionCreate = (
  props: string[] | string,
  options?: Partial<{
    delay: number | string,
    duration: number | string,
    easing: string
  }>
) => string

type GetAutoHeightDuration = (height: number) => number

type Transitions = {
  create: TransitionCreate,
  duration: Duration,
  easing: Easing,
  getAutoHeightDuration: GetAutoHeightDuration
}

type MuiTheme = {
  breakpoints: Breakpoints,
  // components?: Components,
  // direction: Direction,
  mixins: Mixins,
  palette: Palette,
  shadows: Shadows,
  // shape: Shape,
  // spacing: Spacing,
  transitions: Transitions
  // typography: Typography,
  // unstable_strictMode?: boolean,
  // zIndex: ZIndex,
}

export type MergedThemes = FontWeightStyles & MuiTheme & Sizing & {
  branding: BrandingTheme | undefined,
  colors: Colors,
  palette: Palette
}

export type MutationError = {
  key: string | null | undefined,
  messages: Array<string>,
  resource_id?: string | null,
  resource_type?: string | null
}

export type TransitionDurationsType = {
  transitionEnterTimeout: number,
  transitionLeaveTimeout: number
}

// definitions from http://dev.apollodata.com/core/network.html#NetworkInterface

// TODO: Tighten lint & flow

export type GraphQlRequest = {
  operationName: Mutations | Queries,
  variables: any
}

export type GraphQlResponse = {
  data: any,
  errors: ApolloError[]
}

export type GraphQlMutationResult<TData> = {
  data: TData
} // TODO: Use MutationResult from @apollo/client/react/components instead

export type GraphQlReducedMutation<TInput, TData> = (input: TInput) => Promise<GraphQlMutationResult<TData>>

export type FetchPolicy = 'cache-and-network' | 'cache-first' | 'cache-only' | 'network-only' | 'no-cache' | 'standby'

export type GraphQlQueryOptions<TVariables> = {
  fetchPolicy?: FetchPolicy,
  pollInterval?: number,
  query?: DocumentNode,
  skip?: boolean,
  ssr?: boolean,
  variables?: TVariables
}

// TODO: What is the purpose of this custom `Component` type?
type Component<A> = React$ComponentType<A & UO>

export type HOC<Base, Enhanced> = (a: Component<Enhanced>) => Component<Base>

export type FramerProps = {
  animate?: any | string,
  component?: ElementType,
  exit?: any | string,
  initial?: any | string,
  variants?: any
}
