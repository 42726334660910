import type { GenericReducer } from '../types'

export type State = {
  allContentSelected: boolean
}

const init: () => State = () => ({
  allContentSelected: false,
})

export type Reducer = GenericReducer<State>
const reducer: Reducer = (state = init(), action) => {
  switch (action.type) {
  case 'SELECT_ALL_CONTENT': {
    return {
      ...state,
      allContentSelected: true,
    }
  }
  case 'CANCEL_SELECT_ALL_CONTENT': {
    return {
      ...state,
      allContentSelected: false,
    }
  }
  default: {
    return state
  }
  }
}

export default reducer
