import { FONT_SIZE_DEFAULT } from 'd2/constants/fonts'
import { SPACING_HALF } from 'd2/constants'
import { allLinkStates, px } from 'd2/utils/style'
import { screenSmMax } from 'd2/utils/breakpoints'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

const styles = (
  {
    fontNormalStyles,
    ...theme
  }: MergedThemes,
) => ({
  blue: {
    color: theme.branding?.primaryColor,
  },
  button: {
    ...fontNormalStyles,
    ...allLinkStates({
      backgroundColor: theme.colors.transparent,
    }),
    boxShadow: 'none',
    fontSize: px(FONT_SIZE_DEFAULT),
    minHeight: 'auto',
    minWidth: 'auto',
    padding: 0,
    textTransform: 'capitalize',
  },
  icon: {
    marginLeft: px(SPACING_HALF),
    [`@media (max-width: ${screenSmMax}px)`]: {
      marginLeft: px(0),
    },
  },
  textPrimary: {
    color: theme.palette.text.primary,
  },
  white: {
    color: theme.palette.common.white,
  },
} as const)
export default makeStyles({
  name: 'DismissButton',
})(styles)
