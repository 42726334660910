import makeStyles from 'd2/hooks/tss/makeStyles'

const styles = () => ({
  checkbox: {
    alignItems: 'end',
  },
} as const)

export default makeStyles({
  name: 'SecuritySettings',
})(styles)
