import { gql } from '@apollo/client'
import { useQuerySwitch } from 'd2/hooks/useQuerySwitch'
import type { ReducerArg } from 'd2/hooks/useQuerySwitch'
import type { storageConsumptionProgressBar } from 'd2/queries'

const reducer = ({
  data,
  error,
  loading,
}: ReducerArg<storageConsumptionProgressBar>) => ({
  ...data,
  error,
  loading,
})
export const useQuery = () => useQuerySwitch<storageConsumptionProgressBar, EO, typeof reducer>(gql`
  query storageConsumptionProgressBar {
    me {
      id
      current_storage_usage
      active_tier_permission {
        id
        size_in_bytes
      }
    }
  }
`, {
  reducer,
})
