import { FONT_SIZE_EXTRA_LARGE } from 'd2/constants/fonts'
import {
  MAX,
  SNACKBAR_WIDTH,
  SPACING,
  SPACING_QUARTER,
} from 'd2/constants'
import { OVERLAY_Z_INDEX } from 'd2/constants/zIndexes'
import { allLinkStates, px } from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

const TAG_WIDTH = 5

const styles = (theme: MergedThemes) => ({
  action: {
    marginLeft: px(SPACING),
    maxWidth: 'max-content',
  },
  content: {
    backgroundColor: `${theme.palette.background.paper} !important`,
    display: 'inherit',
    fontWeight: 300,
  },
  error: {
    backgroundColor: theme.branding?.danger,
  },
  heading: {
    color: theme.palette.text.primary,
    fontSize: FONT_SIZE_EXTRA_LARGE,
    marginBottom: px(SPACING_QUARTER),
  },
  info: {
    backgroundColor: theme.branding?.info,
  },
  message: {
    ...allLinkStates({
      color: theme.palette.text.primary,
    }),
  },
  messageContainer: {
    alignItems: 'center',
    display: 'flex',
    padding: 0,
    width: MAX,
  },
  messageRoot: {
    borderRadius: 0,
    padding: px(SPACING),
    width: '100%',
  },
  messageWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  snackbar: {
    bottom: px(SPACING),
    maxWidth: px(SNACKBAR_WIDTH),
    width: '100%',
    zIndex: `${OVERLAY_Z_INDEX + 1} !important`,
  },
  success: {
    backgroundColor: theme.branding?.success,
  },
  tag: {
    height: MAX,
    left: 0,
    position: 'absolute',
    width: px(TAG_WIDTH),
  },
} as const)

export default makeStyles({
  name: 'SnackbarNotification',
})(styles)
