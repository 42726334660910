import { MAX, SPACING } from 'd2/constants'
import { MergedThemes } from 'd2/types'
import { OVERLAY_Z_INDEX } from 'd2/constants/zIndexes'
import makeStyles from 'd2/hooks/tss/makeStyles'

type StylesProps = { innerHeight: string | undefined }

const styles = (_theme: MergedThemes, { innerHeight }: StylesProps) => ({
  container: {
    bottom: 0,
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    whiteSpace: 'initial', /* Reset anything the parent might have set */
    zIndex: OVERLAY_Z_INDEX,
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    height: MAX,
    overflow: 'scroll',
    position: 'absolute',
    width: MAX,
  },
  inner: {
    flex: 1,
    height: innerHeight ?? '',
    maxHeight: innerHeight ? '' : MAX,
    overflow: 'inherit',
    paddingBottom: SPACING,
    position: 'relative',
  },
} as const)

export default makeStyles<StylesProps>({
  name: 'Overlay',
})(styles)
