import { forwardRef, memo } from 'react'
import Base from '../base'
import cx from 'classnames'
import useStyles from './styles'
import type { SizedButtonProps } from './types'

const SizedButton = memo<SizedButtonProps>(forwardRef(({
  align,
  className,
  maxWidth,
  noPadding,
  rounded,
  size,
  ...props
}, ref) => {
  const { classes } = useStyles({
    align,
    maxWidth,
    noPadding,
    rounded,
    size,
  })

  return (
    <Base
      {...props}
      className={cx(
        classes.alignContent,
        classes.buttonSize,
        {
          [classes.fullWidth]: maxWidth,
        },
        className,
      )}
      ref={ref}
    />
  )
},
))

SizedButton.displayName = 'SizedButton'

export default SizedButton
