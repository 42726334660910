import { Timer } from 'react-timeout'
import {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  find,
  isMatch,
  isNil,
  noop,
  some,
} from 'lodash-es'
import type {
  FormContext,
  FormError,
  FormErrorUpdate,
  FormValues,
  MutationError,
} from './types'

// using context allows <FieldValue> to access form data with a simple API
// TODO: Remove 'as' type assertions because they are unsafe.
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const FormValueContext: React$Context<FormContext<FormValues>> = createContext({
  disableHtml5Required: true,
  errors: [],
  formDisabled: false,
  formErrors: [],
  formTestID: null,
  formValue: null,
  getErrorMessage: null,
  onFormChange: noop,
  updateError: noop,
} as FormContext<FormValues>)

const {
  Consumer: FormValueContextConsumer,
  Provider: FormValueContextProvider,
} = FormValueContext

export function useFormValue<FormValue> (): FormContext<FormValue> {
  return useContext(FormValueContext)
}

export function useOnce<TReturn> (onetimeCallback: () => TReturn): TReturn {
  const ref = useRef<[TReturn] | undefined>()
  let result

  if (ref.current) {
    result = ref.current[0]
  } else {
    result = onetimeCallback()
    ref.current = [result]
  }

  return result
}

// this hook handles the 'wait for gql data and THEN set default form values'
// If you don't depend on async data, you don't really need this hook but it won't hurt anything to use it anyway.
// This should be a very easy drop-in replacement hook to replace formValues() HOCs.
export function useFormState<TFormValues> (initialValue: () => TFormValues): [TFormValues, (formValues: TFormValues) => void] {
  const [formValues, setFormValues] = useState<TFormValues>(useOnce(initialValue))

  // when initialState changes from returning undefined to any value, set the initial state
  useLayoutEffect(() => {
    if (!isNil(formValues)) return

    const initialState = initialValue()

    if (!isNil(initialState)) {
      setFormValues(initialState)
    }
  }, [formValues, initialValue])

  return [formValues, setFormValues]
}

/*
getErrorMessage: a function that can be provided to resolve error messages specific to a mutation
children: normal React children, or a render function that receives the current errors in the form
disableHtml5Required: A flag that will avoid using the html 5 browser required functionality. Ultimately we want disabled for all forms, but until we do use this to disable the native required (https://trello.com/c/mo5Xf1Fd)
onChange: Note the promise won't be awaited. this is just for Flow when executing mutations in the submit handler
*/
export type Props = {
  children: React$Node | ((
    a: {
      errors: FormError[],
      hasVisibleErrors: (section?: string | null) => boolean
    }
  ) => React$Node),
  disabled?: boolean,
  disableHtml5Required?: boolean,
  errors?: MutationError[] | null,
  getErrorMessage?: (messageOrKey: string) => string | null | undefined,
  loading?: boolean,
  onChange: (a: FormValues) => any, // Used to be Promise<void> | void but useUrlQueryParam setter returns newParams and not void.
  onSubmit?: () => Promise<void> | void,
  onValidationErrorsChange?: (a: FormError[]) => void,
  stopPropagation?: boolean,
  testID?: string,
  value: FormValues | null | undefined
}

// a utility function to get a unique 'path' for an error, which includes it key and resource id/types. this is used for some internal form code
const mutationErrorPath = (error: any): string => `${error.key || ''}_${error.resource_id || ''}_${error.resource_type || ''}`

const mapMutationErrorsToValidationErrors = (errors?: Array<MutationError> | null): FormError[] => (errors ?? []).map((error) => ({
  ...error,
  active: false,
  matched: false,
  sectionName: null,
  visible: false,
  visited: false,
}))

const Form = memo<Props>(({
  children,
  disableHtml5Required = true,
  disabled,
  errors: mutationErrors,
  getErrorMessage,
  onChange,
  onSubmit: onSubmitProp,
  onValidationErrorsChange,
  stopPropagation,
  testID,
  value,
}) => {
  const validationErrors = useRef<FormError[]>(mapMutationErrorsToValidationErrors(mutationErrors))

  // Errors are stored as a ref and not state because they are manipulated in batches using a timer.
  // But once they are updated, the form needs to be re-rendered in order to pass the updated errors down.
  // This state hook basically acts as a way to ensure a rerender so descendants get the proper errors
  const [validationErrorsFromState, forceUpdateForErrors] = useState<FormError[]>(validationErrors.current)
  const updateErrors: (a: FormError[]) => void = useCallback((errors) => {
    if (onValidationErrorsChange) {
      onValidationErrorsChange(errors)
    }
    forceUpdateForErrors(errors)
  }, [forceUpdateForErrors, onValidationErrorsChange])

  // when the errors change in props, we have to update the state to keep everything in sync. See /d2/examples/Form/FormSections for an example
  useEffect(() => {
    validationErrors.current = mapMutationErrorsToValidationErrors(mutationErrors)
    updateErrors(validationErrors.current)
  }, [mutationErrors, updateErrors])

  // Fields call updateError on the context when they first show an error.
  // This will happen for each error when it first renders. If we updated the state each time updateError
  // was called, the form would re-render each time, which causes a UX lag. Even worse, it didn't even update the state properly when it happened all at once.
  // The fix was to queue up error updates and update all errors in state at once after a brief timeout. This sort of thing should only
  // ever be done if completely necessary. This is a strange situation, so it's warranted here.
  const updateErrorTimeoutId = useRef<Timer>(-1)
  const updateError = useCallback((currError: FormError, errorUpdates: FormErrorUpdate) => {
    const errorKey = mutationErrorPath(currError)
    // sometimes unnecessary updates were happening, mostly when useFieldValue hook's internal useEffect did multiple unsubscribes
    // avoiding the update prevents potentially unnecessary re-renders
    const matchingError = find(validationErrors.current, (error) => mutationErrorPath(error) === errorKey)
    if (matchingError && isMatch(matchingError, errorUpdates)) {
      return
    }
    // update the current error
    validationErrors.current = validationErrors.current.map((error) => {
      if (mutationErrorPath(error) === errorKey) {
        return {
          ...error,
          ...errorUpdates,
        }
      }
      return error
    })
    // waiting on timer, errors will be updated shortly
    if (updateErrorTimeoutId.current !== -1) {
      return
    }
    // you may want to switch to something a bit more elegant. this basically just prevents a bunch of rerenders and `onValidationErrorsChange` when
    // errors are registered. Check out npm use-timeout for something a bit like this (that one wasn't suited for this though)
    updateErrorTimeoutId.current = setTimeout(() => {
      updateErrorTimeoutId.current = -1
      updateErrors(validationErrors.current)
    }, 1)
  }, [updateErrors])

  const childrenMemo = useMemo(
    () => {
      // This is a bit of a hack. We need children memo to update when errors change,
      // but exhaustiveDeps doesn't want refs to be included in the dependency list.
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const errors = validationErrors.current || validationErrorsFromState
      return typeof children === 'function'
        ? children({
          errors,
          hasVisibleErrors: (sectionName) => some(errors, (error) => error.visible && (!sectionName || error.sectionName === sectionName)),
        })
        : children
    },
    [children, validationErrorsFromState],
  )

  return (
    <form
      method='post'
      onSubmit={useCallback(
        (event: any) => {
          if (stopPropagation) event.stopPropagation()
          event.preventDefault() // these forms are designed to be used with graphql mutations (aka JS only aka AJAX) so don't do a default submission
          if (disabled) return // prevent double submission
          onSubmitProp?.()
        }, [disabled, onSubmitProp, stopPropagation],
      )}
    >
      <FormValueContextProvider
        value={useMemo(() => ({
          disableHtml5Required: !!disableHtml5Required,
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          errors: validationErrorsFromState || validationErrors.current,
          formDisabled: !!disabled,
          formErrors: mutationErrors,
          formTestID: testID,
          formValue: value,
          getErrorMessage,
          onFormChange: onChange,
          updateError,
        }), [
          disableHtml5Required,
          disabled,
          getErrorMessage,
          mutationErrors,
          onChange,
          testID,
          updateError,
          validationErrorsFromState,
          value,
        ])}
      >
        { childrenMemo }
      </FormValueContextProvider>
    </form>
  )
})

Form.displayName = 'Form'

export default Form

export { FormValueContext, FormValueContextProvider, FormValueContextConsumer }
