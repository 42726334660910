import type { GenericReducer } from '../types'

export type State = {
  artworkImagePath: string,
  imageThumbnailUrl: string,
  selectedArtistId: string,
  selectedMediaId: string
}

const init: () => State = () => ({
  artworkImagePath: '',
  imageThumbnailUrl: '',
  selectedArtistId: '',
  selectedMediaId: '',
})

export type Reducer = GenericReducer<State>
const reducer: Reducer = (state = init(), action) => {
  switch (action.type) {
  case 'CREATE_ALBUM_ADD_IMAGE_PATH': {
    return {
      ...state,
      artworkImagePath: action.payload.artworkImagePath,
    }
  }
  case 'CREATE_ALBUM_ADD_THUMBNAIL_URL': {
    return {
      ...state,
      imageThumbnailUrl: action.payload.imageThumbnailUrl,
    }
  }
  case 'CREATE_ALBUM_ADD_SELECTED_MEDIA': {
    return {
      ...state,
      selectedMediaId: action.payload.mediaId,
    }
  }
  case 'CREATE_ALBUM_SELECT_ARTIST': {
    return {
      ...state,
      selectedArtistId: action.payload.selectedArtistId,
    }
  }
  case 'CREATE_ALBUM_RESET': {
    return init()
  }
  default: {
    return state
  }
  }
}

export default reducer
