import { hideSnackbar as hideSnackbarAction, showSnackbar as showSnackbarAction } from 'd2/actions'
import { useDispatch } from 'react-redux'
import { useMemo } from 'react'
import type { Dispatch } from 'd2/actions'
import type { Payload } from 'd2/reducers/Snackbar'

export type HideSnackbar = () => void
export type ShowGenericErrorSnackbar = () => void
export type ShowSnackbar = (a: Payload) => void

type UseSnackbarResult = {
  hideSnackbar: HideSnackbar,
  showGenericErrorSnackbar: ShowGenericErrorSnackbar,
  showSnackbar: ShowSnackbar
}

export default function useSnackbar (): UseSnackbarResult {
  const dispatch = useDispatch<Dispatch>()

  return useMemo<UseSnackbarResult>(() => ({
    hideSnackbar: () => {
      dispatch(hideSnackbarAction())
    },
    showGenericErrorSnackbar: () => {
      dispatch(showSnackbarAction({
        message: 'Oops! Something went wrong. Try again in a little while.', // TODO: useTranslations
        type: 'error',
      }))
    },
    showSnackbar: (props) => {
      dispatch(showSnackbarAction(props))
    },
  }), [dispatch])
}
