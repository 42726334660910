import {
  SyntheticEvent,
  memo,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { d } from 'd2/utils'
import { forwardProps } from 'd2/utils/props'
import { throttle } from 'lodash-es'
import { useIsMobile } from '../Responsive'
import useStyles from './styles'
import type { ReactDomProps } from 'd2/types'

type OwnProps = ReactDomProps & {
  bordered?: boolean | null,
  fillHeight?: boolean | null,
  fillWidth?: boolean | null,
  height?: string,
  innerClassName?: string | null,
  maxHeight?: string,
  onScroll?: (a: SyntheticEvent) => void,
  onScrollPercent?: (a: number) => void,
  scrollToBottomDependency?: any | null
}

type Props = OwnProps

const THROTTLE_SCROLL = 100

const ScrollableContainerInternal = memo<Props>(({
  bordered,
  children,
  className,
  fillHeight,
  fillWidth,
  height,
  innerClassName,
  maxHeight,
  onScroll,
  onScrollPercent,
  scrollToBottomDependency,
  ...props
}) => {
  const innerRef = useRef<HTMLDivElement | null>(null)
  const { classes, cx } = useStyles({
    height,
    maxHeight,
  })

  const [atBottom, setAtBottom] = useState<boolean>(false)
  const [atTop, setAtTop] = useState<boolean>(false)

  const handleScroll = useMemo(() => throttle(() => {
    if (!innerRef.current) return
    const { scrollHeight, scrollTop } = d(innerRef.current)
    setAtBottom(scrollHeight - scrollTop === innerRef.current.clientHeight)
    setAtTop(scrollTop === 0)
  }, THROTTLE_SCROLL), [innerRef])

  useLayoutEffect(() => {
    const inner = innerRef.current
    inner?.addEventListener('scroll', handleScroll)
    handleScroll()
    return () => {
      handleScroll.cancel()
      inner?.removeEventListener('scroll', handleScroll)
    }
  }, [innerRef, handleScroll])

  useEffect(() => {
    if (!scrollToBottomDependency || !innerRef.current) return
    const { scrollHeight } = innerRef.current
    innerRef.current.scrollTop = scrollHeight
  }, [innerRef, scrollToBottomDependency])

  return (
    <div
      className={cx(classes.container, {
        [classes.containerShadowTop]: !atTop,
        [classes.containerShadowBottom]: !atBottom,
        [classes.border]: bordered,
        [classes.fillHeight]: fillHeight,
      }, className)}
      {...forwardProps(props)}
    >
      <div
        className={cx(classes.inner, innerClassName, {
          [classes.fillHeight]: fillHeight,
          [classes.fillWidth]: fillWidth,
        })}
        onScroll={(onScroll || onScrollPercent) && ((event: SyntheticEvent) => {
          onScroll?.(event)
          // @ts-expect-error (auto-migrated from flow FixMe)[prop-missing] - Cannot get event.target.clientHeight because property clientHeight is missing in EventTarget
          onScrollPercent?.((event.target.scrollTop + event.target.clientHeight) * 100 / event.target.scrollHeight)
        })}
        ref={(inner) => {
          innerRef.current = inner
        }}
      >
        { children }
      </div>
    </div>
  )
})

ScrollableContainerInternal.displayName = 'ScrollableContainerInternal'

const ScrollableContainer = memo<Props & { mobile?: boolean }>(({
  children,
  mobile = true,
  ...props
}) => {
  const isMobile = useIsMobile()
  if (!mobile && isMobile) {
    return (
      <div>
        { children }
      </div>
    )
  }

  return (
    <ScrollableContainerInternal
      {...props}
    >
      { children }
    </ScrollableContainerInternal>
  )
})

ScrollableContainer.displayName = 'ScrollableContainer'

export default ScrollableContainer
