import {
  memo,
  useCallback,
  useMemo,
} from 'react'
import { omit } from 'lodash-es'
import FormControlLabel from '@mui/material/FormControlLabel'
// eslint-disable-next-line consistent-default-export-name/default-import-match-filename
import MaterialSwitch from '@mui/material/Switch'
import useStyles from './styles'
import type { Props } from './types'

const Switch = memo<Props>(({
  checked,
  className,
  disabled = false,
  label,
  labelPlacement = 'start',
  onChange,
  secondary,
  testID,
  ...props
}) => {
  const { classes } = useStyles({
    checked,
    labelPlacement,
    secondary,
  })

  const control = useMemo(() => (<MaterialSwitch
    {...omit(props, 'classes')}
    classes={
      {
        checked: classes.checked,
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
      }
    }
    className={className}
    disableRipple
    focusVisibleClassName={classes.focusVisible}
  />), [classes, props, className])

  const handleOnChange = useCallback((event: any) => {
    onChange(event.target.checked)
  }, [onChange])

  return (<FormControlLabel
    checked={checked}
    className={classes.label}
    control={control}
    disabled={disabled}
    label={label}
    labelPlacement={labelPlacement}
    onChange={handleOnChange}
    {...(testID ? { 'data-test-id': testID } : {})}
    value={false}
  />)
})

Switch.displayName = 'Switch'

export default Switch
