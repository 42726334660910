import { isNil } from 'lodash-es'
import { memo } from 'react'
import DismissButton from 'd2/components/DismissButton'
import OutlineButton from 'd2/components/ButtonV2/OutlineButton'
import useStyles from './styles'
import type { Props } from './types'

const FlashMessageBanner = memo<Props>(({
  actionText,
  actionUrl,
  bannerId,
  bannerType,
  d1,
  message,
  onDismiss,
}) => {
  const { classes, cx } = useStyles()

  return (
    <div
      className={cx(classes.banner, { [classes.bannerD1]: d1 }, classes[bannerType])}
      id={bannerId}
    >
      <div className={cx(classes.messageActionWrapper, { [classes.wrapperWithDismissButton]: !isNil(onDismiss) })}>
        <span>
          { message }
        </span>
        { actionText && actionUrl && <div className={classes.buttonWrapper}>
          <OutlineButton
            className={classes.button}
            color='white'
            href={actionUrl}
            id={`${bannerId}-btn`}
            rounded
            size='small'
            testID={`${bannerId}-button`}
          >
            { actionText }
          </OutlineButton>
        </div> }
      </div>
      { onDismiss && <DismissButton
        className={classes.dismissButton}
        hasButtonText
        onDismiss={onDismiss}
      /> }
    </div>
  )
})

FlashMessageBanner.displayName = 'FlashMessageBanner'

export default FlashMessageBanner
