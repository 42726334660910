import { SyntheticEvent, memo } from 'react'
import { assetUri } from 'd2/utils/Routes'
import { screenMdMax } from 'd2/utils/breakpoints'
import BlankButton from 'd2/components/ButtonV2/BlankButton'
import FaIcon from 'd2/components/FaIcon'
import MediaQuery from 'd2/components/MediaQuery'
import useStyles from './styles'
import useTranslations from './translations'

type OwnProps = {
  className?: string,
  hasButtonText?: boolean,

  onDismiss: (event: Event | React.SyntheticEvent<any>) => void,
  variant?: 'blue' | 'textPrimary' | 'white',
  testID?: string
}

type Props = OwnProps

const FALLBACK = 'white'

const DismissButton = memo<Props>(({
  className,
  hasButtonText,
  onDismiss,
  variant,
}) => {
  const { classes, cx } = useStyles()
  const t = useTranslations()

  return (
    <BlankButton
      className={cx(
        classes.button,
        className,
        classes[variant ?? FALLBACK],
      )}
      disableRipple={!!variant}
      onClick={onDismiss}
    >
      {
        hasButtonText && <MediaQuery minWidth={screenMdMax}>
          { t.buttonText }
        </MediaQuery>
      }
      { variant
        ? <FaIcon
          icon='times'
          weight='regular'
        />
        : <img
          alt={t.imgAlt}
          className={classes.icon}
          src={assetUri('/images/icons/dismiss-icon.svg')}
        /> }
    </BlankButton>
  )
})

DismissButton.displayName = 'DismissButton'

export default DismissButton
