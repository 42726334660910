import { OTPInputProps } from './types'
import {
  fill,
  split,
  toPairs,
  trim,
} from 'lodash-es'
import {
  memo,
  useCallback,
  useState,
} from 'react'
import SingleOTPInput from './SingleOTPInput'
import useStyles from './styles'

export function OTPInputComponent (props: OTPInputProps) {
  const {
    autoFocus,
    disabled,
    isNumberInput,
    length,
    onChangeOTP,
    onPaste,
    ...rest
  } = props

  const { classes } = useStyles()
  // Define state activeInput = 0
  const [activeInput, setActiveInput] = useState(0)

  // Define state otpValues = array with <length> items with default value = ""
  const [otpValues, setOTPValues] = useState(fill(Array<string>(length), ''))

  // Helper to return value with the right type: 'text' or 'number'
  const getRightValue = useCallback(
    (str: string) => {
      const changedValue = str

      if (!isNumberInput || !changedValue) {
        return changedValue
      }

      return Number(changedValue) >= 0 ? changedValue : ''
    },
    [isNumberInput],
  )

  // Helper to return OTP from inputs
  const handleOtpChange = useCallback(
    (otp: string[]) => {
      const otpValue = otp.join('')
      onChangeOTP(otpValue)
    },
    [onChangeOTP],
  )

  // Change OTP value at focussing input
  const changeCodeAtFocus = useCallback(
    (str: string) => {
      const updatedOTPValues = [...otpValues]
      updatedOTPValues[activeInput] = str[0] ?? ''
      setOTPValues(updatedOTPValues)
      handleOtpChange(updatedOTPValues)
    },
    [activeInput, handleOtpChange, otpValues],
  )

  // Focus `inputIndex` input
  const focusInput = useCallback(
    (inputIndex: number) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0)
      setActiveInput(selectedIndex)
    },
    [length],
  )

  const handleOnFocus = useCallback(
    (index: number) => () => {
      focusInput(index)
    },
    [focusInput],
  )

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1)
  }, [activeInput, focusInput])

  // Handle onChange value for each input
  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const val = getRightValue(event.currentTarget.value)
      if (!val) {
        event.preventDefault()
        return
      }
      changeCodeAtFocus(val)
      focusNextInput()
    },
    [changeCodeAtFocus, focusNextInput, getRightValue],
  )

  const handleOnBlur = useCallback(() => {
    setActiveInput(-1)
  }, [])

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1)
  }, [activeInput, focusInput])

  const handleOnKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      const pressedKey = event.key

      switch (pressedKey) {
      case 'Backspace':
      case 'Delete': {
        event.preventDefault()
        if (otpValues[activeInput]) {
          changeCodeAtFocus('')
        } else {
          focusPrevInput()
        }
        break
      }
      case 'ArrowLeft': {
        event.preventDefault()
        focusPrevInput()
        break
      }
      case 'ArrowRight': {
        event.preventDefault()
        focusNextInput()
        break
      }
      case 'Enter': {
        event.preventDefault()
        onPaste?.(otpValues.join(''))
        break
      }
      default: {
        // Ignore all special keys if it is not numeric or alphabet characters
        if (/^[^\dA-Za-z]$/.test(pressedKey)) {
          event.preventDefault()
        }

        break
      }
      }
    },
    [
      activeInput,
      changeCodeAtFocus,
      focusNextInput,
      focusPrevInput,
      otpValues,
      onPaste,
    ],
  )

  const handleOnPaste = useCallback(
    (event: React.ClipboardEvent) => {
      event.preventDefault()

      const trimmedPastedData = trim(event.clipboardData
        .getData('text/plain'),
      )
      const pastedData = split(trimmedPastedData
        .slice(0, length - activeInput), '')

      let nextFocusIndex = 0
      const updatedOTPValues = [...otpValues]
      for (const [index, val] of toPairs(updatedOTPValues)) {
        if (Number(index) >= Number(activeInput)) {
          const changedValue = getRightValue(pastedData.shift() ?? val)
          if (changedValue) {
            updatedOTPValues[Number(index)] = changedValue
            nextFocusIndex = Number(index)
          }
        }
      }
      setOTPValues(updatedOTPValues)
      onChangeOTP(updatedOTPValues.join(''))
      setActiveInput(Math.min(nextFocusIndex + 1, length - 1))
      onPaste?.(updatedOTPValues.join(''))
    },
    [
      activeInput,
      getRightValue,
      onChangeOTP,
      length,
      onPaste,
      otpValues,
    ],
  )

  return (
    <div
      {...rest}
      className={classes.container}
    >
      <input
        autoComplete="one-time-code"
        inputMode="numeric"
        onChange={handleOnChange}
        pattern="\d{6}"
        required
        type="hidden"
        value={otpValues.join('')}
      />
      { fill(Array(length), '')
        .map((_, index) => (
          <SingleOTPInput
            autoFocus={autoFocus} // eslint-disable-line jsx-a11y/no-autofocus
            disabled={disabled}
            focus={activeInput === index}
            key={index} // eslint-disable-line react/no-array-index-key
            onBlur={handleOnBlur}
            onChange={handleOnChange}
            onFocus={handleOnFocus(index)}
            onKeyDown={handleOnKeyDown}
            onPaste={handleOnPaste}
            value={otpValues[index]}
          />
        )) }
    </div>
  )
}

const OTPInput = memo(OTPInputComponent)
export default OTPInput
