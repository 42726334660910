import { FONT_SIZE_EXTRA_LARGE, FONT_SIZE_SMALL } from 'd2/constants/fonts'
import {
  MAX,
  SPACING,
  SPACING_DOUBLE,
  SPACING_HALF,
  SPACING_QUADRUPLE,
  SPACING_QUARTER,
} from 'd2/constants'
import { MergedThemes } from 'd2/types'
import { px } from 'd2/utils/style'
import { screenXsMax } from 'd2/utils/breakpoints'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { SizedButtonProps } from './types'

const MIN_BUTTON_LARGE_WIDTH = 120

const styles = (_theme: MergedThemes, { align, maxWidth, noPadding, rounded, size }: SizedButtonProps) => ({
  alignContent: {
    alignItems: 'center',
    justifyContent: align ? align === 'left' ? 'flex-start' : 'flex-end' : 'center',
  },
  buttonSize: {
    ...size === 'large'
      ? {
        borderRadius: rounded ? px(SPACING_QUADRUPLE) : 0,
        fontSize: px(FONT_SIZE_EXTRA_LARGE),
        minWidth: px(MIN_BUTTON_LARGE_WIDTH),
        padding: noPadding ? 0 : `${px(SPACING)} ${px(SPACING_DOUBLE)}`,
        [`@media (max-width: ${screenXsMax}px)`]: {
          padding: noPadding ? 0 : `${px(SPACING_HALF)} ${px(SPACING)}`,
        },
      }
      : size === 'medium'
        ? {
          borderRadius: rounded ? px(SPACING_DOUBLE) : 0,
          fontSize: px(FONT_SIZE_EXTRA_LARGE),
          padding: noPadding ? 0 : `${px(SPACING_HALF)} ${px(SPACING)}`,
        }
        : size === 'small'
          ? {
            borderRadius: rounded ? px(SPACING) : 0,
            padding: noPadding ? 0 : `${px(SPACING_QUARTER)} ${px(SPACING_HALF)}`,
          }
          : size === 'xsmall'
            ? {
              borderRadius: rounded ? px(SPACING) : 0,
              fontSize: px(FONT_SIZE_SMALL),
              padding: noPadding ? 0 : `${px(SPACING_QUARTER)} ${px(SPACING_HALF)}`,
            }
            : {
              borderRadius: rounded ? px(SPACING_DOUBLE) : 0,
              fontSize: px(FONT_SIZE_EXTRA_LARGE),
              padding: noPadding ? 0 : `${px(SPACING_HALF)} ${px(SPACING)}`,
            },
  },
  fullWidth: {
    width: maxWidth ? MAX : undefined,
  },
} as const)

export default makeStyles<SizedButtonProps>({
  name: 'SizedButton',
})(styles)
