/*
Use this when you need to compare the 'current' value of a variable to its previous value from
the last time the component was rendered within useEffect(), like
```
    const {receiveAmount, sendAmount } = props
    const prevAmount = usePrevious({receiveAmount, sendAmount});
    useEffect(() => {
        if(prevAmount.receiveAmount !== receiveAmount) {

         // process here
        }
        if(prevAmount.sendAmount !== sendAmount) {

         // process here
        }
    }, [receiveAmount, sendAmount])
```
Thanks to https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
"It’s possible that in the future React will provide a usePrevious Hook out of the box since it’s a relatively common use case."
*/
import { useEffect, useRef } from 'react'

export default function usePrevious<TValue> (value: TValue): TValue | null | undefined {
  const ref = useRef<TValue | null | undefined>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
