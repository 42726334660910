import { MAX } from 'd2/constants'
import { styles as checkboxFieldStyles } from 'd2/components/Form/CheckboxField/styles'
import { px } from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

type StylesProps = { height?: number | undefined }

const styles = (theme: MergedThemes, { height }: StylesProps) => ({
  ...checkboxFieldStyles(theme),
  bordered: {
    border: `${px(1)} solid ${theme.branding?.checkboxListBorder ?? theme.colors.transparent}`,
  },
  labelSpan: {
    minWidth: 0,
  },
  listHeight: {
    height,
  },
  listPadding: {
    padding: 0,
  },
  noPaddingLeft: {
    paddingLeft: 0,
  },
  overflow: {
    overflow: 'hidden hidden',
  },
  twoColumns: {
    display: 'flex',
    flexWrap: 'wrap',
    width: MAX,
  },
  twoColumnsItem: {
    width: '50%',
  },
} as const)
export default makeStyles<StylesProps>({
  name: 'CheckboxList',
})(styles)
