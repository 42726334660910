/* eslint-disable @typescript-eslint/no-shadow */
// eslint-disable-next-line consistent-default-export-name/default-export-match-filename
import { createPortal } from 'react-dom'
import { m } from 'framer-motion'
import {
  memo,
  useCallback,
  useMemo,
  useRef,
} from 'react'
import BodyClass from 'd2/hocs/BodyClass'
import DocumentOn from '../DocumentOn'
import useStyles from './styles'
import type { Props } from './types'

const ESCAPE_KEY = 27

export const Overlay = memo<Props>(({
  alpha = 0.7,
  children,
  className,
  contentKey,
  innerHeight,
  light,
  onClickAway,
  rootProps,
  wrapContentWith,
}) => {
  const { classes, cx } = useStyles({ innerHeight })
  const inner = useRef<Element | null | undefined>(null)

  const handleContentClick = useCallback<NonNullable<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>['onClick']>>((event) => {
    if (!onClickAway) {
      return
    }

    const clickableArea: Element | null | undefined = inner.current?.querySelectorAll('.modal-clickable-area')[0]
    if (!clickableArea || !(event.target instanceof Node) || clickableArea.contains(event.target)) {
      return
    }

    event.preventDefault()
    event.stopPropagation()
    onClickAway()
  }, [onClickAway])

  const content = useMemo(() => (
    <div
      className={classes.content}
      key={contentKey}
      onClick={handleContentClick}
      {...rootProps ?? {}}
    >
      <div
        className={classes.inner}
        ref={(innerRef) => {
          inner.current = innerRef
        }}
      >
        { children }
      </div>
    </div>
  ), [
    classes,
    contentKey,
    handleContentClick,
    rootProps,
    children,
  ])

  const inVariant = light ? `rgba(255, 255, 255, ${alpha})` : `rgba(0, 0, 0, ${alpha})`
  const outVariant = light ? 'rgba(255, 255, 255, 0)' : 'rgba(0, 0, 0, 0)'
  const handleKeydown = useCallback<(a: KeyboardEvent | MouseEvent) => void>((event) => {
    if ('keyCode' in event && event.keyCode === ESCAPE_KEY) {
      onClickAway?.()
    }
  }, [onClickAway])

  const maybeWrappedContent = useMemo(() => wrapContentWith
    ? wrapContentWith(content)
    : content, [content, wrapContentWith])

  return (
    <m.div
      animate={{ background: inVariant }}
      className={cx(className, classes.container)}
      exit={{ background: outVariant }}
      initial={{ background: outVariant }}
    >
      <BodyClass className='d2-overlay-is-active' />
      <DocumentOn
        event='keydown'
        fn={onClickAway ? handleKeydown : undefined}
      />
      { maybeWrappedContent }
    </m.div>
  )
})

Overlay.displayName = 'Overlay'

const OverlayPortal = memo<Props>((props) => {
  const modalRoot = useMemo<Element | null | undefined>(() => {
    let modalRoot: Element | null | undefined
    try {
      modalRoot = document.querySelector('#react-modal-root')
    } catch (error) {
      // TODO: Why are we catching these errors? Isn't it too defensive?
      // eslint-disable-next-line no-console
      console.error('Error in d2/src/components/Overlay/index', error)
    }
    return modalRoot
  }, [])

  if (modalRoot) {
    return createPortal(
      <Overlay {...props} />,
      modalRoot,
    )
  }

  return null
})

OverlayPortal.displayName = 'OverlayPortal'

export default OverlayPortal
