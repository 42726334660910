import type { Exact } from 'd2/types'
import type { GenericReducer } from '../types'

export type SelectedNetworkIds = Exact<{
  [x: string]: boolean
}>

export type ScheduleType = 'asap' | 'schedule'

export type State = {
  albumId: string,
  deployAt: Scalar$DateTime | null | undefined,
  scheduleType: ScheduleType | null | undefined,
  selectedNetworkIds: SelectedNetworkIds | null | undefined
}

export const init: () => State = () => ({
  albumId: '',
  deployAt: null,
  scheduleType: null,
  selectedNetworkIds: {},
})

export type Reducer = GenericReducer<State>
const reducer: Reducer = (state = init(), action) => {
  switch (action.type) {
  case 'CREATE_ALBUM_RELEASE_UPDATE_DEPLOY_AT': {
    return {
      ...state,
      deployAt: action.payload.deployAt,
    }
  }
  case 'CREATE_ALBUM_RELEASE_UPDATE_SCHEDULE_TYPE': {
    return {
      ...state,
      scheduleType: action.payload.scheduleType,
    }
  }
  case 'CREATE_ALBUM_RELEASE_UPDATE_SELECTED_NETWORK_IDS': {
    return {
      ...state,
      selectedNetworkIds: action.payload.selectedNetworkIds,
    }
  }
  case 'CREATE_ALBUM_RELEASE_RESET': {
    return init()
  }
  default: {
    return state
  }
  }
}

export default reducer
