import type { Exact } from 'd2/types'
import type { GenericReducer } from '../types'

export type DestinationType = 'customize' | 'recommended'
export type SelectedNetworkIds = Exact<{
  [x: string]: boolean
}>

export type State = {
  destinationType: DestinationType | null | undefined,
  selectedNetworkIds: SelectedNetworkIds
}

const DEFAULT_DESTINATION_TYPE: DestinationType = 'recommended'

export const init: () => State = () => ({
  destinationType: DEFAULT_DESTINATION_TYPE,
  selectedNetworkIds: {},
})

export type Reducer = GenericReducer<State>
const reducer: Reducer = (state = init(), action) => {
  switch (action.type) {
  case 'DESTINATION_SELECTOR_UPDATE_DESTINATION_TYPE': {
    return {
      ...state,
      destinationType: action.payload.destinationType,
    }
  }
  case 'DESTINATION_SELECTOR_UPDATE_SELECTED_NETWORK_IDS': {
    return {
      ...state,
      selectedNetworkIds: { ...action.payload.selectedNetworkIds },
    }
  }
  case 'DESTINATION_SELECTOR_RESET': {
    return init()
  }
  default: {
    return state
  }
  }
}

export default reducer
