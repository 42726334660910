import { px } from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'

const MAX_SIZE = 1080
const MIN_SIZE = 100

const styles = () => ({
  qrCode: {
    maxHeight: px(MAX_SIZE),
    maxWidth: px(MAX_SIZE),
    minHeight: px(MIN_SIZE),
    minWidth: px(MIN_SIZE),
  },
} as const)

export default makeStyles({
  name: 'OtpQrCode',
})(styles)
