import { memo } from 'react'
import { useQuery } from './queries'
import { useSelector } from 'react-redux'
import FlashMessageBanner from '../FlashMessageBanner'
import useTranslations from './translations'
import type { Props } from './types'
import type { State } from 'd2/reducers'

const StorageSpaceBanner = memo<Props>(({
  d1,
}) => {
  const [data] = useQuery()
  const t = useTranslations()
  const bannerActive = useSelector<State, boolean>(({ banner }) => banner.StorageSpaceBanner)

  if (!bannerActive || !data) return null

  if (!data.me.exceeded_storage_limit || data.me.has_hidden_payment_plan) return null

  return (
    <FlashMessageBanner
      actionText={t.buttonText}
      actionUrl='/storage/upgrade'
      bannerId='storage-limit-exceeded-banner'
      bannerType='danger'
      d1={d1}
      message={t.bannerText()}
    />
  )
})

StorageSpaceBanner.displayName = 'StorageSpaceBanner'

export default StorageSpaceBanner
