import { MAX } from 'd2/constants'
import { negative, px } from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

const SHADOW_HEIGHT = 5
const SHADOW_OFFSET = 2
const TRANSITION = '0.1s ease-in-out'

type Style = {
  [x: string]: number | string | null | undefined
}

type StylesProps = {
  height: string | undefined,
  maxHeight: string | undefined,
}

const shadow: Style = {
  MozTransition: `-moz-box-shadow ${TRANSITION}`,
  WebkitTransition: `-webkit-box-shadow ${TRANSITION}`,
  content: '""',
  height: px(SHADOW_HEIGHT),
  left: px(negative(SHADOW_OFFSET)),
  pointerEvents: 'none',
  position: 'absolute',
  right: px(negative(SHADOW_OFFSET)),
  transition: `box-shadow ${TRANSITION}`,
}

const styles = (theme: MergedThemes, { height, maxHeight }: StylesProps) => ({
  border: {
    border: `${px(1)} solid ${theme.branding?.disabled ?? 'transparent'}`,
  },
  container: {
    '&:after': {
      ...shadow,
      bottom: px(negative(SHADOW_HEIGHT)),
    },
    '&:before': {
      ...shadow,
      top: px(negative(SHADOW_HEIGHT)),
    },
    overflow: 'hidden',
    position: 'relative',
  },
  containerShadowBottom: {
    '&:after': {
      MozBoxShadow: '0 -2px 2px -1px rgba(0, 0, 0, 0.1)',
      WebkitBoxShadow: '0 -2px 2px -1px rgba(0, 0, 0, 0.1)',
      boxShadow: '0 -2px 2px -1px rgba(0, 0, 0, 0.1)',
    },
  },
  containerShadowTop: {
    '&:before': {
      MozBoxShadow: '0 2px 2px -1px rgba(0, 0, 0, 0.1)',
      WebkitBoxShadow: '0 2px 2px -1px rgba(0, 0, 0, 0.1)',
      boxShadow: '0 2px 2px -1px rgba(0, 0, 0, 0.1)',
    },
  },
  fillHeight: {
    height: MAX,
  },
  fillWidth: {
    width: MAX,
  },
  inner: {
    height,
    maxHeight,
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
} as const)

export default makeStyles<StylesProps>({
  name: 'ScrollableContainer',
})(styles)
