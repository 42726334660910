/* eslint-disable react-memo/require-memo */
import { TRANSITION_SPEED } from 'd2/constants'
import { onlyText } from 'react-children-utilities'
// eslint-disable-next-line consistent-default-export-name/default-import-match-filename
import MuiTableSortLabel from '@mui/material/TableSortLabel'
import Tooltip from 'd2/components/Tooltip'
import useStyles from './styles'
import useTranslations from './translations'
import type { TemplateType } from './types'

const TableSortLabel: TemplateType = ({
  isSorting,
  label,
  sortDirection = 'asc',
  tooltipPlacement,
  tooltipTitle,
}) => {
  const { classes } = useStyles()
  const t = useTranslations()

  return (
    <Tooltip
      enterDelay={TRANSITION_SPEED}
      hasArrow
      placement={tooltipPlacement ?? 'bottom'}
      title={t.sortBy(onlyText(tooltipTitle), isSorting)}
    >
      { /* TODO: support sortIconBefore prop */ }
      <MuiTableSortLabel
        active={isSorting}
        className={classes.grayText}
        direction={sortDirection}
        hideSortIcon
      >
        <span className={classes.grayText}>
          { label }
        </span>
      </MuiTableSortLabel>
    </Tooltip>
  )
}

TableSortLabel.displayName = 'TableSortLabel'

export default TableSortLabel
