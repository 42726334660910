export function deepEquals<T> (first: T, second: T): boolean {
  return JSON.stringify(first) === JSON.stringify(second)
}

// Object.keys is annoying because the TS type of the return value is `string[]`
// typedKeys is a wrapper around Object.keys that returns a smarter type, containing only the actual keys of the object.
// https://stackoverflow.com/a/52856805/2696867
export function typedKeys<T extends AO> (obj: T): (keyof T)[] {
  // TODO: Remove 'as' type assertions because they are unsafe.
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return Object.keys(obj) as (keyof T)[]
}
