/* eslint-disable @typescript-eslint/no-use-before-define */
import { ReactDomProps } from 'd2/types'
import { SPACING } from 'd2/constants'
import { d } from 'd2/utils'
import { isTDDBrowser } from 'd2/utils/tdd'
import { keyframes } from 'tss-react'
import { m } from 'framer-motion'
import { memo } from 'react'
import { ms, px } from 'd2/utils/style'
import { reduce, times } from 'lodash-es'

// We can avoid passing in new objects every render by defining the objects outside of the component:
const APPEAR_FROM_TOP_PROPS = {
  animate: {
    opacity: 1,
    transform: 'translateY(0px)',
  },
  exit: {
    opacity: 0,
    transform: 'translateY(-10px)',
  },
  initial: {
    opacity: 0,
    transform: 'translateY(-10px)',
  },
  transition: { duration: 0.3 },
}

export const AppearFromTop = memo(({ children }: ReactDomProps) => (
  <m.div {...APPEAR_FROM_TOP_PROPS}>
    { children }
  </m.div>
))

AppearFromTop.displayName = 'AppearFromTop'

export type OptsType = {
  delayBase?: number,
  delayEasingAmount?: number,
  delayStepInterval?: number,
  direction?: string,
  durationEasingAmount?: number,
  easingFunction?: (a: number) => number,
  itemAnimationDuration?: number,
  numItems?: number,
  positionOffset?: number
}

export function appear (opts?: OptsType) {
  const {
    delayBase = 0,
    delayEasingAmount = 0.9,
    delayStepInterval = 60,
    durationEasingAmount = 0.9,
    easingFunction = (i: number) => Math.sin((i / numItems) * Math.PI / 2),
    itemAnimationDuration = 300,
    numItems = 25,
  }: OptsType = d(opts)

  const maxItemDelay: number = delayStepInterval * numItems / 2

  if (isTDDBrowser()) {
    return {
      itemAnimationStyles: {},
    }
  }

  return {
    itemAnimationStyles: {
      ...numItems === 1
        ? {
          animationDelay: ms(delayBase),
          animationDuration: ms(itemAnimationDuration),
        }
        : reduce(times(numItems), (nthChildStyles, i: number) => {
          const easingFraction: number = easingFunction(i)
          const currentItemAnimationDuration: number = (1 - (easingFraction * durationEasingAmount)) * itemAnimationDuration

          return {
            ...nthChildStyles,
            [`&:nth-of-type(${i + 1})`]: {
              animationDelay: ms(delayBase + easingFraction * maxItemDelay * delayEasingAmount),
              animationDuration: ms(currentItemAnimationDuration),
            },
          }
        }, {}),
      animationFillMode: 'backwards',
      animationIterationCount: 1,
      animationName: keyframes`
        100% {
          opacity: 1;
        }
        0% {
          opacity: 0;
          visibility: visible;
        }
      `,
      animationTimingFunction: 'ease-out',
    },
  }
}

export function appearFromSide (opts?: OptsType) {
  const {
    delayBase = 0,
    delayEasingAmount = 0.9,
    delayStepInterval = 60,
    durationEasingAmount = 0.9,
    easingFunction = (i: number) => Math.sin((i / numItems) * Math.PI / 2),
    itemAnimationDuration = 300,
    numItems = 25,
    positionOffset = SPACING,
    direction = 'right',
  }: OptsType = d(opts)

  const maxItemDelay: number = delayStepInterval * numItems / 2

  if (isTDDBrowser()) {
    return {
      itemAnimationStyles: {},
    }
  }

  return {
    itemAnimationStyles: {
      ...numItems === 1
        ? {
          animationDelay: ms(delayBase),
          animationDuration: ms(itemAnimationDuration),
        }
        : reduce(times(numItems), (nthChildStyles, i: number) => {
          const easingFraction: number = easingFunction(i)
          const currentItemAnimationDuration: number = (1 - (easingFraction * durationEasingAmount)) * itemAnimationDuration

          return {
            ...nthChildStyles,
            [`&:nth-of-type(${i + 1})`]: {
              animationDelay: ms(delayBase + easingFraction * maxItemDelay * delayEasingAmount),
              animationDuration: ms(currentItemAnimationDuration),
            },
          }
        }, {}),
      animationFillMode: 'backwards',
      animationIterationCount: 1,
      animationName: keyframes`
        0% {
          opacity: 0;
          transform: translateX(${px(direction === 'right' ? positionOffset : -positionOffset)});
          visibility: visible;
        }
        100% {
          opacity: 1;
          transform: translateX(0);
        }
      `,
      animationTimingFunction: 'ease-out',
    },
  }
}

export function appearFromVertical (opts?: OptsType) {
  const {
    delayBase = 0,
    delayEasingAmount = 0.9,
    delayStepInterval = 60,
    durationEasingAmount = 0.9,
    easingFunction = (i: number) => Math.sin((i / numItems) * Math.PI / 2),
    itemAnimationDuration = 300,
    numItems = 25,
    positionOffset = SPACING,
    direction = 'top',
  }: OptsType = d(opts)

  const maxItemDelay: number = delayStepInterval * numItems / 2

  if (isTDDBrowser()) {
    return {
      itemAnimationStyles: {},
    }
  }

  return {
    itemAnimationStyles: {
      ...numItems === 1
        ? {
          animationDelay: ms(delayBase),
          animationDuration: ms(itemAnimationDuration),
        }
        : reduce(times(numItems), (nthChildStyles, i: number) => {
          const easingFraction: number = easingFunction(i)
          const currentItemAnimationDuration: number = (1 - (easingFraction * durationEasingAmount)) * itemAnimationDuration

          return {
            ...nthChildStyles,
            [`&:nth-of-type(${i + 1})`]: {
              animationDelay: ms(delayBase + easingFraction * maxItemDelay * delayEasingAmount),
              animationDuration: ms(currentItemAnimationDuration),
            },
          }
        }, {}),
      animationFillMode: 'backwards',
      animationIterationCount: 1,
      animationName: keyframes`
        from {
          opacity: 0;
          transform: translateY(${px(direction === 'top' ? -positionOffset : positionOffset)});
          visibility: visible;
        }
        to {
          opacity: 1;
          transform: translateY(0);
        }
      `,
      animationTimingFunction: 'ease-out',
    },
  }
}

export function appearFromCenter (opts?: OptsType) {
  const {
    delayBase = 0,
    delayEasingAmount = 0.9,
    delayStepInterval = 60,
    durationEasingAmount = 0.9,
    easingFunction = (i: number) => Math.sin((i / numItems) * Math.PI / 2),
    itemAnimationDuration = 300,
    numItems = 25,
  }: OptsType = d(opts)

  const maxItemDelay: number = delayStepInterval * numItems / 2

  if (isTDDBrowser()) {
    return {
      itemAnimationStyles: {},
    }
  }

  return {
    itemAnimationStyles: {
      ...numItems === 1
        ? {
          animationDelay: ms(delayBase),
          animationDuration: ms(itemAnimationDuration),
        }
        : reduce(times(numItems), (nthChildStyles, i: number) => {
          const easingFraction: number = easingFunction(i)
          const currentItemAnimationDuration: number = (1 - (easingFraction * durationEasingAmount)) * itemAnimationDuration

          return {
            ...nthChildStyles,
            [`&:nth-of-type(${i + 1})`]: {
              animationDelay: ms(delayBase + easingFraction * maxItemDelay * delayEasingAmount),
              animationDuration: ms(currentItemAnimationDuration),
            },
          }
        }, {}),
      animationFillMode: 'backwards',
      animationIterationCount: 1,
      animationName: keyframes`
        0% {
          transform: scale(0, 0);
        }
      `,
      animationTimingFunction: 'ease-out',
    },
  }
}

export const zDepthAnimationOnHover = {
  '&:hover': {
    boxShadow: `0 ${px(6)} ${px(16)} 0 rgba(212, 212, 212, 0.5)`,
  },
  transition: `box-shadow ${ms(500)} ease`,
}
