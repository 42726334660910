import {
  Children,
  cloneElement,
  isValidElement,
  memo,
} from 'react'
import { SPACING } from 'd2/constants'
import useStyles from './styles'

type OwnProps = {
  align?: 'center' | 'left' | 'right' | 'spread',
  children: React$Node,
  maxWidth?: boolean,
  noMargin?: boolean,
  reverseStack?: boolean,
  spacing?: number,
  stackMdScreen?: boolean,
  stackXsScreen?: boolean
}

type Props = OwnProps

const ButtonContainer = memo<Props>(({
  align,
  children,
  maxWidth = false,
  noMargin,
  reverseStack = true,
  spacing,
  stackMdScreen = false,
  stackXsScreen = true,
}) => {
  const { classes, cx } = useStyles()
  const childrenArray = Children.toArray(children)
  const { length: childrenCount } = childrenArray

  return (
    <div
      className={
        cx(classes.container, {
          [classes.containerWithMargin]: !noMargin,
          [classes.alignRight]: !align || align === 'right',
          [classes.alignCenter]: align === 'center',
          [classes.alignLeft]: align === 'left',
          [classes.alignSpread]: align === 'spread',
          [classes.maxWidth]: maxWidth,
          [classes.stackMdScreen]: stackMdScreen,
          [classes.stackXsScreen]: stackXsScreen,
        })
      }
      style={{ gridGap: spacing ?? SPACING }}
    >
      { childrenArray.map((child, index: number) => isValidElement(child) && cloneElement(child, {
        // @ts-expect-error TS version 4.8 started complaining about cloneElement's props.
        style: {
          ...child.props.style,
          '--reverse-index': reverseStack ? childrenCount - index : index + 1,
        },
      })) }
    </div>
  )
})

ButtonContainer.displayName = 'ButtonContainer'

export default ButtonContainer
