import type { GenericReducer } from '../types'
import type { SortDirection } from 'd2/types'

export type StorageConsumptionTableSortColumns = 'file_size'

export type State = {
  counter: number,
  counter2: number,
  storageConsumptionTablePageIndex: number,
  storageConsumptionTableSortBy: StorageConsumptionTableSortColumns,
  storageConsumptionTableSortDirection: SortDirection
}

const init: () => State = () => ({
  counter: 0,
  counter2: 0,
  storageConsumptionTablePageIndex: 0,
  storageConsumptionTableSortBy: 'file_size',
  storageConsumptionTableSortDirection: 'desc',
})

export type Reducer = GenericReducer<State>
const reducer: Reducer = (state = init(), action) => {
  switch (action.type) {
  case 'INCREMENT_COUNTER': {
    return {
      ...state,
      counter: state.counter + 1,
    }
  }
  case 'INCREMENT_COUNTER_2': {
    return {
      ...state,
      counter2: state.counter2 + 1,
    }
  }
  case 'CHANGE_STORAGE_CONSUMPTION_TABLE_PAGE': {
    return {
      ...state,
      storageConsumptionTablePageIndex: action.payload,
    }
  }
  case 'CHANGE_STORAGE_CONSUMPTION_TABLE_SORT':
  {
    const sortBy: StorageConsumptionTableSortColumns = action.payload
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const sortDirection: SortDirection = state.storageConsumptionTableSortBy === sortBy ? state.storageConsumptionTableSortDirection === 'desc' ? 'asc' : 'desc' : 'asc'

    return {
      ...state,
      storageConsumptionTableSortBy: sortBy,
      storageConsumptionTableSortDirection: sortDirection,
    }
  }
  default: {
    return state
  }
  }
}

export default reducer
