// originally taken from assets/javascripts/utils/Errors.js but it needed capitalized
// it would probably be better to use graphql i18n, which we can do in the future
import { logWarn } from 'd2/utils/environment'
import type { MutationError } from './types'

// TODO: translations!
const messages = {
  cannot_self_invite: 'You cannot invite yourself as a payee',
  cant_be_changed: 'Cannot be changed',
  cant_be_empty: 'Required',
  cant_match_genre: 'Secondary Genre and Genre cannot match.',
  doesnt_match: 'Doesn\'t match',
  duplicate_invite: 'Already invited',
  emails_must_match: 'Must match',
  greater_than_max: 'Is greater than maximum allowed',
  invalid_email: 'Must be a valid email',
  invalid_emails: 'One or more email addresses are invalid',
  is_duplicated: 'Already exists',
  is_invalid: 'Is invalid',
  is_invalid_url_format: 'Must start with either http:// or https://',
  is_too_short: 'Is too short',
  less_than_min: 'Is less than the minimum allowed',
  must_agree: 'Must agree',
  must_be_checked: 'Must be checked',
  must_be_first_and_last_name: 'Must include first and last name',
  must_be_greater_than_0: 'Must be greater than 0',
  must_be_in_organization: 'Must be in your organization',
  must_be_selected: 'Must be selected',
  must_be_unique: 'Must be unique',
  must_be_valid_sms: 'Phone number must be capable of receiving SMS. Please update the number in \'Account Info\' and try again.',
  must_be_yes: 'Must be yes',
  not_sms_compatible: 'Phone Number is not capable of receiving SMS.',
  unsupported_sms_territory: 'SMS notifications are not yet supported in this territory.',
  upc_must_be_unique: 'UPC already exists for a different album in your catalog',
}
/* eslint-enable unicorn/no-unused-properties */

export function getFormErrorMessage (key: string): string {
  // TODO: Remove 'as' type assertions because they are unsafe.
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const { [key]: message } = messages as Record<string, string>
  if (message) return message

  logWarn(`Unknown form error message key ${key}. The UI might have an ugly message.`)
  return key
}

export const errorsAreSamePath = (
  {
    key: key1,
    resource_id: resourceId1,
    resource_type: resourceType1,
  }: MutationError,
  {
    key: key2,
    resource_id: resourceId2,
    resource_type: resourceType2,
  }: MutationError,
): boolean => key1 === key2
  && resourceId1 === resourceId2
  && resourceType1 === resourceType2
