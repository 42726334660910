/*

UrlPagination
--------------------

Provides props for paging and sorting using URL query string parameters to store the values.

*/
/* eslint-disable @typescript-eslint/no-shadow */
import { SMALL_TABLE_SIZE } from 'd2/constants'
import { buildNestedQuery } from 'd2/utils/Routes'
import { d } from 'd2/utils'
import {
  includes,
  isNaN,
  mapKeys,
  max,
} from 'lodash-es'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'd2/hooks/useRouter'
import { useSelector } from 'react-redux'
import advancedSearchOptionsFor from 'd2/utils/advancedSearchOptionsFor'
import qs from 'qs'
import type { BrowserLocation } from 'd2/types'
import type {
  DefaultPaginationSortProps,
  PaginationOptions,
  PaginationProps,
  SortDirection,
} from './types'
import type { SortBy } from 'd2/queries/enumSortTypes'
import type { State } from 'd2/reducers'

const DEFAULT_SORT_DIRECTION: SortDirection = 'asc'

export default function useUrlPagination<T extends DefaultPaginationSortProps = DefaultPaginationSortProps> (
  options?: PaginationOptions | null,
  sortByType: SortBy | null | undefined = null,
): PaginationProps<T> {
  const {
    defaultPageSize,
    defaultSortBy,
    defaultSortDirection,
    paramPrefix,
  } = d(options)
  const location = useSelector<State, BrowserLocation>(({ router }) => router.location)
  const navigate = useNavigate()
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
  const getUrlQueryParamKey: (a: string) => string = useCallback(
    (key) => paramPrefix ? `${paramPrefix}_${key}` : key,
    [paramPrefix],
  )
  const getUrlQueryParamIntValue: (b: string, a: number) => number = useCallback(
    (key, defaultValue) => {
      const { [getUrlQueryParamKey(key)]: value } = queryParams
      if (!value || typeof value !== 'string') return defaultValue
      const parsed: number = Number.parseInt(value, 10)
      return isNaN(parsed) ? defaultValue : parsed
    },
    [getUrlQueryParamKey, queryParams],
  )

  const updateUrlQueryParams: (
    newParameters: {
      [x: string]: number | string
    },
    additionalParams?: {
      [x: string]: number | string
    } | null
  ) => void = useCallback(
    (newParameters, additionalParams = null) => {
      const newQueryString = {
        ...queryParams,
        ...mapKeys(newParameters, (_value, key) => getUrlQueryParamKey(key)),
      }
      const newParams = {
        ...newQueryString,
        ...additionalParams,
      }
      navigate({
        ...location,
        search: `?${buildNestedQuery(newParams)}`,
      })
      return newParams
    },
    [
      getUrlQueryParamKey,
      navigate,
      location,
      queryParams,
    ],
  )

  const sortValues = useMemo(() => advancedSearchOptionsFor(sortByType), [sortByType])

  const page: number = max([getUrlQueryParamIntValue('page', 1) - 1, 0]) ?? 0
  const perPage: number = getUrlQueryParamIntValue('per_page', defaultPageSize ?? SMALL_TABLE_SIZE)
  const searchTerm = String(queryParams[getUrlQueryParamKey('search_term')] ?? '')
  const sortBy = queryParams[getUrlQueryParamKey('sort_by')] ?? defaultSortBy

  if (sortBy && sortValues?.length && !includes(sortValues, sortBy)) {
    throw new Error(`${JSON.stringify(sortBy)} is not a valid sort value on ${location.pathname}`)
  }

  return useMemo(
    () => ({
      onChangePerPage: (perPage) => updateUrlQueryParams({
        page: 1,
        per_page: perPage,
      }),
      onChangeSearchTerm: (newSearchTerm) => {
        if (searchTerm === newSearchTerm) return
        updateUrlQueryParams({
          page: 1,
          search_term: newSearchTerm,
        })
      },
      onChangeSortBy: (sortBy, direction) => updateUrlQueryParams({
        page: 1,
        sort_by: sortBy,
        sort_direction: direction,
      }),
      onPageChange: (page, additionalParams) => updateUrlQueryParams({ page: page + 1 }, additionalParams),
      page,
      pageFirstItemIndex: page * perPage,
      perPage,
      searchTerm,
      sortBy,
      // TODO: Remove 'as' type assertions because they are unsafe.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      sortDirection: (queryParams[getUrlQueryParamKey('sort_direction')] ?? defaultSortDirection ?? DEFAULT_SORT_DIRECTION) as SortDirection,
    }),
    [
      defaultSortDirection,
      getUrlQueryParamKey,
      page,
      perPage,
      queryParams,
      searchTerm,
      sortBy,
      updateUrlQueryParams,
    ],
  )
}
