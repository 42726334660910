import {
  MAX,
  SPACING,
  SPACING_HALF,
  SPACING_ZERO,
} from 'd2/constants'
import { px } from 'd2/utils/style'
import { screenSmMax } from 'd2/utils/breakpoints'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

const styles = (
  {
    fontNormalStyles,
    ...theme
  }: MergedThemes,
) => ({
  alignCenter: {
    textAlign: 'center',
  },
  alignLeft: {
    textAlign: 'left',
  },
  alignRight: {
    textAlign: 'right',
  },
  bottomButton: {
    paddingTop: px(SPACING),
    width: MAX,
  },
  childrenWrapper: {
    width: MAX,
  },
  closeButton: {
    color: theme.colors.darkGray,
    cursor: 'pointer',
    fontSize: px(26),
    right: px(SPACING),
    top: px(SPACING),
  },
  content: {
    paddingBottom: px(SPACING),
    paddingTop: px(SPACING),
    width: MAX,
  },
  dividerBottom: {
    borderTop: `${px(1)} solid ${theme.palette.grey.A100}`,
  },
  footNote: {
    width: MAX,
  },
  fullWidth: {
    width: MAX,
  },
  headingDivider: {
    borderBottom: `${px(1)} solid ${theme.palette.grey.A100}`,
    paddingBottom: px(SPACING_HALF),
  },
  headingWrapper: {
    width: MAX,
  },
  inlineHeading: {
    alignSelf: 'flex-start',
  },
  modalContainer: {
    backgroundColor: theme.palette.common.white,
    boxShadow: '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.2)',
    padding: px(SPACING),
    position: 'relative',
    [`@media (max-width: ${px(screenSmMax)})`]: {
      bottom: 0,
      left: 0,
      position: 'static',
      right: 0,
      top: 0,
    },
  },
  modalHeading: {
    ...fontNormalStyles,
    color: theme.colors.darkGray,
    fontSize: px(28),
    fontWeight: 600,
    letterSpacing: px(1),
    lineHeight: px(36),
    textAlign: 'center',
  },
  modalWrapper: {
    height: MAX,
    [`@media (max-width: ${px(screenSmMax)})`]: {
      overflowX: 'hidden',
      overflowY: 'scroll',
      padding: px(SPACING_ZERO),
    },
  },
  noContentPadding: {
    paddingBottom: px(SPACING_ZERO),
    paddingTop: px(SPACING_ZERO),
  },
  overlayInnerContent: {
    padding: px(SPACING_HALF),
  },
} as const)

export default makeStyles({
  name: 'Modal',
})(styles)
