import { memo } from 'react'
import useStyles from './styles'
import type { OwnProps } from './types'

const Spacer = memo<OwnProps>(({
  double,
  half,
  quarter,
}) => {
  const { classes, cx } = useStyles()
  return (
    <div
      className={cx(classes.default, {
        [classes.half]: half,
        [classes.double]: double,
        [classes.quarter]: quarter,
      })}
    />
  )
})

Spacer.displayName = 'Spacer'

export default Spacer
