import type { Exact } from 'd2/types'
import type { GenericReducer } from '../types'

// TODO: How to use an array instead? The values do nothing here.
export const BANNER_KEYS = Object.freeze({
  StorageSpaceBanner: undefined,
})

export type BannerKey = keyof typeof BANNER_KEYS

export type State = Exact<{
  [k in BannerKey]: boolean;
}>

const init: () => State = () => ({
  StorageSpaceBanner: true,
})

export type Reducer = GenericReducer<State>
const reducer: Reducer = (state = init(), action) => {
  switch (action.type) {
  case 'SHOW_BANNER': {
    return {
      ...state,
      [action.payload.key]: true,
    }
  }
  case 'HIDE_BANNER': {
    return {
      ...state,
      [action.payload.key]: false,
    }
  }
  default: {
    return state
  }
  }
}

export default reducer
