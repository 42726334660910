import {
  PROGRESS_BAR,
  PROGRESS_CONTAINER,
  PROGRESS_TEXT,
} from 'd2/constants/zIndexes'
import { px } from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

const HEIGHT = 20 as const
const styles = (
  {
    fontNormalStyles,
    ...theme
  }: MergedThemes,
) => ({
  progressBar: {
    backgroundColor: theme.palette.common.white,
    borderRadius: px(10),
    boxShadow: 'inset 0 1px 4px 0 rgba(0,0,0,0.4)',
    height: px(HEIGHT),
    marginRight: px(0),
    marginTop: px(15),
    width: '100%',
    zIndex: PROGRESS_BAR,
  },
  progressBarFillFull: {
    backgroundColor: theme.branding?.danger,
    boxShadow: 'inset 0 1px 4px 0 rgba(0,0,0,0.4)',
    height: px(HEIGHT),
  },
  progressBarFillNotFull: {
    backgroundColor: theme.branding?.primaryColor,
    boxShadow: 'inset 0 1px 4px 0 rgba(0,0,0,0.4)',
    height: px(HEIGHT),
  },
  progressBarRoot: {
    height: px(HEIGHT),
  },
  progressContainer: {
    height: px(HEIGHT),
    position: 'relative',
    textAlign: 'center',
    zIndex: PROGRESS_CONTAINER,
  },
  progressSectionContainer: {
    position: 'relative',
    textAlign: 'center',
    width: '100%',
  },
  progressText: {
    ...fontNormalStyles,
    color: theme.branding?.primaryContrastTextColor,
    fontSize: px(12),
    lineHeight: px(HEIGHT),
    position: 'absolute',
    width: '100%',
    zIndex: PROGRESS_TEXT,
  },
  progressTextUnder: {
    color: theme.colors.darkGray,
  },
} as const)

export default makeStyles({
  name: 'StorageConsumptionProgressBar',
})(styles)
