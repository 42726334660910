import {
  useEffect,
  useRef,
  useState,
} from 'react'

export default function useDebounce<TValue> (value: TValue, delay: number): TValue {
  const [debouncedValue, setDebouncedValue] = useState<TValue>(value)
  const didMountRef = useRef(false)

  useEffect(
    () => {
      if (didMountRef.current) {
        const handler = setTimeout(() => {
          setDebouncedValue(value)
        }, delay)

        return () => {
          clearTimeout(handler)
        }
      }
      didMountRef.current = true
    }, [delay, value],
  )

  return debouncedValue
}
