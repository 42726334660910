import {
  BANNER_HEIGHT,
  SPACING,
  SPACING_DOUBLE,
  SPACING_EIGHTH,
  SPACING_HALF,
  SPACING_ZERO,
} from 'd2/constants'
import { px } from 'd2/utils/style'
import {
  screenMdMax,
  screenMdMin,
  screenSmMax,
  screenXsMax,
} from 'd2/utils/breakpoints'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

const styles = (
  {
    fontNormalStyles,
    ...theme
  }: MergedThemes,
) => ({
  banner: {
    ...fontNormalStyles,
    alignItems: 'center',
    backgroundColor: theme.branding?.danger,
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    minHeight: px(BANNER_HEIGHT),
    padding: px(SPACING_HALF),
    position: 'relative',
    textAlign: 'center',
  },
  bannerD1: {
    marginBottom: px(SPACING_DOUBLE),
  },

  // TODO: Make this button style a reusable component
  button: {
    ...fontNormalStyles,
    '&:focus': {
      backgroundColor: theme.colors.transparent,
      color: theme.palette.common.white,
    },
    '&:hover': {
      backgroundColor: theme.colors.transparent,
      color: theme.palette.common.white,
    },
    '&:visited': {
      backgroundColor: theme.colors.transparent,
      color: theme.palette.common.white,
    },
    backgroundColor: theme.colors.transparent,
    border: `${px(2)} solid white`,
    borderRadius: px(20),
    boxShadow: 'none',
    color: theme.palette.common.white,
    fontSize: 'inherit',
    marginLeft: px(SPACING),
    minHeight: 'auto',
    padding: `${px(SPACING_EIGHTH)} ${px(SPACING)}`,
    textTransform: 'capitalize',
    [`@media (max-width: ${px(screenXsMax)})`]: {
      marginLeft: px(SPACING_ZERO),
      width: 'auto',
    },
  },

  buttonWrapper: {
    [`@media (max-width: ${px(screenSmMax)})`]: {
      marginTop: px(SPACING_HALF),
    },
  },

  danger: {
    backgroundColor: theme.branding?.danger,
  },

  dismissButton: {
    border: 'none',
    position: 'absolute',
    right: px(SPACING),
    [`@media (max-width: ${px(screenXsMax)})`]: {
      display: 'flex',
      justifyContent: 'flex-end',
      right: px(SPACING_HALF),
      width: '10%',
    },
  },

  info: {
    backgroundColor: theme.branding?.info,
  },

  messageActionWrapper: {
    [`@media (min-width: ${px(screenMdMin)})`]: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
  },

  success: {
    backgroundColor: theme.branding?.success,
  },

  wrapperWithDismissButton: {
    [`@media (max-width: ${px(screenMdMax)})`]: {
      width: '90%',
    },
  },
} as const)

export default makeStyles({
  name: 'FlashMessageBanner',
})(styles)
