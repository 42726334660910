// TODO: Rename dir to Base capitalized
// eslint-disable-next-line consistent-default-export-name/default-export-match-filename
import { forwardProps } from 'd2/utils/props'
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
} from 'react'
import { omit } from 'lodash-es'
import { track2 } from 'd2/analytics'
import AwareLink from 'd2/components/AwareLink'
import Button from '@mui/material/Button'
import ClipboardJS from 'clipboard'
import useSnackbar from 'd2/hooks/useSnackbar'
import useStyles from './styles'
import type { BaseProps } from './types'

const Base = memo<BaseProps>(forwardRef(({
  children,
  copyable,
  copyableSuccessMessage = 'Successfully copied to clipboard',
  copyableText,
  disabled,
  mutationLoading,
  size = 'medium',
  ...props
}, ref) => {
  const { classes } = useStyles()
  const { showSnackbar } = useSnackbar()

  // TODO: Make custom hook for this:
  useEffect(() => {
    // @ts-expect-error ref can be a function apparently? Not sure how to handle properly with forwardRef
    const el = ref?.current
    if (copyable && el) {
      const clipboardContainer = new ClipboardJS(el)

      clipboardContainer.on('success', () => {
        showSnackbar({
          hasDismiss: true,
          message: copyableSuccessMessage,
          type: 'success',
        })
      })
      clipboardContainer.on('error', () => {
        showSnackbar({
          hasDismiss: true,
          message: 'Error copying to clipboard',
          type: 'error',
        })
      })
    }
  }, [
    ref,
    copyable,
    showSnackbar,
    copyableSuccessMessage,
  ])

  const handleOnClick = useCallback((event: any) => {
    if (props.testID) {
      track2('button_press', { button_id: props.testID })
    }
    if (props.onClick) {
      props.onClick(event)
    }
  }, [props])

  return (
    <Button
      component={props.type === 'submit' ? 'button' : AwareLink}
      rel={props.target === '_blank' ? 'noopener noreferrer' : null}
      {...forwardProps(omit(props, 'color', 'variant', 'size'))}
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      disabled={disabled || mutationLoading}
      disableRipple
      ref={ref}
      {
        // TODO: Make custom hook for this:
        ...copyableText
          ? {
            'data-clipboard-text': copyableText,
          }
          : {}
        }
      classes={{
        disabled: classes.disabled,
        root: classes.root,
      }}
      onClick={handleOnClick}
      size={size}
    >
      { children }
    </Button>
  )
}))

Base.displayName = 'Base'

export default Base
