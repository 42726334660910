/* eslint-disable id-length */
// TS TYPES FOR THIS FILE ARE IN globals.d.ts under `declare module 'd2/utils' {`
// FLOW TYPES FOR THIS FILE ARE IN d2/src/flow-lib.js under `declare module 'd2/utils' {`

/*

See d2/src/utils/typecheck.tsx for type proofs

*/

// Use `d(obj)` to destructure an Object that can be null or undefined instead of `obj || {}` or `obj ?? {}`
export function d (obj: any) {
  // Originally had: `{ ...obj }` but this new version should be more performant:
  return obj || {}
}

// Use `da(arr)` to destructure an Array that can be null or undefined instead of `arr || []` or `arr ?? []`
export function da (arr: any) {
  // Originally had: `[...arr]` but this new version should be more performant:
  return arr || []
}
