import { SPACING_ZERO } from 'd2/constants'
import { allLinkStates, px } from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

const styles = ({ branding, colors }: MergedThemes) => ({
  link: {
    ...allLinkStates({
      backgroundColor: colors.transparent,
      borderColor: colors.transparent,
      boxShadow: 'none',
      color: branding?.linkColor,
    }),
    '&:active': {
      color: branding?.linkActive,
    },
    '&:disabled': {
      backgroundColor: colors.transparent,
      color: branding?.disabledText,
    },
    '&:hover': {
      backgroundColor: colors.transparent,
      color: branding?.linkHover,
    },
    '&:visited': {
      backgroundColor: colors.transparent,
      color: branding?.primaryColor,
    },
    backgroundColor: colors.transparent,
    borderColor: colors.transparent,
    color: branding?.primaryColor,
    fontSize: px(16),
    lineHeight: px(24),
    margin: SPACING_ZERO,
    minHeight: SPACING_ZERO,
    minWidth: SPACING_ZERO,
    padding: SPACING_ZERO,
    textTransform: 'none',
  },
} as const)

export default makeStyles({
  name: 'LinkButton',
})(styles)
