import {
  SyntheticEvent,
  forwardRef,
  memo,
  useCallback,
  useMemo,
} from 'react'
import { forwardProps } from 'd2/utils/props'
import { track2 } from 'd2/analytics'
import Link from 'd2/components/Link'
import useStyles from './styles'
import type { Props } from './types'

const HyperLink = memo<Props>(forwardRef(({
  bold,
  button,
  children,
  className: ownClassName,
  href,
  italic,
  large,
  medium,
  noFont,
  onClick: onClickProp,
  small,
  testID,
  to,
  variant = 'default',
  ...props
}, ref) => {
  const { classes, cx } = useStyles()

  const className: string = useMemo(
    () => cx({
      [classes.bold]: bold,
      [classes.buttonMargins]: button,
      [classes.danger]: variant === 'danger',
      [classes.default]: variant === 'default',
      [classes.disabled]: variant === 'disabled',
      [classes.secondary]: variant === 'secondary',
      [classes.tooltip]: variant === 'tooltip',
      [classes.italic]: italic,
      [classes.large]: large,
      [classes.small]: small,
      [classes.medium]: medium,
      [classes.noFont]: noFont && variant !== 'unstyled' && !bold,
      [classes.font]: !noFont && variant !== 'unstyled' && !bold,
    }),
    [
      classes,
      cx,
      bold,
      button,
      variant,
      italic,
      large,
      small,
      medium,
      noFont,
    ],
  )
  const handleTrack = useCallback(
    (event: SyntheticEvent) => {
      track2('hyperlink_press', {
        path: to ?? href,
        test_id: testID,
      })
      onClickProp?.(event)
    },
    [
      href,
      onClickProp,
      testID,
      to,
    ],
  )

  const rel: string | undefined = props.target === '_blank' ? 'noopener noreferrer' : undefined

  const newLocal = variant === 'disabled'
  return newLocal
    ? (
      <span
        className={cx(className, ownClassName)}
        data-test-id={testID}
        // @ts-expect-error Type 'ForwardedRef<unknown>' is not assignable to type 'LegacyRef<HTMLSpanElement> | undefined'.
        ref={ref}
        {...forwardProps(props)}
      >
        { children }
      </span>
    )
    : to
      ? (
        <Link
          className={cx(className, ownClassName)}
          data-test-id={testID}
          onClick={handleTrack}
          // @ts-expect-error Type 'ForwardedRef<unknown>' is not assignable to type 'LegacyRef<HTMLAnchorElement> | undefined'.
          ref={ref}
          rel={rel}
          to={to}
          {...forwardProps(props)}
        >
          { children }
        </Link>
      )
      : (
        <a
          className={cx(className, ownClassName)}
          data-test-id={testID}
          data-turbolinks='false'
          href={href}
          onClick={handleTrack}
          // @ts-expect-error Type 'ForwardedRef<unknown>' is not assignable to type 'LegacyRef<HTMLSpanElement> | undefined'.
          ref={ref}
          rel={rel}
          {...forwardProps(props)}
        >
          { children }
        </a>
      )
}))

HyperLink.displayName = 'HyperLink'

export default HyperLink
