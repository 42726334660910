/*
Connects to a specific value from the ancestor form.
*/
/* eslint-disable react-hooks/exhaustive-deps */
/* The dependency arrays that the lint rule gives will result in errors just blinking when you upload audio files and try to submit. */
import { FormValueContext } from '../Form'
// eslint-disable-next-line @typescript-eslint/no-restricted-imports, no-restricted-imports
import { PropertyPath } from 'lodash'
import {
  cloneDeep,
  every,
  find,
  get,
  head,
  includes,
  isNil,
  set,
  snakeCase,
} from 'lodash-es'
import { deepEquals } from 'd2/utils/objects'
import { errorsAreSamePath, getFormErrorMessage } from '../formErrors'
import { track2 } from 'd2/analytics'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import useDebounce from 'd2/hooks/useDebounce'
import type {
  FormError,
  FormFieldValue,
  FormValues,
  MutationError,
} from '../types'

/*
convertFieldValue: takes the value from path and does any necessary conversion to the value that's returned from the hook. This is not bi-directional.
path: the current property to show, like 'age'
  Supports property paths from https://lodash.com/docs/4.17.10#get, so you can do advanced stuff like 'parent.name'
  If set to null or undefined, the field value will be bound to whatever the current form value is from context.
error_key: the key within mutation errors to show for this field. defaults to path
getErrorMessage: Allows a way to provide a custom error message. Additionally you can use the <Form getErrorMessage prop
*/
export type UseFieldValueArgs = {
  convertFieldValue?: (a: FormFieldValue) => FormFieldValue,
  disabled?: boolean,
  errorKey?: string,
  errorKeys?: string[],
  getErrorMessage?: (b: string, a: MutationError) => string | null | undefined,
  path: string | null | undefined,
  resourceId?: number | string | null,
  resourceType?: string | null
}

export type UseFieldValueResult = {
  disableHtml5Required: boolean,
  error: string | null | undefined,
  fieldTestID: string | null | undefined,
  isDisabled: boolean,
  onChange: (a: FormFieldValue) => Promise<void> | void,
  value: FormFieldValue
  updateError: (a: FormFieldValue, b: any) => Promise<void> | void,
  validationError: FormError | null | undefined,
}

export function useFieldValue (
  {
    convertFieldValue,
    disabled,
    errorKey,
    errorKeys,
    getErrorMessage,
    path,
    resourceId,
    resourceType,
  }: UseFieldValueArgs,
): UseFieldValueResult {
  const {
    disableHtml5Required,
    errors,
    formDisabled,
    formErrors,
    formTestID,
    formValue,
    getErrorMessage: formGetErrorMessage,
    onFormChange,
    updateError,
  }: any = useContext(FormValueContext)

  // Because these are different objects every render, I think we can bypass adding them as deps (and causing infinite loops) by using refs.
  const formValueRef = useRef<typeof formValue>(formValue)
  const onFormChangeRef = useRef<typeof onFormChange>(onFormChange)
  formValueRef.current = formValue
  onFormChangeRef.current = onFormChange

  // "mutationError" doesn't change much. it will change between mutation submissions, and will
  // also change if the resource id/type, which happened in audio uploader
  const mutationError: MutationError | null | undefined = useMemo(() => {
    const errorKeysNormalized: string[] = (errorKeys ?? [errorKey ?? path]).map((error) => snakeCase(error!))
    return find(formErrors, (error) =>
      (every([error.resource_type, resourceType], isNil) || error.resource_type === resourceType)
    && (every([error.resource_id, resourceId], isNil) || String(error.resource_id) === String(resourceId))
    && includes(errorKeysNormalized, snakeCase(error.key)))
  }, [
    formErrors,
    resourceType,
    resourceId,
    errorKeys,
    errorKey,
    path,
  ])

  const validationError: FormError | null | undefined = useMemo(() => {
    if (!mutationError) return null
    return find(errors, (error) => errorsAreSamePath(mutationError, error))
  }, [errors, mutationError])

  const fieldValue: FormFieldValue = useMemo(() => {
    const value: FormFieldValue = path ? get(formValueRef.current, path) : formValueRef.current
    return convertFieldValue ? convertFieldValue(value) : value
  }, [formValueRef.current, path, convertFieldValue])

  const isErrorVisible: boolean = useMemo(() => {
    if (!validationError) return false
    if (!validationError.hasOwnProperty('valueForError')) return false // although this could be implied and would save a render step
    const {
      valueForError,
    }: any = validationError

    // if the current value equals the value when an error was assigned, show the error
    // the deep equals is necessary in the case of complex types, like an array (one empty array should equal another)
    return fieldValue === valueForError || deepEquals(fieldValue, valueForError)
  }, [fieldValue, validationError])

  const errorMessage: string | null | undefined = useMemo(() => {
    if (mutationError) {
      // At this time, we just show the first error
      const messageOrKey: string | null | undefined = head(mutationError.messages)
      if (messageOrKey) {
        // first at the hook level, then <Form getErrorMessage, then the global defaults
        let message: string | null | undefined = getErrorMessage ? getErrorMessage(messageOrKey, mutationError) : null
        if (!message && formGetErrorMessage) {
          message = formGetErrorMessage(messageOrKey)
        }
        if (!message) {
          message = getFormErrorMessage(messageOrKey)
        }
        return message
      }
    }
    return null
  }, [mutationError])

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const isDisabled: boolean = disabled || formDisabled // either the entire form is disabled, or just this component. honor either

  // this updates the validation error, typically when it first comes in
  useEffect(() => {
    // first encounter
    if (validationError && !validationError.hasOwnProperty('valueForError')) {
      updateError(validationError, {
        active: true,
        matched: true,
        valueForError: fieldValue,
        visible: true,
        visited: true,
      })
    }
    return () => {
      // see d2/examples/Forms/AdvancedFormErrors for an easy way to debug this
      if (validationError && (validationError.matched || validationError.visible)) {
        updateError(validationError, {
          matched: false,
          visible: false,
        })
      }
    }
  }, [validationError])

  // separate effect guarded by isErrorVisible is required because otherwise it can cause an infinite update loop
  useEffect(() => {
    if (validationError && validationError.visible !== isErrorVisible) {
      updateError(validationError, {
        active: isErrorVisible,
        matched: true,
        visible: isErrorVisible,
        visited: true,
      })
    }
  }, [validationError, isErrorVisible])

  const trackChange: (a: FormFieldValue) => void = useCallback((value) => {
    if (!path || !formTestID) return

    track2('form_field_changed', {
      field_path: path,
      form_id: formTestID,
      value,
    })
  }, [path, formTestID])

  // This may fire too often, so we may want to adjust this later depending on how
  // the information we get back is impacted.
  const delayTrackingChange = 500

  const debouncedValue: FormFieldValue = useDebounce(fieldValue, delayTrackingChange)

  useEffect(() => {
    if (debouncedValue) trackChange(debouncedValue)
  }, [debouncedValue])

  return {
    disableHtml5Required,
    error: isErrorVisible ? errorMessage : null,
    fieldTestID: formTestID && path && `${formTestID}-${path}`,
    isDisabled,
    onChange: useCallback((newValue: FormFieldValue) => {
      if (isDisabled) return // controls should not allow input to change but it could happen with controls that don't have disable functionality like ButtonGroup
      // the form value is shallow cloned, so paths with complex objects like 'parent.name' could reference a 'past' form value. shouldn't be a problem though
      const formValueClone: FormValues = cloneDeep(formValueRef.current)
      // TODO: Remove 'as' type assertions because they are unsafe.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      set(formValueClone, path as PropertyPath, newValue)
      onFormChangeRef.current(formValueClone)
    }, [isDisabled, path]),
    updateError,
    validationError,
    value: fieldValue,
  }
}
