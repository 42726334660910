import { SPACING_OCTUPLE } from 'd2/constants'
import { appear } from 'd2/animations'
import { px } from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

const {
  itemAnimationStyles,
} = appear({
  delayBase: 1200,
  itemAnimationDuration: 800,
  numItems: 1,
})

const styles = (theme: MergedThemes) => ({
  containerFillHeight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: px(SPACING_OCTUPLE * 2),
  },
  containerInnerFillHeight: {
    flex: '0',
  },
  progressCircle: {
    color: theme.branding?.secondaryColor,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  progressCircleDelay: {
    ...itemAnimationStyles,
  },
} as const)

export default makeStyles({
  name: 'LoadingSpinner',
})(styles)
