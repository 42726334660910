import { MAX, SPACING } from 'd2/constants'
import { px } from 'd2/utils/style'
import { screenMdMax, screenXsMax } from 'd2/utils/breakpoints'
import makeStyles from 'd2/hooks/tss/makeStyles'

const styles = () => ({
  alignCenter: {
    justifyContent: 'center',
  },
  alignLeft: {
    justifyContent: 'start',
  },
  alignRight: {
    justifyContent: 'end',
  },
  alignSpread: {
    justifyContent: 'normal',
  },
  container: {
    display: 'grid',
    gridAutoFlow: 'column',
  },
  containerWithMargin: {
    marginTop: px(SPACING),
  },
  maxWidth: {
    width: MAX,
  },
  stackMdScreen: {
    [`@media (max-width: ${screenMdMax}px)`]: {
      '& > *': {
        gridRow: 'var(--reverse-index, 1) / span 1',
      },
      gridAutoFlow: 'row',
      justifyContent: 'unset',
    },
  },
  stackXsScreen: {
    [`@media (max-width: ${screenXsMax}px)`]: {
      '& > *': {
        gridRow: 'var(--reverse-index, 1) / span 1',
      },
      gridAutoFlow: 'row',
      justifyContent: 'unset',
    },
  },
} as const)
export default makeStyles({
  name: 'ButtonContainer',
})(styles)
