import { MergedThemes } from 'd2/types'
import { SPACING_HALF, SPACING_ZERO } from 'd2/constants'
import makeStyles from 'd2/hooks/tss/makeStyles'

type StylesProps = { needSpacing: boolean }

const styles = (_theme: MergedThemes, { needSpacing }: StylesProps) => ({
  iconWrapper: {
    paddingRight: needSpacing ? SPACING_HALF : SPACING_ZERO,
  },
  root: {
    textTransform: 'inherit',
  },
} as const)

export default makeStyles<StylesProps>({
  name: 'IconButton',
})(styles)
