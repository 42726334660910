import { Props } from './types'
import {
  memo,
  useCallback,
  useMemo,
} from 'react'
import Modal from 'd2/components/Modal'
import SnackbarNotification from 'd2/components/SnackbarNotification'
// eslint-disable-next-line import/no-restricted-paths
import ConfirmPasswordForm from 'd2/partials/ConfirmPasswordForm'
// eslint-disable-next-line import/no-restricted-paths
import OneTimePasswordForm from 'd2/partials/OneTimePasswordForm'
import useModal from 'd2/hooks/useModal'

const OneTimePasswordModal = memo<Props>(({
  authMethod,
  forceSendInitialOtp = false,
  modalIndex,
  onPhoneError,
  onSuccess,
  onToggleClose,
  otpOnly = false,
  passwordForm,
  phoneNumber,
  redirect = false,
  rememberDevice,
  verifyOtp = false,
}) => {
  const content = useMemo(() => (<OneTimePasswordForm
    authMethod={authMethod}
    forceSendInitialOtp={forceSendInitialOtp}
    onPhoneError={onPhoneError}
    onSuccess={onSuccess}
    otpOnly={otpOnly}
    phoneNumber={phoneNumber}
    redirect={redirect}
    rememberDevice={rememberDevice}
    verifyOtp={verifyOtp}
  />), [
    authMethod,
    rememberDevice,
    otpOnly,
    onPhoneError,
    onSuccess,
    phoneNumber,
    forceSendInitialOtp,
    redirect,
    verifyOtp,
  ])
  const { hideModal } = useModal({
    modalIndex,
    modalKey: 'OneTimePasswordModal',
  })
  const handleOnToggleClose = useCallback(() => {
    onToggleClose()
    hideModal()
  }, [hideModal, onToggleClose])

  return (<>
    <Modal
      content={passwordForm ? <ConfirmPasswordForm onSuccess={onSuccess} /> : content}
      maxWidthPx={600}
      modalIndex={modalIndex}
      modalKey='OneTimePasswordModal'
      onToggleClose={handleOnToggleClose}
      testID='OneTimePasswordModal'
    />
    <SnackbarNotification />
  </>)
})

OneTimePasswordModal.displayName = 'OneTimePasswordModal'

export default OneTimePasswordModal
