/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { DEFAULT_LINE_HEIGHT } from 'd2/constants/fonts'
import { PREMIUM_PARTNER_COLORS, type SolidButtonProps } from './types'
import { SPACING_ZERO } from 'd2/constants'
import { allLinkStates, em } from 'd2/utils/style'
import { includes, toString } from 'lodash-es'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

const styles = (
  { branding, colors, fontNormalStyles, palette }: MergedThemes,
  { color }: SolidButtonProps,
) => {
  const buttonTextColor = `${color === 'secondary' ? 'secondary' : 'primary'}ButtonTextColor`
  const isPremiumPartnerButton = includes(PREMIUM_PARTNER_COLORS, toString(color))
  const needsBlackText = includes(['faintGray', 'white'], color)

  return {
    button: {
      ...fontNormalStyles,
      '&:disabled': {
        backgroundColor: branding?.disabled,
        color: branding?.disabledText,
      },
      '&:visited': {
        color: branding?.primaryButtonTextColor,
      },
      borderRadius: SPACING_ZERO,
      boxShadow: 'none',
      color: branding?.primaryButtonTextColor,
      fontFamily: branding?.primaryFontFamily,
      lineHeight: em(DEFAULT_LINE_HEIGHT),
      minHeight: SPACING_ZERO,
      minWidth: SPACING_ZERO,
      textAlign: 'center',
      textTransform: 'none',
      whiteSpace: 'nowrap',
    },
    buttonColor: {
      ...allLinkStates({
        // TODO: Remove 'as' type assertions because they are unsafe.
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        color: isPremiumPartnerButton ? `${colors.white} !important` : needsBlackText ? `${colors.black} !important` : `${branding?.[buttonTextColor as keyof typeof branding] as string | undefined ?? 'transparent'} !important`,
      }),
      '&:active': {
        // TODO: Remove 'as' type assertions because they are unsafe.
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        backgroundColor: branding?.[`${color ?? 'primary'}Active` as keyof typeof branding]
          // @ts-expect-error TODO: improve colors typing
          || colors[color ?? 'primary']
          // @ts-expect-error TODO: improve colors typing
          || palette[color ?? 'primary'],
      },
      '&:focus': {
        outlineOffset: SPACING_ZERO,
      },
      '&:hover': {
        // TODO: Remove 'as' type assertions because they are unsafe.
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        backgroundColor: branding?.[`${color ?? 'primary'}Hover` as keyof typeof branding]
          // @ts-expect-error TODO: improve colors typing
          || colors[`${color ?? 'primary'}Hover`]
          // @ts-expect-error TODO: improve colors typing
          || palette[color],
      },
      // TODO: Remove 'as' type assertions because they are unsafe.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      backgroundColor: branding?.[`${color ?? 'primary'}Color` as keyof typeof branding]
        // @ts-expect-error TODO: improve colors typing
        || colors[color ?? 'primary']
        // @ts-expect-error TODO: improve colors typing
        || palette[color ?? 'primary'],
    },
  } as const
}

export default makeStyles<SolidButtonProps>({
  name: 'SolidButton',
})(styles)
