import type { GenericReducer } from '../types'

export type ActiveTierId = string

export type State = {
  activeTierId: ActiveTierId | null | undefined
}

const init: () => State = () => ({
  activeTierId: null,
})

export type Reducer = GenericReducer<State>
const reducer: Reducer = (state = init(), action) => {
  switch (action.type) {
  case 'SELECT_TIER': {
    return {
      ...state,
      activeTierId: action.payload.activeTierId,
    }
  }
  default: {
    return state
  }
  }
}

export default reducer
