export default function useRadioNormalDefault () {
  return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <defs>
      <style>
        .a {
          fill: #fff;
          stroke: #dfdfdf;
          fill-rule: evenodd;
        }
      </style>
    </defs>
    <path class="a" d="M10,.5h0A9.5,9.5,0,0,1,19.5,10h0A9.5,9.5,0,0,1,10,19.5h0A9.5,9.5,0,0,1,.5,10h0A9.5,9.5,0,0,1,10,.5Z"/>
  </svg>`
}
