import {
  memo,
  useEffect,
  useMemo,
} from 'react'
import { noop } from 'lodash-es'

/*

Example usage:

  <div>
    <DocumentOn
      event='keydown'
      fn={onClickAway && ((event) => {
        if (event.keyCode === ESCAPE_KEY) {
          onClickAway(event)
        }
      })}
    />
  </div>

*/

// When adding a new event type, add to `EventName` and `Fn`:
type EventName = KeyboardEventTypes | MouseEventTypes // E.g. 'keydown' | 'mouseup' | 'click' ...

type Fn = (a: KeyboardEvent | MouseEvent) => void

type Props = {
  event: EventName,
  fn: Fn | null | undefined
}

const DocumentOn = memo<Props>(({
  event,
  fn,
}) => {
  const fnMemo = useMemo<Fn | null | undefined>(() => fn ?? noop, [fn])

  useEffect(() => {
    if (!fnMemo) return
    try {
      document.addEventListener(event, fnMemo, false)
    } catch (error) {
      // TODO: Why are we catching these errors? Isn't it too defensive?
      // eslint-disable-next-line no-console
      console.error('Error in d2/src/components/DocumentOn/index.js setup', error)
    }

    return () => {
      try {
        document.removeEventListener(event, fnMemo, false)
      } catch (error) {
        // TODO: Why are we catching these errors? Isn't it too defensive?
        // eslint-disable-next-line no-console
        console.error('Error in d2/src/components/DocumentOn/index.js teardown', error)
      }
    }
  }, [event, fnMemo])

  return null
})

DocumentOn.displayName = 'DocumentOn'

export default DocumentOn
