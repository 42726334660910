import { memo } from 'react'
import Button from '../../Button'
import Flexbox from 'd2/components/Layout/Flexbox'
import useTranslations from '../translations'
import type { Props } from './types'

const LoadMoreButton = memo<Props>(({
  anyMoreResults,
  handleLoadMore,
  loadMoreLoading,
  loadMoreText,
}) => {
  const t = useTranslations()
  return (
    <Flexbox
      align='center'
      secondaryAlign='center'
    >
      <Button
        disabled={!anyMoreResults}
        icon={anyMoreResults && 'redo-alt'}
        link={!anyMoreResults}
        mutationLoading={loadMoreLoading}
        onClick={handleLoadMore}
        size='small'
        variant={anyMoreResults ? 'primary-inverted' : 'primary'}
      >
        { anyMoreResults ? loadMoreText ?? t.loadMore : t.noMoreResults }
      </Button>
    </Flexbox>
  )
})

LoadMoreButton.displayName = 'LoadMoreButton'

export default LoadMoreButton
