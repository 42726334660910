import type { Action } from 'd2/actions'
import type { GenericReducer } from '../types'

export type State = {
  width: number | null | undefined
}

const init: () => State = () => ({
  width: null,
})

export type Reducer = GenericReducer<State>
const reducer: Reducer = (state = init(), action: Action) => {
  switch (action.type) {
  case 'SET_DEVICE_DIMENSIONS': {
    return {
      width: action.payload.width,
    }
  }
  default: {
    return state
  }
  }
}

export default reducer
