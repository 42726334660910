import { forwardRef, memo } from 'react'
import { useIsMobile } from '../../Responsive'
import IconButton from '../IconButton'
import useStyles from './styles'
import type { SolidButtonProps } from './types'

const SolidButton = memo<SolidButtonProps>(forwardRef(
  ({
    className,
    color,
    disableMobile,
    maxWidth,
    ...props
  }, ref) => {
    const { classes } = useStyles({ color })
    const isMobile = useIsMobile()

    return (
      <IconButton
        {...props}
        className={`${classes.buttonColor} ${classes.button} ${className ?? ''}`}
        maxWidth={maxWidth || (!disableMobile && isMobile)}
        ref={ref}
        variant='contained'
      />
    )
  },
))

SolidButton.displayName = 'SolidButton'

export default SolidButton
