import { Context as ResponsiveContext } from 'react-responsive'
import { memo } from 'react'
import { useSelector } from 'react-redux'
import type { State } from 'd2/reducers'

const ResponsiveProvider = memo<{
  children: React$Node
}>(({
  children,
}) => {
  const width = useSelector<State, number | null | undefined>(({ device }) => device.width)

  if (width) {
    // We only need this Provider if we want to mock the media query width in jest tests.
    return (
      <ResponsiveContext.Provider value={{ width }}>
        { children }
      </ResponsiveContext.Provider>
    )
  }

  return (<>
    { children }
  </>)
})

export default ResponsiveProvider
