import { Component, MouseEvent } from 'react'
import FirstPage from '@mui/icons-material/FirstPage'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPage from '@mui/icons-material/LastPage'

type PageEvent = MouseEvent<HTMLButtonElement> | null

type Props = {
  buttonClassName?: string,
  className?: string,
  count: number,
  onPageChange: (event: PageEvent, page: number) => void,
  page: number,
  rowsPerPage: number
}

export default class TablePaginationActions extends Component<Props> {
  get pageCount () {
    const { props: { count, rowsPerPage } } = this
    return Math.max(1, Math.ceil(count / rowsPerPage))
  }

  handleFirstPageButtonClick = (event: PageEvent) => {
    this.props.onPageChange(event, 0)
  }

  handleBackButtonClick = (event: PageEvent) => {
    this.props.onPageChange(event, this.props.page - 1)
  }

  handleNextButtonClick = (event: PageEvent) => {
    this.props.onPageChange(event, this.props.page + 1)
  }

  handleLastPageButtonClick = (event: PageEvent) => {
    this.props.onPageChange(
      event,
      this.pageCount - 1,
    )
  }

  render () {
    const {
      pageCount,
      props: { buttonClassName, className, count, page, rowsPerPage },
    } = this

    return (
      <div className={className}>
        { pageCount > 2 && (
          <IconButton
            aria-label='First Page'
            className={buttonClassName}
            disabled={page === 0}
            onClick={this.handleFirstPageButtonClick}
            size="large"
          >
            <FirstPage />
          </IconButton>
        ) }
        <IconButton
          aria-label='Previous Page'
          className={buttonClassName}
          disabled={page === 0}
          onClick={this.handleBackButtonClick}
          size="large"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          aria-label='Next Page'
          className={buttonClassName}
          data-test-id='Pagination-Button-NextPage'
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          onClick={this.handleNextButtonClick}
          size="large"
        >
          <KeyboardArrowRight />
        </IconButton>
        { pageCount > 2 && (
          <IconButton
            aria-label='Last Page'
            className={buttonClassName}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            onClick={this.handleLastPageButtonClick}
            size="large"
          >
            <LastPage />
          </IconButton>
        ) }
      </div>
    )
  }
}
