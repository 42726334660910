import { Flexbox } from 'd2/components/Layout'
import {
  concat,
  reduce,
  without,
} from 'lodash-es'
import {
  memo,
  useCallback,
  useMemo,
} from 'react'
import { px } from 'd2/utils/style'
import Body from 'd2/components/Typography/Body'
import BodySmall from 'd2/components/Typography/BodySmall'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ScrollableContainer from 'd2/components/ScrollableContainer'
import useStyles from './styles'

export type Metadata = {
  subtitle?: React$Node | string,
  title: React$Node | string
}
// TODO: Unique ID map: Add a required unique ID for mapping
export type Item = {
  disabled?: boolean,
  metadata?: Metadata,
  name?: React$Node | string,
  value: string
}

export type Items = Item[]

type OwnProps = {
  bordered?: boolean,
  height?: number | null,
  items: Items,
  loadMore?: React$Node,
  noPaddingLeft?: boolean | null,
  onChange?: (a: string[]) => void,
  onScrollPercent?: (a: number) => void,
  selectedValues: string[],
  testID?: string | null,
  twoColumns?: boolean
}

type Props = OwnProps

const CheckboxList = memo<Props>(({
  bordered,
  height,
  items,
  loadMore,
  noPaddingLeft,
  onChange: onChangeProp,
  onScrollPercent,
  selectedValues,
  testID,
  twoColumns,
}) => {
  const { classes, cx } = useStyles({
    height: height ?? undefined,
  })
  const onChange = useCallback(({ target: { checked, value } }: any) => {
    const newSelectedValues: string[] = checked
      ? concat(selectedValues, value)
      : without(selectedValues, value)
    onChangeProp?.(newSelectedValues)
  }, [onChangeProp, selectedValues])

  const selectedValuesMap: {
    [x: string]: boolean
  } = useMemo(() => reduce(selectedValues, (values: any, value: string) => {
    values[value] = true
    return values
  }, {}), [selectedValues])

  return (
    <List
      className={cx(classes.listPadding, {
        [classes.bordered]: !!bordered,
        [classes.listHeight]: !!height,
      })}
      onChange={onChange}
    >
      <ScrollableContainer
        className={cx({
          [classes.twoColumns]: twoColumns,
        })}
        fillHeight={!height}
        fillWidth={!twoColumns}
        innerClassName={cx({
          [classes.twoColumns]: twoColumns,
          [classes.overflow]: items.length < 2,
        })}
        maxHeight={height ? px(height) : ''}
        onScrollPercent={onScrollPercent}
      >
        {
          items.map((item, index: number) => {
            const { disabled, metadata, name, value } = item
            const { checkboxWithRightLabel, label, labelOnRight } = classes

            return (
              <ListItem
                className={cx({
                  [classes.noPaddingLeft]: noPaddingLeft,
                  [classes.twoColumnsItem]: twoColumns,
                })}
                key={index} // eslint-disable-line react/no-array-index-key
              >
                <FormControlLabel
                  checked={!!selectedValuesMap[value]}
                  classes={{
                    label: classes.labelSpan,
                  }}
                  className={cx(label, labelOnRight)}
                  control={<Checkbox // eslint-disable-line react-memo/require-usememo
                    classes={{ root: checkboxWithRightLabel }}
                    color='primary'
                    value={value}
                  />}
                  disabled={disabled}
                  label={metadata
                    ? <Flexbox vertical>
                      <Body>
                        { metadata.title }
                      </Body>
                      <BodySmall color='gray'>
                        { metadata.subtitle }
                      </BodySmall>
                    </Flexbox>
                    : name}
                  {...(testID ? { 'data-test-id': `${testID}-${value}` } : {})}
                />
              </ListItem>
            )
          })
        }
        {
          loadMore
            ? <ListItem>
              { loadMore }
            </ListItem>
            : undefined
        }
      </ScrollableContainer>
    </List>
  )
})

CheckboxList.displayName = 'CheckboxList'

export default CheckboxList
